<template>
    <!-- <InviteCandidate :showEmail="showEmail" :assessmentsLength="project.assessments" :toggleEmail="toggleEmail" :projectId="project._id" /> -->
    <AllRatings :viewAllRatings="viewAllRatings" :toggleRatings="toggleRatings" :candidatesRating="candidatesRating" />

    <div v-if="isLoading" class="loader">
        <LoadingComponent />
    </div>
    <div v-else>
        <div class="board-container">
            <div class="w-full flex flex-col-reverse lg:flex-row justify-between p-3 bg-[#fff] border-b rounded mb-5 shadow-card">
                <div class="flex">
                    <button
                        class="backBtn bg-black/5 text-gray-700 hover:bg-black/10 menuBtn hidden lg:block"
                        @click="
                            () => {
                                this.$router.go(-1);
                            }
                        "
                    >
                        <font-awesome-icon :icon="['fas', 'angle-left']" />
                    </button>
                    <div class="mx-4 flex flex-col">
                        <h2 class="projData">
                            {{ project.name ? project.name : "Untitled" }}
                            {{ project.seniority && " - " + project.seniority }}
                            {{ project.jobTitle && " - " + project.jobTitle }}
                        </h2>
                        <div class="flex" style="color: #2196f3">
                            <div class="flex items-center">
                                <font-awesome-icon :icon="['far', 'file-lines']" class="mx-2" />
                                <p>{{ filteredAssessmentsLength }} tests</p>
                            </div>
                            <div class="flex mx-2 items-center">
                                <font-awesome-icon :icon="['far', 'clock']" class="mx-2" />
                                <p>{{ totalDuration }} minutes</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="flex gap-4 items-center justify-start relative">
                    <button
                        class="backBtn bg-black/5 text-gray-700 hover:bg-black/10 menuBtn lg:hidden block"
                        @click="
                            () => {
                                this.$router.go(-1);
                            }
                        "
                    >
                        <font-awesome-icon :icon="['fas', 'angle-left']" />
                    </button>
                    <button @click="toggleProjectMenu" style="cursor: pointer" class="backBtn bg-black/5 text-gray-700 hover:bg-black/10 menuBtn">
                        <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                    </button>
                    <ProjectMenu
                        :showDetails="showMenu"
                        :toggleMenu="toggleProjectMenu"
                        :editable="invitations.length > 0 ? false : true"
                        :thisProject="project"
                        :hideSample="true"
                        class="absolute top-0 right-0"
                        style="position: absolute; right: 20%"
                    />
                    <div
                        class="text-slate-700 px-4 font-light text-sm py-3 my-1 backBtn bg-black/5 text-gray-700 hover:bg-black/10 menuBtn flex items-center justify-center cursor-pointer"
                        @click.stop
                        @click="navigateToPreview"
                    >
                        <font-awesome-icon :icon="['fas', 'eye']" class="w-5 h-5" />
                    </div>
                    <button ref="targetElement3" @click="toggleEmail" class="nextStep shadow ml-auto">Invite</button>
                </div> -->
            </div>

            <div class="flex lg:flex-row flex-col gap-5 w-[100%]">
                <div class="w-full lg:w-[50%] p-2 rounded-md shadow-card bg-[#fff]">
                    <span class="recentapp"> {{ $t("About project") }}</span>

                    <div class="flex flex-col items-center mt-3">
                        <div class="w-[100%]">
                            <!-- <div class="inline-block py-2 sm:px-6 lg:px-8"> -->
                            <div class="w-full flex p-2 py-4 duration-300 hover:bg-[#2371b631]">
                                <span class="w-[50%] font-medium">{{ $t("Success Rate") }}</span>
                                <span class="w-[50%]">{{ getSuccessRate() }}%</span>
                            </div>
                            <div class="w-full flex p-2 py-4 duration-300 hover:bg-[#2371b631]">
                                <span class="w-[50%] font-medium">{{ $t("Completion") }} </span>
                                <span class="w-[50%]">75%</span>
                            </div>
                            <div class="w-full flex p-2 py-4 duration-300 hover:bg-[#2371b631]">
                                <span class="w-[50%] font-medium">{{ $t("Applicants") }}</span>
                                <span class="w-[50%]">{{ this.candidates.length }}</span>
                            </div>
                            <div class="w-full flex p-2 py-4 duration-300 hover:bg-[#2371b631]">
                                <span class="w-[50%] font-medium">{{ $t("Assessements") }}</span>
                                <span class="w-[50%]">{{ this.project.assessments?.length }} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-[50%] p-2 rounded-md shadow-card bg-[#fff]">
                    <span class="recentapp"> {{ $t("Included Tests") }}</span>
                    <div class="flex flex-col items-center mt-1">
                        <div class="w-[100%] p-1">
                            <!-- <div class="inline-block py-2 sm:px-6 lg:px-8"> -->
                            <table class="min-w-full text-sm" v-if="project?.assessments?.length > 0">
                                <thead class="text-gray-700 bg-[#FBFBFB]">
                                    <tr class="border-b transition-colors hover:bg-muted/50">
                                        <th colspan="10" scope="col" class="h-11 text-[#64748B] px-4 text-left font-medium text-muted-foreground">Test</th>
                                        <th colspan="2" scope="col" class="h-11 text-[#64748B] px-4 text-left font-medium text-muted-foreground">Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(assessment, index) in filteredAssessments"
                                        :key="index"
                                        class="border-b-[1px] border-[#e5e7eb] transition duration-300 ease-in-out text-gray-700 hover:bg-[#2371b631]"
                                    >
                                        <td colspan="10" class="px-4 py-3 align-middle">
                                            {{ assessment.name }}
                                        </td>
                                        <td colspan="2" class="px-4 py-3 align-middle">
                                            <font-awesome-icon :icon="['far', 'clock']" class="mx-1" />
                                            {{ assessment?.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment?.questions_nbr * 35) / 60) }}'
                                        </td>
                                    </tr>
                                </tbody>

                                <thead v-if="customAssessments?.questions_list.length > 0" class="text-gray-700 bg-[#FBFBFB]">
                                    <tr class="border-b transition-colors hover:bg-muted/50">
                                        <th colspan="10" scope="col" class="h-11 text-[#64748B] px-4 text-left font-medium text-muted-foreground">Custom questions</th>
                                        <th colspan="2" scope="col" class="h-11 text-[#64748B] px-4 text-left font-medium text-muted-foreground">Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(question, index) in customAssessments?.questions_list"
                                        :key="index"
                                        class="border-b-[1px] border-[#e5e7eb] transition duration-300 ease-in-out hover:bg-[#F4F4F6] text-gray-700 hover:bg-muted/50"
                                    >
                                        <td colspan="10" class="px-4 py-3 align-middle">
                                            {{ question?.title }}
                                        </td>
                                        <td colspan="2" class="px-4 py-3 align-middle">
                                            <font-awesome-icon :icon="['far', 'clock']" class="mx-1" />
                                            {{ question?.time }}'
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-[100%] my-5">
                <div class="rounded-md p-2 mb-5 shadow-card bg-[#fff]">
                    <DummyCandidatesTable
                        :candidates="candidates"
                        :projectAssessments="project.assessments"
                        :minScore="this.project.min_score ? this.project.min_score : this.project.recommanded_score"
                    />
                </div>
                <div class="w-full rounded-md p-2 shadow-card" style="background-color: #fff">
                    <div class="flex flex-row items-center w-full justify-between mb-[50px]">
                        <div class="flex flex-row content-start items-center w-[50%]">
                            <!-- <div class="svgBG">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M14.06 9.94L12 9L14.06 8.06L15 6L15.94 8.06L18 9L15.94 9.94L15 12L14.06 9.94ZM4 14L4.94 11.94L7 11L4.94 10.06L4 8L3.06 10.06L1 11L3.06 11.94L4 14ZM8.5 9L9.59 6.59L12 5.5L9.59 4.41L8.5 2L7.41 4.41L5 5.5L7.41 6.59L8.5 9ZM4.5 20.5L10.5 14.49L14.5 18.49L23 8.93L21.59 7.52L14.5 15.49L10.5 11.49L3 19L4.5 20.5Z"
                                        fill="#00AEF0"
                                    />
                                </svg>
                            </div> -->
                            <span class="recentapp">Talent Map</span>
                        </div>
                        <div class="flex flex-row content-start items-center">
                            <span class="on-track"><img loading="lazy" decoding="async" src="@/assets/Images/icons/check-green.svg" alt="on-treack" /> {{ $t("On Track") }}</span>
                        </div>
                    </div>
                    <div class="w-full mb-3 flex justify-center items-center">
                        <div class="w-[75%]">
                            <TalentsMappingChart :graphData="this.graphData" :chartWidth="windowWidth" :chartHeight="600" class="hidden lg:block" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="calendar-candidate mb-5 flex flex-col-reverse flex-wrap lg:grid">
                <div class="rounded-md p-2 shadow-card bg-[#fff] w-full">
                    <div class="">
                        <div>
                            <span class="recentapp">{{ $t("Anti cheat") }}</span>
                        </div>
                        <!-- <button class="viewbtn">{{ $t("View All") }} <img loading="lazy"  decoding="async" src="@/assets/Images/icons/Vector.svg" alt="" /></button> -->
                    </div>
                    <div v-if="allCheaters && allCheaters.length > 0" class="flex pt-5 flex-col gap-2 content-center items-center w-full">
                        <div
                            v-for="cheater in allCheaters.slice(0, 3)"
                            :key="cheater._id"
                            :class="{
                                cheaters_containers: cheater.status === 'cheater',
                                'w-full flex justify-between items-center text-[#334155] bg-white p-2.5 mb-2.5 rounded shadow-md w-72 hover:bg-[#d9e7f4]': cheater.status === 'potential-cheater',
                            }"
                        >
                            {{ cheater.First_name + " " + cheater.Last_name }}
                            <div
                                v-if="cheater.status === 'potential-cheater'"
                                class="verify-container"
                                @click="
                                    openCheatingCard();
                                    goToCheatingCard(cheater._id);
                                "
                            >
                                Verify
                            </div>
                            <div v-else class="cheater-span">Cheater</div>
                        </div>

                        <div v-if="allCheaters?.length > 3" class="w-full flex justify-center">
                            <button class="viewbtn" style="margin-top: 5%" @click="() => (viewAllCheaters = !viewAllCheaters)">
                                {{ $t("View All") }}
                                <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/Vector.svg" alt="" /> -->
                            </button>
                        </div>
                        <div v-if="viewAllCheaters" class="fixed top-0 left-0 z-20 w-full h-full flex items-center justify-center bg-[#00000015]">
                            <div class="absolute top-0 left-0 w-full h-full z-[-1]" @click="() => (viewAllCheaters = !viewAllCheaters)"></div>
                            <div class="w-[40%] min-h-[fit-content] max-h-[60%] flex flex-col gap-2 rounded-xl bg-[#fff] p-4 overflow-y-scroll">
                                <div
                                    v-for="cheater in allCheaters"
                                    :key="cheater._id"
                                    :class="{
                                        cheaters_containers: cheater.status === 'cheater',
                                        potential_containers: cheater.status === 'potential-cheater',
                                    }"
                                >
                                    {{ cheater.candidate_id?.First_name + " " + cheater.candidate_id?.Last_name }}
                                    <div v-if="cheater.status === 'potential-cheater'" class="verify-container" @click="goToCheatingCard(cheater._id)">Verify</div>
                                    <div v-else class="cheater-span">Cheater</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex content-center items-center no-cheater-container">
                        <span class="text-center w-full span-no-cheater">No Cheaters Detected</span>
                    </div>
                </div>

                <candidatesFunnel :histogramData="histogramData" />
            </div>
        </div>
        <div class="calendar-candidate flex flex-wrap lg:grid">
            <div class="rounded-md mt-5 p-2 shadow-card bg-[#fff] w-full">
                <div class="flex flex-row content-center items-center gap-2">
                    <span class="recentapp">{{ $t("Rating") }}</span>
                </div>

                <div v-if="candidatesRating.length > 0">
                    <div class="recentcandidates">
                        <CandidatesRating v-for="(candidateRating, i) in ThreeRatings" :candidateRating="candidateRating" :key="i" />
                    </div>

                    <button class="viewbtn" style="margin-left: 50%; transform: translateX(-50%); margin-top: 5%" @click="toggleRatings">
                        {{ $t("View All") }}
                        <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/Vector.svg" alt="" /> -->
                    </button>
                </div>
                <div v-else class="flex content-center items-center w-full h-full">
                    <div class="w-full noRating">
                        <span>{{ $t("No Ratings Yet") }}</span>
                    </div>
                </div>
            </div>
            <candidatesChart :histogramData="this.histogramData2" />
        </div>
    </div>
</template>

<script>
import CandidatesRating from "@/components/CandidatesRating.vue";
// import Destribution from "@/components/Destribution.vue";
// import RacentCandidateRow2 from "@/components/RacentCandidateRow copy.vue";
import candidatesFunnel from "@/components/candidatesFunnel.vue";
import TalentsMappingChart from "../../charts/TalentsMappingChart.vue";
// import allCandidates from "@/components/allCandidates.vue";
import { useStore } from "@/store/index";
import candidatesChart from "@/components/candidatesChart.vue";
// import ProjectMenu from "@/components/ProjectMenu.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import DummyCandidatesTable from "./DummyCandidatesTable.vue";
// import BoardCandidate from "@/components/Board.vue";
// import InviteCandidate from "@/components/InviteCandidate.vue";
import AllRatings from "@/components/AllRatings.vue";
// import axios from "axios";
import dummyData from "../../dummyData/dummyData.js";
import dummyCheaters from "../../dummyData/cheaters.js";

export default {
    name: "DashboardView",
    components: {
        // RacentCandidateRow2,
        candidatesFunnel,
        TalentsMappingChart,
        AllRatings,
        // allCandidates,
        CandidatesRating,
        // Destribution,
        candidatesChart,
        // ProjectMenu,
        LoadingComponent,
        DummyCandidatesTable,
        // InterceptionsSection,

        // InviteCandidate,
    },
    data() {
        return {
            imagePath: require(`@/assets/onBoardingGIFs/inviteCandidate.gif`),
            showEmail: false,
            windowWidth: 500,
            graphData: [],
            selected: [true, false, false],
            current: 0,
            invitations: [],
            allCheaters: [],
            candidates: [],
            compitionRate: 0,
            project: {},
            candidatesScores: [],
            threeCandidates: [],
            candidatesRating: [],
            diskData: [],
            histogramData: [],
            histogramData2: [],
            ThreeRatings: [],
            behindSchedule: null,
            id: "",
            isCheatingCardOpen: false,
            candidateCard: {},
            showMenu: false,
            isLoading: true,
            viewAllRatings: false,
            viewAllCheaters: false,
        };
    },
    methods: {
        toggleProjectMenu() {
            this.showMenu = !this.showMenu;
        },

        toggleRatings() {
            this.viewAllRatings = !this.viewAllRatings;
        },
        goToCheatingCard(id) {
            this.$router.push({
                path: `/dummy-anti-cheat/${id}`,
                // query: { id: this.project._id },
            });
        },

        calculateAverageTime() {
            let averageTime = 0;
            this.candidates.map((candidate) => {
                averageTime += candidate.candidateTime;
            });
            averageTime = averageTime / this.candidates.length;
            return this.calculateTime(averageTime);
        },
        calculateTime(time) {
            let minutes = Math.floor(time / (60 * 1000));
            if (minutes > 59) minutes = 59;

            let seconds = time % (60 * 1000);
            if (seconds > 59) seconds = 59;

            minutes = minutes.toString().padStart(2, "0");

            if (seconds < 10) {
                seconds = "0" + seconds.toString();
            } else {
                seconds = seconds.toString();
            }
            if (isNaN(minutes)) {
                return "00 min 00 sec";
            }
            return `${minutes} min ${seconds} sec`;
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },

        getCandidatesPassed() {
            let candidatesPassed = this.candidates.filter((candidate) => {
                let score = 0;
                candidate.results.forEach((element) => {
                    score += (element.totalPoints * 100) / element.quesionsNbr;
                });
                const averageScore = score / candidate.results.length;
                // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage
                return averageScore > this.project.min_score; // Filter candidates with scores over 80%
            });
            return candidatesPassed.length > 0 ? candidatesPassed.length : 0;
        },
        getSuccessRate() {
            if (this.candidates.length > 0) {
                const candidatesAbove80Percent = this.candidates.filter((candidate) => {
                    let score = 0;
                    candidate.results.forEach((element) => {
                        score += (element.totalPoints * 100) / element.quesionsNbr;
                    });
                    const averageScore = score / candidate.results.length;
                    // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage

                    return averageScore > this.project.min_score; // Filter candidates with scores over 80%
                });

                const numberOfCandidatesAbove80Percent = candidatesAbove80Percent.length;
                const totalCandidates = this.candidates.length;

                const percentageAbove80Percent = (numberOfCandidatesAbove80Percent / totalCandidates) * 100;
                return percentageAbove80Percent.toFixed(2);
            } else return 0;
        },

        navigateToPreview() {
            const url = this.$router.resolve({
                path: "/preview-project",
                query: { id: this.project._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
    },
    computed: {
        filteredAssessmentsLength() {
            if (!this.project || !this.project.assessments) {
                return 0;
            }

            const hasCustomAssessment = this.project.assessments.some((assessment) => assessment.category === "Custom");

            return hasCustomAssessment ? this.project?.assessments?.length - 1 : this.project?.assessments?.length;
        },
        filteredAssessments() {
            return this.project.assessments.filter((assessment) => assessment.category !== "Custom");
        },
        customAssessments() {
            return this.project.assessments.find((assessment) => assessment.category == "Custom");
        },
        totalDuration: {
            get() {
                if (this.project?.assessments?.length > 0) {
                    const totalSeconds = this.filteredAssessments.reduce((acc, assessment) => {
                        if (assessment?.questions_nbr > 25) {
                            return acc + 20 * 35;
                        } else {
                            return acc + assessment?.questions_nbr * 35;
                        }
                    }, 0);

                    const minutes = Math.floor(totalSeconds / 60);
                    const seconds = totalSeconds % 60;
                    return `${minutes}:${seconds}`;
                } else {
                    return { minutes: 0, seconds: 0 };
                }
            },
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    async mounted() {
        let dummy = dummyData.data;
        // this.getCandidateInv();
        // const headers = {
        //     "Content-Type": "application/json",
        //     withCredentials: true,
        // };

        // await axios
        //     //https://server.go-platform.com/
        //     .get(`https://server.go-platform.com/tooltip/get`, {
        //         headers,
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         //alert(res.data);
        //         if (res.data != 2) {
        //             setTimeout(() => {
        //                 this.startIntro();
        //             }, 1500);

        //             axios.get(`https://server.go-platform.com/tooltip/post2`, {
        //                 headers,
        //                 withCredentials: true,
        //             });
        //         }
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });

        //this.startIntro();

        //?
        this.id = this.$route.query.id;
        // this.getAllCheaters(this.id);
        // this.getBestCandidate(this.id);

        // await this.Store.fetchProjects();
        // // this.Store.fetchCandidates();
        // this.Store.getCompanyAssessments();
        // this.Store.fetchInvitations();

        this.project = this.Store.projects[0]?._id;

        this.invitations = dummy.invited;
        this.candidates = dummy.candidates;
        this.behindSchedule = this.invitations.length - this.candidates.length;
        this.threeCandidates = this.candidates.slice(0, 3);
        this.project = dummy.project;
        this.compitionRate = (this.candidates.length / this.invitations.length).toFixed(2);
        this.graphData = dummy.chartData;
        this.histogramData = [
            {
                label: "Invited",
                value: this.invitations.length,
            },
            {
                label: "Attempted",
                value: this.candidates.length,
            },
            {
                label: "Passed",
                value: this.getCandidatesPassed(),
            },
        ];
        this.candidatesRating = dummy.ratings;
        this.ThreeRatings = this.candidatesRating.slice(0, 3);
        this.histogramData2 = dummy.histoData.candidatesScore;
        this.allCheaters = dummyCheaters;
        this.isLoading = false;
    },
};
</script>

<style scoped lang="scss">
.board-container {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.indicator {
    display: grid;
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 20px;
    padding: 4px;
    align-self: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.green {
    background: rgba(5, 205, 153, 0.1);
    color: #05cd99;
}

.red {
    background: rgba(227, 26, 26, 0.1);
    color: #e31a1a;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.score-applicants {
    // widows: 700px;
    /* display: grid; */
    grid-template-columns: 65% 33%;
    padding: 36px 0px;
    gap: 20px;
}

.score {
    width: 100%;
    display: grid;
    align-items: center;
    /* grid-template-rows: 1fr 3fr; */
    background-color: #fff;
    border-radius: 20px;
    padding: 16px;
}

.applicants {
    // width: 100%;
    height: fit-content;
    background-color: #fff;
    border-radius: 20px;
    padding: 0 16px;
    padding-bottom: 36px;
}

.stat-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    /* padding: 0px 48px; */
    margin: 2% 0;
}

.stat-widget > div {
    width: 100%;
    box-shadow: 0px 18px 40px rgb(112 144 176 / 12%);
    padding: 8px 16px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
    background: #fff;
    border-radius: 20px;
}

.stat-widget > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.avg {
    display: flow-root;
}

.calendar-candidate {
    padding: 0px;
    width: 100%;
    // display: grid;
    grid-template-columns: 33% 65%;
    gap: 20px;
    /* gap: 20px; */
    margin: 0px 0px;
}

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    right: -215%;
    width: 90%;
    padding: 10%;
    position: relative;
    margin-top: 15%;
    margin-bottom: 30%;
}

.calendar {
    width: 100%;
    display: flow-root;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
    // padding-top: 10%;
}

.potential_containers {
    background: #ffefd6;
    color: #f76b15;
    // border: 2px solid #F76B15;
    border-radius: 8px;
    padding: 0.6rem 0.6rem;
    width: 100%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cheaters_containers {
    background: #feebec;
    color: #ce2c31;
    // border: 2px solid #F76B15;
    border-radius: 8px;
    padding: 0.6rem 0.6rem;
    width: 100%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cheater-span {
    color: #a3aed0;
    font-size: 1rem;
}

.verify-container {
    padding: 5px 10px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    background: #fff;
    border-radius: 10px;
    color: #53b9ab;
    cursor: pointer;
    border: 1px solid #53b9ab;

    &:hover {
        opacity: 0.85;
    }
}

.calendar > button {
    margin: 3% 0% 3% 63%;
}

.candidate {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.menuBtn {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.score-head {
    /* width: 90%; */
    // margin: 0 3%;
    display: grid;
    justify-items: baseline;
    align-items: center;
    grid-template-columns: 5fr 2fr;
}

.backBtn {
    width: 60px;
    height: 50px;

    border-radius: 10px;
}

.projData {
    font-size: 20px;
    font-weight: 700;
    color: #0f172a;
}

.score-head > div {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center; */
}

.score-head {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.score-head > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.score-head > div > span {
    color: #05cd99;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 30%;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}

.tasks {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    display: grid;
    background-color: #fff;
    border-radius: 20px;
    margin: 10% 10% 10% 2%;
    padding: 0% 3%;
}

.tasks > div {
    border-left: 3px solid #4318ff;
    display: grid;
    gap: 3px;
    justify-items: left;
    padding-left: 3%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.thedate {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;
}

.time-stamp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #a3aed0;
}

.thetask {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1b2559;
}

.tasks > div:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
    gap: 8px;
}

/*----------------------------------------------------------------*/

.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 0px;
}

.nav-links span {
    font-size: 13px;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

/* .calendar-candidate {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 20px;
    margin: 20px 0px;
} */

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    right: -215%;
    width: 90%;
    padding: 10px;
    position: relative;
}

/* .applicants {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 16px;
    display: flow-root;
    text-align: left;
} */

.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}

.score2 {
    display: grid;
    height: 420px;
    width: 100%;
    align-items: center;
    grid-template-rows: 1fr 2fr 3fr;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 16px 0 16px;
}

.nextStep {
    width: 100px;
    height: 50px;
    color: white;
    font-weight: 500;
    background: #2196f3;
    border-radius: 10px;

    &:hover {
        opacity: 0.85;
    }
}

.svgBG {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.noRating {
    margin-top: -10%;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 300;
    text-align: center;

    span {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        width: 90%;
    }
}

.span-no-cheater {
    color: #bbbbbb;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.no-cheater-container {
    height: 80%;
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assessments {
    border-bottom: 1px solid #dcdcdc;
}

.assessments:last-child {
    border: none;
}

.introjs-tooltip {
    min-width: 400px;
    max-width: 400px;
}
</style>
