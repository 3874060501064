<template>
    <div class="bg-white shadow-md rounded-lg p-6 flex justify-between h-[100%]">
        <!-- Candidate Info -->
        <div class="w-full flex flex-col justify-between gap-2">
            <div class="flex flex-row w-[75%]">
                <h2 class="text-4xl font-bold text-left decoration-skip-ink-none font-inter w-[30%]">{{ name }}</h2>
                <div class="flex flex-row justify-between gap-3">
                    <div v-for="(status1, index) in status" :key="index" class="flex flex-row items-center justify-between gap-2" :class="getStatusClass(status1)">
                        <span :class="getSubStatusClass(status1)"></span>
                        <span class="text-md font-bold text-black text-center decoration-skip-ink-none font-inter"> {{ status1 }}</span>
                    </div>
                </div>
            </div>

            <hr class="h-[5px] bg-[#00AEF033] w-full mt-2 mb-2" />

            <div class="flex flex-row justify-between items-center gap-1 text-gray-500">
                <div class="flex flex-row items-center">
                    <img src="../assets/cam.png" alt="Icon" class="w-[30%] h-[20%] bg-[#D9D9D9] p-2 rounded-lg" />

                    <div class="flex flex-col ml-3">
                        <span class="font-bold text-3xl text-black">{{ attempts }}</span>

                        <span>Attempts</span>
                    </div>
                </div>

                <div class="flex flex-rown items-center">
                    <img src="../assets/fe_calendar.png" alt="Icon" class="w-[20%] h-[10%] bg-[#D9D9D9] p-1 rounded-lg" />

                    <div class="flex flex-col ml-3">
                        <span class="font-bold text-3xl text-black">{{ daysOpen }}</span>
                        <span>Days Open</span>
                    </div>
                </div>
                <div class="flex flex-row justify-between gap-2">
                    <i class="fas fa-map-marker-alt mr-2 text-xl"></i>
                    <span>{{ location }}</span>
                </div>

                <div class="flex flex-col items-center">
                    <div class="text-sm text-black pr-9">Top Rated Candidate</div>

                    <!-- Rating -->
                    <div class="flex flex-row items-center ml-9">
                        <div class="font-bold text-2xl text-black">{{ rating }}</div>
                        <img src="../assets/star.png" alt="Icon" class="w-[20%] h-[10%]" />
                        <div class="text-sm text-gray-500">({{ reviews }} reviews)</div>
                    </div>
                </div>

                <div class="flex flex-col justify-between gap-2">
                    <button
                        class="bg-white text-[#00AEF0] font-bold text-md decoration-skip-ink-none font-inter px-4 py-2 border border-[#00AEF0] rounded hover:bg-[#00AEF0] hover:text-white hover:border-[#00AEF0]"
                    >
                        View Interview
                    </button>
                    <button
                        class="bg-[#00AEF0] text-white font-bold text-md decoration-skip-ink-none font-inter px-4 py-2 border border-[#00AEF0] rounded hover:bg-white hover:text-[#00AEF0] hover:border-white"
                    >
                        View Project
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        attempts: {
            type: Number,
            required: true,
        },
        daysOpen: {
            type: Number,
            required: true,
        },
        location: {
            type: String,
            required: true,
        },
        rating: {
            type: Number,
            required: true,
        },
        reviews: {
            type: Number,
            required: true,
        },
        status: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getStatusClass(status) {
            switch (status) {
                case "Ready":
                    return "bg-[#05CD9933] mr-9 text-black p-2 font-bold rounded-lg text-md";
                case "Pending":
                    return "bg-[#FFB4225E] mr-9 text-black p-2 font-bold rounded-lg text-md";
                case "Expired":
                    return "bg-[#FF725E47] mr-9 text-black p-2 font-bold rounded-lg text-md";
                case "Not Ready":
                    return "bg-[#E5328233] mr-9 text-black p-2 font-bold rounded-lg text-md";
                default:
                    return "bg-gray-100 mr-9 text-black p-2 font-bold rounded-lg text-md";
            }
        },
        getSubStatusClass(subStatus) {
            switch (subStatus) {
                case "Ready":
                    return "bg-[#34A853] w-4 h-4 rounded-full text-md";
                case "Pending":
                    return "bg-[#FFB422] w-4 h-4 rounded-full text-md";
                case "Expired":
                    return "bg-[#FF725E] w-4 h-4 rounded-full text-md";
                case "Not Ready":
                    return "bg-[#FF725E] w-4 h-4 rounded-full text-md";
                default:
                    return "bg-black w-4 h-4 rounded-full text-md";
            }
        },
    },
};
</script>

<style>
@import "tailwindcss/tailwind.css";
</style>
