<template>
    <div class="title-subtitle">
        <h1>How GO PLATFORM Helps</h1>
        <h3>
            Give your workforce personalized learning plans and your leadership the visibility and insights needed for better talent decisions–all seamlessly integrated into your technology ecosystem.
        </h3>
    </div>
</template>

<script>
export default {
    name: "TitleSubtitle",
};
</script>

<style scoped>
.title-subtitle {
    text-align: center;
    color: #ffffff;
}

.title-subtitle h1 {
    font-size: 2.2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.title-subtitle h3 {
    font-weight: 400;
    font-size: 0.9em;
    padding: 0 150px;
}
</style>
