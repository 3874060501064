<template>
    <section
        class="invite flex flex-col gap-5 lg:flex-row justify-between py-8 px-4 lg:px-20 items-center my-4"
        style="
            background-color: hsla(0, 0%, 100%, 1);
            background-image: radial-gradient(at 0% 0%, hsla(242, 100%, 70%, 1) 0px, transparent 50%), radial-gradient(at 100% 100%, hsla(206, 89%, 54%, 1) 0px, transparent 50%);
        "
    >
        <div class="w-full screen lg:w-5/7">
            <!--  <img loading="lazy" decoding="async" src="../../assets/Images/letter6.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/letter5.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/letter4.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/letter3.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/letter2.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/letter1.svg" class="absolute right-0 bottom-8 inviteLetter w-20 h-16 lg:w-40 lg:h-32" alt="" />
            <img loading="lazy" decoding="async" src="../../assets/Images/woman_productTour2.svg" class="w-full lg:-mb-14 aspect-auto" />-->
            <img loading="lazy" decoding="async" src="../../assets/Images/invite_screen.png" alt="" class="w-full border-2 rounded border-[#fff] aspect-auto" />
        </div>
        <div class="w-full lg:w-6/7">
            <h1 class="text-3xl lg:text-6xl font-bold text-slate-700 text-center lg:text-left w-full px-4">2. Invite candidates you way.</h1>
            <p class="text-sm lg:text-lg font-light w-full text-left text-slate-700 my-4 px-4">
                Connect with candidates by sending email invites directly from GO PLATFORM or straight from your ATS. Have a long list of candidates? Easily send multiple invites with a single click.
                Or have candidates sign up by sharing a direct link.
            </p>
            <!--  <div class="w-full flex flex-row">
                <div class="w-1/3 h-2"></div>
                <img loading="lazy" decoding="async" src="../../assets/Images/invite_screen.png" alt=""
                    class="w-full screen border-2 rounded border-[#fff] lg:w-3/5 aspect-auto" />
            </div>-->
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
    name: "InviteSection",
    mounted() {
        gsap.utils.toArray(".inviteLetter").forEach((letter, i) => {
            gsap.to(letter, {
                x: -(i * 200),
                y: -(i * 150),
                rotate: -45,
                stagger: 3,
                duration: 2,
                ease: "ease",
                scrollTrigger: {
                    trigger: ".invite",
                    start: "top 50%",
                    scrub: true,
                },
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.screen {
    @media (max-width: 1023px) {
        display: none;
    }
}
</style>
