<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <div class="assessement-preview fixed z-50 bottom-0 top-0 left-0 right-0 flex flex-col items-center justify-center bg-[#0000003f]" v-if="showEmail">
        <div class="fixed -z-50 bottom-0 top-0 left-0 right-0" @click="toggleEmail"></div>
        <div class="wrapper rounded-md bg-white w-[800px] h-[200px] p-8 flex flex-col justify-between items-center">
            <div class="w-full flex justify-between">
                <div class="preview-header w-full px-8">{{ $t("Invite Co-worker") }}</div>

                <button class="w-8 h-8 rounded-full border flex justify-center items-center border-gray-300 text-gray-300" @click="toggleEmail">
                    <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                    <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                </button>
            </div>

            <div class="w-full flex flex-row justify-center items-center px-8 gap-4 h-14">
                <div class="searchbar rounded-md flex flex-row items-center h-full w-2/4 px-5 py-0 border border-gray-300">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/email.svg" alt="search-icon" />
                    <input type="text" :placeholder="$t('Candidate email')" v-model="email" />
                </div>

                <Popper :content="$t('Send Email Invitation')" placement="top" :hover="true">
                    <button class="text-white rounded-md bg-NeonBlue text-base font-medium px-6 py-4 text-center" @click="sendInvitationEmail">{{ $t("Send") }}</button>
                </Popper>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ToastNotification from "@/components/ToastNotification";
import { useStore } from "../store/index";
export default {
    name: "InviteTeammate",
    props: ["showEmail", "toggleEmail"],
    components: { ToastNotification },
    data() {
        return {
            email: "",
            isVisible: false,
            message: "",
            bgc: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    async mounted() {},
    methods: {
        sendInvitationEmail() {
            if (this.email) {
                let data = JSON.stringify({
                    email: this.email,
                    company_name: this.Store.company_name,
                    company_ID: this.Store.company_id,
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://server.go-platform.com/company/inviteTeammate",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                axios
                    .request(config)
                    .then((response) => {
                        this.toggleEmail();
                        this.message = response.data.message;
                        this.bgc = "success";
                        this.email = "";
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 5000);
                    })
                    .catch((error) => {
                        this.message = "please enter a valid email address";
                        this.bgc = "red";
                        this.email = "";
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 5000);
                        console.log(error);
                    });
            } else {
                alert("Enter a valid email");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.assessement-preview {
    .preview-close {
        position: fixed;
        z-index: -1;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    > :nth-child(4) {
        border-radius: 10px;
        padding: 2rem 3rem;
        width: 50%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        background: #fff;
        align-self: center;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);
        gap: 1rem;
        color: #050708;

        .preview-header {
            position: relative;
            //grid-area: head;
            font-size: 38px;
            font-weight: 600;
            line-height: 22px;
            color: #2196f3;
            padding-bottom: 1rem;
            text-transform: uppercase;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background: #c3cad9;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .preview-description {
            display: flex;
            flex-direction: column;
            //grid-area: description;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            > span {
                font-weight: 400;
                padding: 1rem;
            }
        }

        .preview-cards {
            //grid-area: cards;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            gap: 1rem;
            /*border: 1px solid #C3CAD9;
            border-radius: 20px;
            box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);*/
            padding: 1rem 0;

            > * {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: center;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 2rem;

                    font-weight: 700;
                    font-size: 30px;
                    line-height: 22px;
                    text-align: center;
                    padding: 0.5rem;
                    color: #2196f3;
                }
            }
        }
    }
}

.preview-header {
    position: relative;
    //grid-area: head;
    font-size: 24px;
    font-weight: 600;
    line-height: 22px;
    color: #1b2559;
    padding-bottom: 1rem;
    // &::after {
    //     content: "";
    //     width: 100%;
    //     height: 1px;
    //     background: #c3cad9;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    // }
}

.icon {
    font-size: 6rem;
    color: #2196f3;
}

.preview-btn {
    button {
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
        }
    }
}

.title-section {
    display: flex;
    align-items: center;
    margin-top: 48px;
}

h1 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    color: #656669;
    margin-right: 56px;
}

.searchbar {
    //display: flex;
    //align-items: center;
    //background-color: #fff;
    //box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    //border-radius: 30px;
    //padding: 0 16px;
    //height: 8vh;
    //width: 60%;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #adb8cc;
}

input {
    border: none;
    height: 100%;
    width: 100%;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.trash-btn {
    .nav-icon {
        color: red;
    }
}

.trash-btn:hover {
    border: 2px solid red;
}
</style>
