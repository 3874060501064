<template>
    <header>
        <a href="#" class="logo">
            <img :src="logo" alt="GO Platform" />
        </a>
        <nav class="navigation">
            <a href="#">
                Product <span><i class="fa-solid fa-angle-down"></i></span
            ></a>
            <a href="#"> Pricing </a>
            <a href="#">
                Resources <span><i class="fa-solid fa-angle-down"></i></span
            ></a>
        </nav>
        <a href="#" class="main-button"> Log in </a>
    </header>
</template>

<script>
import logo from "@/assets/logo.svg";
export default {
    name: "NavBar",
    data() {
        return { logo: logo };
    },
};
</script>

<style scoped>
header {
    background-color: #f5f8ff;
    width: 100%;
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 100px;
}

.logo img {
    width: 150px;
}

.navigation a {
    color: #334155;
    text-decoration: none;
    font-size: 18px;
    padding: 0 30px;
}

.navigation a span {
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.navigation a:hover {
    color: #00aef0;
    /* Change to desired hover color */
}

.navigation a:hover span {
    transform: rotate(180deg);
    color: #00aef0;
}

@media (max-width: 1023px) {
    header {
        padding: 16px 20px;
    }

    .logo img {
        width: 70px;
    }

    .navigation a {
        font-size: 8px;
        padding: 0 10px;
    }

    header .main-button {
        padding: 6px 10px;
        font-size: 8px;
    }
}
</style>
