<template>
    <button class="icon-button">
        <img :src="iconSrc" :style="{ width: iconWidth, height: iconHeight }" />
        <span class="button-text">{{ buttonText }}</span>
    </button>
</template>

<script>
export default {
    name: "IconButton",
    props: {
        iconSrc: {
            type: String,
            required: true,
        },
        iconAlt: {
            type: String,
            default: "Icon",
        },
        iconWidth: {
            type: String,
            default: "20px",
        },
        iconHeight: {
            type: String,
            default: "20px",
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.icon-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    color: #44546f;
    background-color: #ffffff;
    border: none;
    border-radius: 20px;
    min-width: 120px;
    gap: 10px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 8px rgba(51, 97, 255, 0.098);
}

.icon-buttonn img {
    width: auto;
    height: auto;
}

.icon-button span {
    flex: 1;
    text-align: center; /* Centers text inside the button */
}
</style>
