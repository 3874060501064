<template>
    <section class="skills-overview">
        <div class="text-content">
            <h1>All skills, no frills</h1>
            <p>
                Finally, we put just as much thought and care into the reports and insights we deliver you as we do our tests. In just a few clicks, Go enables you to quickly understand and evaluate
                your candidates’ strengths and weaknesses. Our platform and reports help you focus on accurate, data-driven hiring decisions that will reduce mis-hires and positively impact the
                long-term success of your organization.
            </p>
            <div class="button-container">
                <a href="/register" class="cta-button">Start for free</a>
                <a href="https://calendly.com/aouf-abdellah/20min" target="_blank" rel="noopener noreferrer" class="cta-button bordered-button">Book a demo</a>
            </div>
        </div>
        <span> <img :src="skills" class="skills" /> </span>
    </section>
</template>

<script>
import skills from "@/assets/Images/skills.png";

export default {
    name: "SkillsOverview",
    data() {
        return {
            skills: skills,
        };
    },
};
</script>

<style scoped>
.skills-overview {
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.text-content {
    width: 50%;
    text-align: left;
}

.text-content h1 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}

.text-content p {
    color: #ffffff;
    font-size: 16px;
}

.skills {
    width: 450px;
    height: auto;
    /* Maintains aspect ratio */
    border-radius: 5px;
}

.button-container {
    margin-top: 30px;
    display: inline-flex;
    gap: 20px;
}

.cta-button {
    background-color: #fef5ff;
    color: #334155;
}

.bordered-button {
    background-color: transparent;
    color: #f5f8ff;
    border: 1.5px solid #f5f8ff;
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .skills-overview {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-content {
        width: 100%;
        margin-bottom: 20px;
    }

    .text-content h1 {
        font-size: 24px;
    }

    .text-content p {
        font-size: 12px;
    }

    .talent-score {
        max-width: 100%;
    }

    .cta-button {
        padding: 8px 20px;
        font-size: 12px;
    }
}
</style>
