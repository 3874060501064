<template>
    <section :style="{ overflowY: isShown ? 'hidden' : 'visible' }" class="blogs-container relative">
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Indystry</th>
                    <th>website</th>
                    <th>Date</th>
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="company in companies" :key="company._id">
                    <td class="title-case w-1.5/5">{{ company.name }}</td>
                    <td class="w-1/5">{{ company.industry }}</td>
                    <td class="w-1/5">{{ company.website }}</td>
                    <td class="w-0.5/5">{{ getDate(company.createdAt) }}</td>
                    <td class="actions w-1/5">
                        <font-awesome-icon @click="selectcompany(company._id)" class="icons w-4 info mr-1 h-4 p-2 text-white" :icon="['fas', 'circle-info']" />
                        <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'ban']" />
                        <font-awesome-icon @click="deleteCompany(company._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-show="companydetail" class="fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center">
            <div class="fixed top-0 right-0 bottom-0 left-0 -z-10 bg-[rgba(0,0,0,45%)]" @click="closeDetail"></div>
            <div class="w-10/12 h-[80vh] flex flex-col justify-start items-start bg-white border-2 border-red-200 text-slate-700 text-base font-normal rounded-md p-6 overflow-y-scroll">
                <div class="mb-6 w-full flex flex-row justify-end items-center gap-2">
                    <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'ban']" />
                    <font-awesome-icon @click="deleteCompany(selectedCompany._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                </div>
                <div class="w-full p-5 flex flex-row justify-between items-start gap-6">
                    <img loading="lazy" decoding="async" :src="getImage(selectedCompany.logo)" alt="" class="w-72 h-72 border-2 border-black" />
                    <div class="flex flex-col grow">
                        <h1 class="font-bold text-5xl text-slate-700 text-left w-full mb-10">{{ selectedCompany.name }}</h1>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Id:</span> <span class="font-light">{{ selectedCompany._id || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Industry:</span> <span class="font-light">{{ selectedCompany.industry || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Employees count:</span> <span class="font-light">{{ selectedCompany.employees_count || "N/A" }}</span>
                        </div>
                        <div v-for="(user, index) in selectedCompany?.users" :key="index" class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5 flex flex-col">
                            <span class="font-semibold mr-4 w-full">Contact:</span> <br />
                            <span class="font-semibold mr-4 w-full">Name:</span>
                            <span class="font-light">{{ user.name || "N/A" }}</span> <br />
                            <span class="font-semibold mr-4 w-full">Email:</span>
                            <span class="font-light">{{ user.email || "N/A" }}</span>
                        </div>

                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Registration date:</span> <span class="font-light">{{ getDate(selectedCompany.createdAt) || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Location:</span> <span class="font-light">{{ selectedCompany.location || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Credit:</span> <span class="font-light">{{ selectedCompany.credit || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">recent Project:</span> <span class="font-light">{{ getDate(selectedCompany.recentProjectDate) || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Total Projects:</span> <span class="font-light">{{ selectedCompany.totalProjects || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">subscription plan:</span> <span class="font-light">{{ selectedCompany.plan || "N/A" }}</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mb-5">
                            <span class="font-semibold mr-4 w-72">Verified:</span> <span class="font-light">{{ selectedCompany.isVerified }}</span>
                            <span @click="Verifycompany" v-show="!selectedCompany.isVerified" class="bg-gray-100 text-sm font-light border px-3 py-2 rounded border-gray-400">Verify</span>
                        </div>
                        <div class="w-full flex flex-row items-center justify-start gap-4 text-xl text-slate-700 mt-2 mb-5">
                            <span class="font-semibold mr-4 w-72">discounts:</span>
                            <div
                                v-if="selectedCompany.discounts ? selectedCompany.discounts.length != 0 : false"
                                class="font-light flex flex-row flex-wrap gap-3 bg-gray-100 p-5 rounded border border-slate-400"
                            >
                                <span class="font-light w-fit px-3 bg-white text-base py-1 border border-gray-400 rounded-full" v-for="tag in selectedCompany.discounts" :key="tag.name">{{
                                    tag.name
                                }}</span>
                            </div>
                            <p class="font-light" v-else>N/A</p>
                        </div>
                        <!-- <div v-for="key in keys" :key="key" class="w-full flex flex-col justify-start items-start my-2">
                            <span class="font-bold text-base text-slate-700 text-left">{{ key + ": " }}</span>
                            <p class="text-base font-light text-slate-500 w-full text-left bg-gray-100 p-5 rounded border border-slate-400">{{ selectedCompany[key] }}</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import getDateAndTime from "@/helpers/getDateandTime";
// import getImgUrl from "@/helpers/getImage";

export default {
    name: "companiesBoard",
    data() {
        return {
            companydetail: false,
            selectedCompany: {},
            isShown: false,
            companies: [],
        };
    },
    computed: {
        keys() {
            return Object.keys(this.selectedCompany);
        },
    },
    methods: {
        getDate(str) {
            return getDateAndTime(str);
        },
        Verifycompany() {
            console.log("company verified");
            this.selectedCompany.isVerified = true;
        },
        getImage(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        selectcompany(id) {
            this.selectedCompany = this.companies.find((company) => company._id === id);
            this.companydetail = true;
        },
        closeDetail() {
            this.selectedCompany = {};
            this.companydetail = false;
        },
        deleteCompany(id) {
            this.companydetail = false;
            let config = {
                method: "delete",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/company/deletecompany/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };
            axios
                .request(config)
                .then((response) => {
                    if (response.data.deleted) {
                        const companies = this.companies.filter((company) => company._id != id);
                        this.companies = companies;
                        // console.log('company was deleted')
                    } else console.log("company was not deleted");
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
    mounted() {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://server.go-platform.com/company/fetchcompanies`,
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        };
        axios
            .request(config)
            .then((response) => {
                this.companies = response.data;
                // console.table(this.companies)
            })
            .catch((err) => {
                console.error(err);
            });
    },
};
</script>

<style lang="scss" scoped>
.blogs-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.btn-section {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

table {
    width: 100%;
    text-align: left;
    border: 1px solid #ddd;
}

thead {
    background: #2196f3;
    color: #fff;
}

tbody {
    background: #fff;
}

.loading-tr {
    background: #2196f3;
    height: 40px;
}

.loading-body {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

th {
    padding: 8px 10px;
    text-align: start;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
    font-size: 16px;
}

.actions {
    text-align: center;
}

td {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

.icons {
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
}

.update {
    background: #2196f3;
}

.info {
    background: #5bb98b;
}

button {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

a {
    width: 15%;
}

.title-case {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}
</style>
