<template>
    <div v-if="show" class="container">
        <div @click="toggleModal" class="exit_zone"></div>
        <div class="settings">
            <div>
                <span>Personal Profile</span>
                <button class="save_btn" :disabled="disabled" :class="disabled ? 'disabled' : 'enabled'" @click="updateProfile">Save changes</button>
            </div>
            <div>
                <PersonalInfos>
                    <profileStep title="Main Info">
                        <form action="POST">
                            <div class="input_group">
                                <label for="first_name">First Name</label>
                                <input type="text" id="first_name" v-model="profileSetting.first_name" @keyup="enableBtn" />
                            </div>
                            <div class="input_group">
                                <label for="last_name">Last Name</label>
                                <input type="text" id="last_name" v-model="profileSetting.last_name" @keyup="enableBtn" />
                            </div>

                            <div class="input_group">
                                <label for="birth_date">Date of birth</label>
                                <input type="date" id="birth_date" v-model="profileSetting.birth_date" @keyup="enableBtn" />
                            </div>
                        </form>
                    </profileStep>
                    <profileStep title="Adress">
                        <form action="POST">
                            <div class="input_group">
                                <label for="country">Country</label>
                                <input type="text" id="country" v-model="profileSetting.country" @keyup="enableBtn" />
                            </div>
                            <div class="input_group">
                                <label for="city">City</label>
                                <input type="text" id="city" v-model="profileSetting.city" @keyup="enableBtn" />
                            </div>

                            <div class="input_group">
                                <label for="home_adress">Home adress</label>
                                <input type="text" id="home_adress" v-model="profileSetting.country" @keyup="enableBtn" />
                            </div>
                        </form>
                    </profileStep>
                    <profileStep title="Contact">
                        <form action="POST">
                            <div class="input_group2">
                                <label for="country"> <img loading="lazy" decoding="async" src="../assets/Images/telegram_setting.svg" width="30" height="30" alt="" /></label>
                                <input type="text" id="country" v-model="profileSetting.country" @keyup="enableBtn" />
                            </div>
                            <div class="input_group2">
                                <label for="city"><img loading="lazy" decoding="async" src="../assets/Images/facebook_setting.svg" width="30" height="30" alt="" /></label>
                                <input type="text" id="city" v-model="profileSetting.city" @keyup="enableBtn" />
                            </div>

                            <div class="input_group2">
                                <label for="home_adress"><img loading="lazy" decoding="async" src="../assets/Images/instagram_setting.svg" width="30" height="30" alt="" /></label>
                                <input type="text" id="home_adress" v-model="profileSetting.country" @keyup="enableBtn" />
                            </div>
                            <div class="input_group2">
                                <label for="home_adress"><img loading="lazy" decoding="async" src="../assets/Images/phone_setting.svg" width="30" height="30" alt="" /></label>
                                <input type="text" id="home_adress" v-model="profileSetting.country" @keyup="enableBtn" />
                            </div>
                        </form>
                    </profileStep>
                    <profileStep title="Notifications">
                        <form action="POST">
                            <div class="input_group3">
                                <span>General email notifications</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="input_group3">
                                <span>Emails for every new user joining in team</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="input_group3">
                                <span>Emails for every Assessment test Sent</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="input_group3">
                                <span>Emails for every time new assessment is launched</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="input_group3">
                                <span>Emails for weekly report</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="input_group3">
                                <span>Emails for every new message</span>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </form>
                    </profileStep>
                </PersonalInfos>
            </div>
            <div>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/lock_icon.svg" alt="Privacy" width="40" height="40" />
                <span>We keep your data private and never share it with third-parties.</span>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalInfos from "@/components/personal_infos.vue";
import profileStep from "@/components/profile_step.vue";
//import VueMultiselect from 'vue-multiselect';
//import '../../node_modules/vue-multiselect/dist/vue-multiselect.css';
//import { QuillEditor } from '@vueup/vue-quill';
//import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: "SettingView",
    components: {
        PersonalInfos,
        profileStep, // VueMultiselect , QuillEditor
    },
    props: {
        show: { type: Boolean, required: true, default: false },
        toggleModal: { type: Function, required: true },
        profile: { type: Object, required: true },
    },
    data() {
        return {
            disabled: true,
            options: ["Select option", "options", "selected", "multiple", "label", "searchable", "clearOnSelect", "hideSelected", "maxHeight", "allowEmpty", "showLabels", "onChange", "touched"],
            taglist: [
                {
                    image: "icon_idea.svg",
                    title: "Iinnovative",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_trust.svg",
                    title: "Nurturing",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_conflict.svg",
                    title: "Energitic",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_idea.svg",
                    title: "Sustainable",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_trust.svg",
                    title: "Clean",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
                {
                    image: "icon_conflict.svg",
                    title: "Creatif",
                    text: "We strive to constantly pursue innovation of our products and services",
                },
            ],
            benefit_list: [
                {
                    icon_path: "icon_hamburger.svg",
                    benefit: "Free Food and Snacks",
                },
                {
                    icon_path: "icon_globe.svg",
                    benefit: "Internation Exposuere",
                },
                {
                    icon_path: "icon_hospital_insurance.svg",
                    benefit: "Paid Sick Days",
                },
                {
                    icon_path: "icon_hospital_insurance.svg",
                    benefit: "THR/ Bonus System",
                },
                { icon_path: "icon_clothes.svg", benefit: "Casual Dress Code" },
                { icon_path: "icon_lunch.svg", benefit: "Free lunch" },
                {
                    icon_path: "icon_moneybag.svg",
                    benefit: "Competitive Salary",
                },
                { icon_path: "icon_more.svg", benefit: "More" },
            ],
        };
    },
    computed: {
        profileSetting: function () {
            return this.profile;
        },
    },
    methods: {
        enableBtn: function () {
            this.disabled = false;
        },
        updateProfile() {
            this.$emit("profileUpdated", this.profileSetting);
            console.log(this.profileSetting);
        },
        closeModal: function () {
            this.toggleModal;
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.container {
    font-family: "inter";
    font-style: normal;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        background: #fff;
        padding: 3rem 1.5rem;
        width: 50%;
        height: 50%;
        border-radius: 10px;
        > :first-child {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        > :nth-child(2) {
            width: 100%;
        }
        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .input_group {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                text-align: left;
            }
            .input_group2 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                label {
                    width: 10%;
                }
                input {
                    width: 90%;
                }
            }
            .input_group3 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-align: left;
                > :first-child {
                    width: 90%;
                }
                > :nth-child(2) {
                    width: 10%;
                }
            }
        }
        > :last-child {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background: #ecf5f9;
            margin-top: 1.5rem;
        }
        input,
        select {
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            height: 2rem;
            width: 100%;
            padding-left: 0.5rem;
        }
        .enabled {
            cursor: pointer;
            background: #2196f3;
        }
        .disabled {
            cursor: not-allowed;
            background: rgba(74, 187, 251, 1);
        }
    }
}
.exit_zone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.input_image {
    border: 1px dashed #d5d5d5;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 5rem;
    align-items: center;

    input {
        display: none;
    }
    label {
        padding: 5%;
        background: rgba(231, 231, 231, 0.5);
        border-radius: 10px;
    }
}
.save_btn {
    padding: 2% 3%;
    border-radius: 50px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.browse_btn {
    background: #fff;
    padding: 2%;
    border-radius: 50px;
    border: 1px solid #bdbdbd;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #dbdbdc;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #b8b8b9;
}
textarea {
    resize: none;
}

.option__desc {
    display: flex;
    flex-direction: column;
    color: #2f343a;
    margin-left: 0.5rem;

    .option__title {
        font-weight: 700;
        font-size: 12.2674px;
        line-height: 18px;
    }
    .option__small {
        font-weight: 500;
        font-size: 12.2674px;
        line-height: 18px;
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 28px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
