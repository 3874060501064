<template>
    <div class="skills-assessment-cards">
        <SkillsAssessmentCard :title="$t('Mobilize your workforce')">
            {{ $t("From they fine john he give of rich he. They age and draw mrs like. Improving end distrusts may instantly.") }}
        </SkillsAssessmentCard>
        <SkillsAssessmentCard :title="$t('Develop their Skills')">
            {{ $t("His defective nor convinced residence own. Connection has put impossible own apartments boisterous.") }}
        </SkillsAssessmentCard>
        <SkillsAssessmentCard :title="$t('Understand your talent')"> {{ $t("Party we years to order allow asked of. We so opinion friends me message as delight.") }} </SkillsAssessmentCard>
    </div>
</template>

<script>
import SkillsAssessmentCard from "./SkillsAssessmentCard.vue";
export default {
    name: "SkillsAssessmentCards",
    components: {
        SkillsAssessmentCard,
    },
};
</script>

<style scoped>
.skills-assessment-cards {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 60px 0;
}

@media (max-width: 1023px) {
    .skills-assessment-cards {
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
    }

    .skills-assessment-card {
        width: 100%;
        max-width: 480px;
        margin-bottom: 20px;
    }

    .skills-assessment-card .info h5 {
        text-align: center;
    }

    .skills-assessment-card .info p {
        font-size: 12px;
        text-align: center;
    }
}
</style>
