<template>
    <div v-if="customResults" class="individualWrapper rounded-md shadow p-1 border mt-8 border-slate-200">
        <div class="resultWrapper" :style="`background-color: ${this.$route.path === '/activity' && '#fff'} `">
            <div class="flex flex-row content-start items-center w-[70%]">
                <div class="svgBG border border-slate-300">
                    <img loading="lazy" decoding="async" src="../assets/question-mark.svg" alt="" />
                </div>
                <h3>{{ $t("Custom Questions") }}</h3>
            </div>

            <div v-for="(result, index) in customResults" :key="index" class="w-full border border-slate-200 rounded-md">
                <div class="result justify-between items-center">
                    <div class="relative w-[95%] flex flex-col justify-between items-center gap-2">
                        <div class="flex flex-col w-full justify-center gap-5 items-start">
                            <h4 class="text-left text-base font-semibold text-slate-700">{{ result.name }}</h4>
                            <div v-if="isSliderOpen[index]" class="h-[1px] w-full bg-[#0f172a]"></div>
                        </div>
                    </div>
                    <div class="text-[#2196f3]" @click="toggleSlider(index)">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </div>
                </div>
                <div
                    class="slider-content"
                    v-if="isSliderOpen[index]"
                    :style="`transition: all .5s ease ;transition-delay: 0.25s ;height: ${isSliderOpen[index] ? 'auto' : '0px'}; background-color: ${isSliderOpen[index] ? '#fff' : 'none'} `"
                >
                    <h2 class="text-md text-[#0f172a] my-2" style="font-weight: 600" v-if="isSliderOpen[index]">
                        {{ result.question }}
                    </h2>
                    <p v-if="isSliderOpen[index]"><span class="font-semibold">Answer :</span> {{ result.answer }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomResults",
    props: {
        questions_results: Array,
    },
    components: {},
    mounted() {
        console.log("Results result", this.questions_results);
    },
    computed: {
        customResults() {
            // Find the object in questions_results that has the customResults array
            const customResultsObject = this.questions_results?.find((item) => item.customResults);
            return customResultsObject ? customResultsObject.customResults : null;
        },
    },
    data() {
        return {
            showBig5: false,
            big5Result: false,
            big5: true,
            showTooltip: Array(this.questions_results?.length).fill(false),
            isSliderOpen: Array(this.questions_results?.length).fill(false), // Array to track whether each slider is open or not
        };
    },
    methods: {
        toggleSlider(index) {
            // Toggle the state of the slider when the icon is clicked
            this.isSliderOpen[index] = !this.isSliderOpen[index];
        },
        toggleShowTooltip(index) {
            this.showTooltip[index] = !this.showTooltip[index];
        },
        toggleBig5() {
            this.showBig5 = !this.showBig5;
        },
        getAssessmentInterception(assessmentName) {
            console.log({ INETRCEPTIONSSSSSSSSSSSSSSSS: this.candidateInterpretations });
            let interception = this.candidateInterpretations?.find((interception) => interception.assessmentName === assessmentName);
            console.log({ interception });
            return interception?.description;
        },
        getInterceptionTitle(assessmentName) {
            let interception = this.candidateInterpretations?.find((interception) => interception.assessmentName === assessmentName);
            console.log({ interception });
            return interception?.title;
        },
        getTraits(results) {
            console.log("getTraits", results);
            return Object.keys(results).map((key) => {
                console.log({ key });
                return { name: key, degree: results[key] };
            });
        },
    },
};
</script>

<style scoped lang="scss">
#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    // border: 1px solid #2196f3;
    background-color: #f4f7fe;
    border-radius: 90px;
    margin-right: 48px;
    width: 95%;
    height: 15px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    // margin-left: -1px;
    border-radius: 50%;
    background: #2196f3;
    border: 3px solid #2196f3;
    cursor: pointer;
    transform: translateX(-50%);
}

#success-slider[value="0"]::-webkit-slider-thumb {
    display: none;
}

.tooltip {
    position: absolute;
    bottom: -100%;
    // left: 50%;
    transform: translateX(-50%);
    background-color: #00000086;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 25;
}

.dot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 55%;
}

.dot::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2196f3;
    display: block;
    margin-right: 5px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    margin-left: 55%;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.individualWrapper {
    // display: grid;
    // grid-template-columns: 65% 33%;

    gap: 15px;

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.resultWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 5px;
    padding: 3%;
    background-color: #fff;

    > :first-child {
        margin-bottom: 16px;
        color: #343637;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 133.333% */
        letter-spacing: -0.48px;
    }
}

.result {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    // margin-bottom: 1px;
    // margin-left: 2%;
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: #fff;

    &:hover {
        cursor: pointer;
        background-color: #2371b631;
    }

    span {
        color: #343637;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px;
        /* 76.923% */
        letter-spacing: -0.26px;
    }
}

.svgBG {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

h3 {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    margin: 0;
}

.slider-content {
    height: 0;
    overflow-y: auto;
    transition: height 0.3s ease;
    /* Use height for the transition */
    margin-left: 2%;
    padding: 1%;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        color: #343637;
    }
}
</style>
