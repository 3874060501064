<template>
    <InviteCandidate :showEmail="showEmail" :assessmentsLength="project.assessments" :toggleEmail="toggleEmail" :projectId="project._id" />
    <AllRatings :viewAllRatings="viewAllRatings" :toggleRatings="toggleRatings" :candidatesRating="candidatesRating" />
    <div v-if="isLoading" class="loader">
        <LoadingComponent />
    </div>
    <div v-else>
        <!-- Header Stats -->
        <div class="grid grid-cols-4 gap-4">
            <div class="bg-custom-gradient text-center p-4 rounded-lg">
                <div class="flex flex-row justify-between gap-2 pl-9 pr-9 items-center">
                    <div class="flex flex-col w-1/3">
                        <img src="../assets/Images/icons/successRate.png" alt="icon" class="w-9 h-9 p-0" />
                        <div></div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 items-center w-2/3">
                        <p class="text-white font-semibold text-xl">{{ $t("Success Rate") }}</p>
                        <h2 class="text-4xl font-bold text-white">{{ getSuccessRate() }}%</h2>
                    </div>
                </div>
            </div>
            <div class="bg-custom-gradient text-center p-4 rounded-lg">
                <div class="flex flex-row justify-between gap-2 pl-9 pr-9 items-center">
                    <div class="flex flex-col w-1/3">
                        <img src="../assets/Images/icons/completion.png" alt="icon" class="w-9 h-9" />
                        <div></div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 items-center w-2/3">
                        <p class="text-white font-semibold text-xl">{{ $t("Completion") }}</p>
                        <h2 class="text-4xl font-bold text-white">{{ this.compitionRate !== "NaN" ? this.compitionRate : 0 }}%</h2>
                    </div>
                </div>
            </div>
            <div class="bg-custom-gradient text-center p-4 rounded-lg">
                <div class="flex flex-row justify-between gap-2 pl-9 pr-9 items-center">
                    <div class="flex flex-col w-1/3">
                        <img src="../assets/Images/icons/applicant.png" alt="icon" class="w-9 h-9" />
                        <div></div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 items-center w-2/3">
                        <p class="text-white font-semibold text-xl">{{ $t("Applicants") }}</p>
                        <h2 class="text-4xl font-bold text-white">{{ this.candidates.length }}</h2>
                    </div>
                </div>
            </div>
            <div class="bg-custom-gradient text-center p-4 rounded-lg">
                <div class="flex flex-row justify-between gap-2 pl-9 pr-9 items-center">
                    <div class="flex flex-col w-1/3">
                        <img src="../assets/Images/icons/assess.png" alt="icon" class="w-9 h-9" />
                        <div></div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 items-center w-2/3">
                        <p class="text-white font-semibold text-xl">{{ $t("Assessements") }}</p>
                        <h2 class="text-4xl font-bold text-white">{{ this.project.assessments?.length }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="board-container">
                <div class="w-full flex flex-col-reverse lg:flex-row justify-between p-3 bg-[#fff] border-b rounded mb-5 shadow-card">
                    <div class="flex">
                        <Popper :content="$t('Back')" placement="top" :hover="true">
                            <button
                                class="backBtn bg-[#E0E4EC] text-gray-700 hover:bg-black/10 menuBtn hidden lg:block"
                                @click="
                                    () => {
                                        this.$router.go(-1);
                                    }
                                "
                            >
                                <font-awesome-icon :icon="['fas', 'angle-left']" class="pl-6" />
                            </button>
                        </Popper>
                        <div class="mx-4 flex flex-col">
                            <h2 class="projData bg-custom-gradient bg-clip-text text-transparent">
                                {{ project.name ? project.name : "Untitled" }}
                                {{ project.seniority && " - " + project.seniority }}
                                {{ project.jobTitle && " - " + project.jobTitle }}
                            </h2>
                            <div class="flex" style="color: #2196f3">
                                <div class="flex items-center">
                                    <font-awesome-icon :icon="['far', 'file-lines']" class="mx-2" />
                                    <p>{{ filteredAssessmentsLength }} tests</p>
                                </div>
                                <div class="flex mx-2 items-center">
                                    <font-awesome-icon :icon="['far', 'clock']" class="mx-2" />
                                    <p>{{ totalDuration }} minutes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-4 items-center justify-start relative">
                        <button
                            class="backBtn bg-[#E0E4EC] text-gray-700 hover:bg-black/10 menuBtn lg:hidden block"
                            @click="
                                () => {
                                    this.$router.go(-1);
                                }
                            "
                        >
                            <font-awesome-icon :icon="['fas', 'angle-left']" class="pl-8" />
                        </button>
                        <Popper :content="$t('Edit, Duplicate and Delete')" placement="top" :hover="true">
                            <button @click="toggleProjectMenu" style="cursor: pointer" class="backBtn bg-[#E0E4EC] text-gray-700 mt-1 hover:bg-black/10 menuBtn">
                                <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" class="pl-7" />
                            </button>
                        </Popper>
                        <ProjectMenu
                            :showDetails="showMenu"
                            :toggleMenu="toggleProjectMenu"
                            :editable="invitations.length > 0 ? false : true"
                            :thisProject="project"
                            :hideSample="true"
                            class="absolute top-0 right-0"
                            style="position: absolute; right: 20%"
                        />
                        <Popper :content="$t('Preview')" placement="top" :hover="true">
                            <div
                                class="text-slate-700 px-4 font-light text-sm py-3 my-1 backBtn bg-[#E0E4EC] text-gray-700 hover:bg-black/10 menuBtn flex items-center justify-center cursor-pointer"
                                @click.stop
                                @click="navigateToPreview"
                            >
                                <!-- <p>{{ $t("Preview") }}</p> -->
                                <font-awesome-icon :icon="['fas', 'eye']" class="w-5 h-5" />
                            </div>
                        </Popper>
                        <Popper :content="$t('Invite candidates')" placement="top" :hover="true">
                            <button ref="targetElement3" @click="toggleEmail" class="nextStep shadow ml-auto">Invite</button>
                        </Popper>
                    </div>
                </div>

                <div class="navigation-tabs bg-white h-[62px] rounded shadow-card">
                    <div class="nav-links font-inter text-md pt-5 font-bold text-left decoration-skip-ink-none flex flex-row justify-between items-center gap-4 text-[#343637] h-full">
                        <router-link to="#" @click.prevent="navigateToDashBoard(project)" :class="`${this.$route.path == '/boards' ? 'active' : ''}`">
                            <span>Summary</span>
                        </router-link>
                        <router-link to="#" class="disabled" @click.prevent="navigateToVideoBoard(project)" :class="`${this.$route.path == '/DashVideo' ? 'active' : ''}`">
                            <div class="absolute top-[-15px] right-[-25px] rounded-[15px] text-[12px] px-2 text-[#fff] bg-[#2371b6]">Soon</div>
                            <span class="hidden lg:block">Video Interview</span>
                        </router-link>

                        <router-link to="#" @click.prevent="navigateToCheatBoard(project)" :class="`${this.$route.path == '/CheatTab' ? 'active' : ''}`">
                            <span>Anti cheat</span>
                        </router-link>

                        <!-- <router-link to="/InvitedCoworkerTab" :class="`${this.$route.path == '/InvitedCoworkerTab' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Invited Coworkers</span>
                </router-link> -->
                    </div>
                </div>

                <div v-if="allCheaters && allCheaters.length > 0" class="p-4 bg-white w-full">
                    <!-- Table -->
                    <div class="overflow-x-auto">
                        <table class="w-full table-auto border-none">
                            <thead>
                                <tr class="text-[#000000DE]">
                                    <th class="px-4 py-2 text-left font-roboto">Talents</th>
                                    <th class="px-4 py-2 text-left font-roboto">Top Category</th>
                                    <th class="px-4 py-2 text-left font-roboto">Last Assessment</th>
                                    <th class="px-4 py-2 text-left font-roboto">Score</th>
                                    <th class="px-4 py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cheater in allCheaters.slice(0, 3)" :key="cheater._id" class="border-t hover:bg-gray-50">
                                    <td class="px-4 py-2 flex items-center">
                                        <img src="https://via.placeholder.com/40" alt="Avatar" class="w-10 h-10 rounded-full mr-3" />
                                        {{ cheater.candidate_id?.First_name + " " + cheater.candidate_id?.Last_name }}
                                    </td>
                                    <td class="px-4 py-2 text-[#00AEF0]"></td>
                                    <td class="px-4 py-2 font-roboto font-normal tracking-[0.6018px] text-left decoration-skip-ink-none">'—'</td>
                                    <td class="px-4 py-2 font-roboto font-normal tracking-[0.6018px] text-left decoration-skip-ink-none">{{ cheater.candidate_id?.Score || "—" }}</td>
                                    <td class="px-4 py-2">
                                        <button
                                            v-if="cheater.status === 'potential-cheater'"
                                            @click="
                                                openCheatingCard();
                                                goToCheatingCard(cheater._id);
                                            "
                                            class="px-4 py-2 text-white bg-[#00AEF0] rounded hover:bg-white hover:text-[#00AEF0] transition duration-150 font-roboto font-normal tracking-[0.6018px] text-left decoration-skip-ink-none"
                                        >
                                            VERIFY
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Pagination -->
                    <div class="flex items-center justify-between mt-4">
                        <button class="px-3 py-2 bg-white text-black border rounded hover:text-[#00AEF0]" :disabled="currentPage === 1" @click="changePage(currentPage - 1)">Prev</button>
                        <div class="flex items-center space-x-2">
                            <button
                                v-for="page in totalPages"
                                :key="page"
                                :class="['px-3 py-2 rounded', currentPage === page ? 'bg-[#00AEF0] text-white' : 'text-black bg-white border border-black hover:text-[#00AEF0]']"
                                @click="changePage(page)"
                            >
                                {{ page }}
                            </button>
                        </div>
                        <button class="px-3 py-2 bg-white text-black border rounded hover:text-[#00AEF0]" :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">Next</button>
                    </div>
                </div>
                <div v-else class="flex content-center items-center no-cheater-container p-4 bg-white w-full">
                    <span class="text-center w-full span-no-cheater">No Cheaters Detected</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store/index";
import ProjectMenu from "@/components/ProjectMenu.vue";
import InviteCandidate from "@/components/InviteCandidate.vue";
import AllRatings from "@/components/AllRatings.vue";
import axios from "axios";
import introJs from "intro.js";
import "intro.js/introjs.css"; // Import Intro.js CSS
import LoadingComponent from "../components/LoadingComponent.vue";

export default {
    name: "DashboardView",
    components: {
        AllRatings,
        ProjectMenu,
        LoadingComponent,
        InviteCandidate,
    },
    data() {
        return {
            talents: [
                {
                    name: "Riad Mahrez",
                    avatar: "https://via.placeholder.com/40", // Replace with actual avatar
                    category: "Technical Skills",
                    lastAssessment: "July 14, 2015",
                    score: "58%",
                },
                {
                    name: "Riad Mahrez",
                    avatar: "https://via.placeholder.com/40",
                    category: "Technical Skills",
                    lastAssessment: "November 28, 2015",
                    score: null,
                },
                // Add more data entries here...
            ],
            currentPage: 1,
            totalPages: 2,
            selectedRank: "Best Ranked",
            selectedStatus: "Passed",
            imagePath: require(`../assets/onBoardingGIFs/inviteCandidate.gif`),
            showEmail: false,
            windowWidth: 500,
            graphData: [],
            selected: [true, false, false],
            current: 0,
            invitations: [],
            allCheaters: [],
            candidates: [],
            compitionRate: 0,
            project: {},
            candidatesScores: [],
            threeCandidates: [],
            candidatesRating: [],
            diskData: [],
            histogramData: [],
            histogramData2: [],
            ThreeRatings: [],
            behindSchedule: null,
            id: "",
            isCheatingCardOpen: false,
            candidateCard: {},
            showMenu: false,
            isLoading: true,
            viewAllRatings: false,
            viewAllCheaters: false,
            users: [
                { name: "User 1", email: "user1@example.com" },
                { name: "User 2", email: "user2@example.com" },
                { name: "User 3", email: "user3@example.com" },
            ],
        };
    },
    methods: {
        navigateToPreview() {
            const locale = this.$route.params.locale || "en";

            const url = this.$router.resolve({
                path: `/${locale}/preview-project`,
                query: { id: this.project._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
        navigateToDashBoard(project) {
            const locale = this.$route.params.locale || "en";

            this.$router.push({
                path: `/${locale}/boards`,
                query: { id: project._id },
            });
        },
        navigateToCheatBoard(project) {
            const locale = this.$route.params.locale || "en";

            this.$router.push({
                path: `/${locale}/CheatTab`,
                query: { id: project._id },
            });
        },
        navigateToVideoBoard(project) {
            const locale = this.$route.params.locale || "en";

            this.$router.push({
                path: `/${locale}/DashVideo`,
                query: { id: project._id },
            });
        },

        async getCandidateInv() {
            // if (!this.candidateInfo || !this.candidate || !this.projectId) {
            //     console.error("Candidate information is incomplete.");
            //     return;
            // }

            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/candidates/candidateInfo",
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    email: this.candidateEmail,
                    projectId: this.projectId,
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    this.candidateInv = response.data.CandidateInvitation;
                    this.Store.candidateInfoAct = response.data.candidateInfo;
                    this.Store.candidateActivity = response.data.candidateScore;
                    this.candidateInfo = response.data.candidateInfo;
                    this.candidate = response.data.candidateScore;
                    this.candidateInterpretations = response.data.interpretations;
                    this.candidateRating = response.data.candidateRating;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        startIntro() {
            // Initialize Intro.js
            const intro = introJs();

            // Set Intro.js options
            intro.setOptions({
                steps: [
                    {
                        element: this.$refs.targetElement3,
                        // <img src="${this.imagePath}" alt="Image Description">
                        intro: `<h3>Invite candidate</h3>
                        <p> Invite candidate that you want to take test</p>`,
                        position: "bottom",
                    },

                    // Add more steps as needed
                ],
            });

            // Start the introduction
            intro.start();
        },

        toggleProjectMenu() {
            this.showMenu = !this.showMenu;
        },

        toggleRatings() {
            this.viewAllRatings = !this.viewAllRatings;
        },
        goToCheatingCard(id) {
            const locale = this.$route.params.locale || "en";

            this.$router.push({
                path: `/${locale}/anti-cheat/${id}`,
                // query: { id: this.project._id },
            });
        },
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },

        openCheatingCard() {
            this.isCheatingCardOpen = true;
        },
        calculateAverageTime() {
            let averageTime = 0;
            this.candidates.map((candidate) => {
                averageTime += candidate.candidateTime;
            });
            averageTime = averageTime / this.candidates.length;
            return this.calculateTime(averageTime);
        },
        calculateTime(time) {
            let minutes = Math.floor(time / (60 * 1000));
            if (minutes > 59) minutes = 59;

            let seconds = time % (60 * 1000);
            if (seconds > 59) seconds = 59;

            minutes = minutes.toString().padStart(2, "0");

            if (seconds < 10) {
                seconds = "0" + seconds.toString();
            } else {
                seconds = seconds.toString();
            }
            if (isNaN(minutes)) {
                return "00 min 00 sec";
            }
            return `${minutes} min ${seconds} sec`;
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        getAllCheaters(id) {
            axios.get(`https://server.go-platform.com/anticheat/potential-cheaters/${id}`).then((response) => {
                const potentialCheaters = response.data;
                const cheaters = response.data;
                this.allCheaters = [...potentialCheaters, ...cheaters];
                console.log("THESES AER ALL THE CHEATERS");
                console.log(this.allCheaters);
                console.log("THESES AER ALL THE CHEATERS");
            });
        },

        getCandidatesPassed() {
            let candidatesPassed = this.candidates.filter((candidate) => {
                let score = 0;
                candidate.results.forEach((element) => {
                    score += (element.totalPoints * 100) / element.quesionsNbr;
                });
                const averageScore = score / candidate.results.length;
                // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage
                return averageScore > this.project.min_score; // Filter candidates with scores over 80%
            });
            return candidatesPassed.length > 0 ? candidatesPassed.length : 0;
        },
        getSuccessRate() {
            if (this.candidates.length > 0) {
                const candidatesAbove80Percent = this.candidates.filter((candidate) => {
                    let score = 0;
                    candidate.results.forEach((element) => {
                        score += (element.totalPoints * 100) / element.quesionsNbr;
                    });
                    const averageScore = score / candidate.results.length;
                    // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage

                    return averageScore > this.project.min_score; // Filter candidates with scores over 80%
                });

                const numberOfCandidatesAbove80Percent = candidatesAbove80Percent.length;
                const totalCandidates = this.candidates.length;

                const percentageAbove80Percent = (numberOfCandidatesAbove80Percent / totalCandidates) * 100;
                return percentageAbove80Percent.toFixed(2);
            } else return 0;
        },
        async getBestCandidate(id) {
            if (id === "") {
                // this.filteredCandidates = this.candidatesResults;
                return;
            }
            this.histogramData = [];
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/company/bestCandidate/${id}`,
                headers: {},
            };
            this.isLoading = true;
            axios
                .request(config)
                .then(async (response) => {
                    console.log({ HISTODATA: response });
                    this.histogramData2 = response.data.candidatesScore;
                    // this.averageResults = response.data.averageData;
                    // this.averageScore();
                    // this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    // this.isLoading = false;
                });
        },
    },
    computed: {
        filteredCandidates() {
            // Example filtering logic based on selectedRank and selectedStatus
            return this.candidates.filter((candidate) => {
                if (this.selectedStatus === "Passed" && candidate.rating < 1) {
                    return false;
                }
                if (this.selectedStatus === "Not Passed" && candidate.rating >= 3) {
                    return false;
                }
                return true;
            });
        },
        filteredAssessmentsLength() {
            if (!this.project || !this.project.assessments) {
                return 0;
            }

            const hasCustomAssessment = this.project.assessments.some((assessment) => assessment.category === "Custom");

            return hasCustomAssessment ? this.project?.assessments?.length - 1 : this.project?.assessments?.length;
        },
        filteredAssessments() {
            return this.project.assessments.filter((assessment) => assessment.category !== "Custom");
        },
        customAssessments() {
            return this.project.assessments.find((assessment) => assessment.category == "Custom");
        },
        totalDuration: {
            get() {
                if (this.project?.assessments?.length > 0) {
                    const totalSeconds = this.filteredAssessments.reduce((acc, assessment) => {
                        if (assessment?.questions_nbr > 25) {
                            return acc + 20 * 35;
                        } else {
                            return acc + assessment?.questions_nbr * 35;
                        }
                    }, 0);

                    const minutes = Math.floor(totalSeconds / 60);
                    const seconds = totalSeconds % 60;
                    return `${minutes}:${seconds}`;
                } else {
                    return { minutes: 0, seconds: 0 };
                }
            },
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    async mounted() {
        this.getCandidateInv();
        const headers = {
            "Content-Type": "application/json",
            withCredentials: true,
        };

        await axios
            //https://server.go-platform.com/
            .get(`https://server.go-platform.com/tooltip/get`, {
                headers,
                withCredentials: true,
            })
            .then((res) => {
                //alert(res.data);
                if (res.data != 2) {
                    setTimeout(() => {
                        this.startIntro();
                    }, 1500);

                    axios.get(`https://server.go-platform.com/tooltip/post2`, {
                        headers,
                        withCredentials: true,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });

        //this.startIntro();

        //?
        this.id = this.$route.query.id;
        this.getAllCheaters(this.id);
        this.getBestCandidate(this.id);

        await this.Store.fetchProjects();
        // this.Store.fetchCandidates();
        this.Store.getCompanyAssessments();
        this.Store.fetchInvitations();

        this.project = this.Store.projects[0]?._id;

        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: "https://server.go-platform.com/projects/projectData",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                id: this.id,
            },
            withCredentials: true,
        };

        await axios
            .request(config)
            .then((response) => {
                this.invitations = response.data.invited;
                this.candidates = response.data.candidates;
                this.behindSchedule = this.invitations.length - this.candidates.length;
                this.threeCandidates = this.candidates.slice(0, 3);
                this.project = response.data.project;
                this.compitionRate = (this.candidates.length / this.invitations.length).toFixed(2);
                this.graphData = response.data.chartData;
                this.histogramData = [
                    {
                        label: "Invited",
                        value: this.invitations.length,
                    },
                    {
                        label: "Attempted",
                        value: this.candidates.length,
                    },
                    {
                        label: "Passed",
                        value: this.getCandidatesPassed(),
                    },
                ];
                this.isLoading = false;
                // this.yourAssessment = response.data.assessments;
                // this.score = response.data.score;
            })
            .catch((error) => {
                console.log(error);
            });
        this.diskData = this.Store.createDistributionData(this.id);

        let config2 = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://server.go-platform.com/AssessmentTest/candidateRating/${this.id}`,
            headers: {},
            withCredentials: true,
        };
        axios
            .request(config2)
            .then((response) => {
                this.candidatesRating = response.data;
                this.ThreeRatings = this.candidatesRating.slice(0, 3);
            })
            .catch((error) => {
                console.log({ error });
            });
    },
};
</script>

<style scoped lang="scss">
@import "tailwindcss/tailwind.css";

.board-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.indicator {
    display: grid;
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 20px;
    padding: 4px;
    align-self: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.green {
    background: rgba(5, 205, 153, 0.1);
    color: #05cd99;
}

.red {
    background: rgba(227, 26, 26, 0.1);
    color: #e31a1a;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.score-applicants {
    // widows: 700px;
    /* display: grid; */
    grid-template-columns: 65% 33%;
    padding: 36px 0px;
    gap: 20px;
}

.score {
    width: 100%;
    display: grid;
    align-items: center;
    /* grid-template-rows: 1fr 3fr; */
    background-color: #fff;
    border-radius: 20px;
    padding: 16px;
}

.applicants {
    // width: 100%;
    height: fit-content;
    background-color: #fff;
    border-radius: 20px;
    padding: 0 16px;
    padding-bottom: 36px;
}

.stat-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    /* padding: 0px 48px; */
    margin: 2% 0;
}

.stat-widget > div {
    width: 100%;
    box-shadow: 0px 18px 40px rgb(112 144 176 / 12%);
    padding: 8px 16px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
    background: #fff;
    border-radius: 20px;
}

.stat-widget > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.avg {
    display: flow-root;
}

.calendar-candidate {
    padding: 0px;
    width: 100%;
    // display: grid;
    grid-template-columns: 33% 65%;
    gap: 20px;
    /* gap: 20px; */
    margin: 0px 0px;
}

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    right: -215%;
    width: 90%;
    padding: 10%;
    position: relative;
    margin-top: 15%;
    margin-bottom: 30%;
}

.calendar {
    width: 100%;
    display: flow-root;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
    // padding-top: 10%;
}

.potential_containers {
    background: #ffefd6;
    color: #f76b15;
    // border: 2px solid #F76B15;
    border-radius: 8px;
    padding: 0.6rem 0.6rem;
    width: 100%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cheaters_containers {
    background: #feebec;
    color: #ce2c31;
    // border: 2px solid #F76B15;
    border-radius: 8px;
    padding: 0.6rem 0.6rem;
    width: 100%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1.15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cheater-span {
    color: #a3aed0;
    font-size: 1rem;
}

.verify-container {
    padding: 5px 10px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    background: #fff;
    border-radius: 10px;
    color: #53b9ab;
    cursor: pointer;
    border: 1px solid #53b9ab;

    &:hover {
        opacity: 0.85;
    }
}

.calendar > button {
    margin: 3% 0% 3% 63%;
}

.candidate {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.menuBtn {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.score-head {
    /* width: 90%; */
    // margin: 0 3%;
    display: grid;
    justify-items: baseline;
    align-items: center;
    grid-template-columns: 5fr 2fr;
}

.backBtn {
    width: 60px;
    height: 50px;

    border-radius: 10px;
}

.projData {
    font-size: 20px;
    font-weight: 700;
}

.score-head > div {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center; */
}

.score-head {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.score-head > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.score-head > div > span {
    color: #05cd99;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 30%;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}
/*
.nav-links a.active * {
    color: #00AEF0;

}


.nav-links a::after {
    content: "";
    background: #00AEF0;
    position: absolute;
    color: #00AEF0;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;

}
*/
.tasks {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    display: grid;
    background-color: #fff;
    border-radius: 20px;
    margin: 10% 10% 10% 2%;
    padding: 0% 3%;
}

.tasks > div {
    border-left: 3px solid #4318ff;
    display: grid;
    gap: 3px;
    justify-items: left;
    padding-left: 3%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.thedate {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;
}

.time-stamp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #a3aed0;
}

.thetask {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1b2559;
}

.tasks > div:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
    gap: 8px;
}

/*----------------------------------------------------------------*/

.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
}

.nav-links span {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: black;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    right: -215%;
    width: 90%;
    padding: 10px;
    position: relative;
}

/* .applicants {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 16px;
    display: flow-root;
    text-align: left;
} */

.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}

.score2 {
    display: grid;
    height: 420px;
    width: 100%;
    align-items: center;
    grid-template-rows: 1fr 2fr 3fr;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 16px 0 16px;
}

.nextStep {
    width: 100px;
    height: 50px;
    color: white;
    font-weight: 500;
    background: #2196f3;
    border-radius: 10px;

    &:hover {
        opacity: 0.85;
    }
}

.svgBG {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.noRating {
    margin-top: -10%;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 300;
    text-align: center;

    span {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        width: 90%;
    }
}

.span-no-cheater {
    color: #bbbbbb;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.no-cheater-container {
    height: 80%;
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assessments {
    border-bottom: 1px solid #dcdcdc;
}

.assessments:last-child {
    border: none;
}

.introjs-tooltip {
    min-width: 400px;
    max-width: 400px;
}

.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 25px;
}
.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
    color: #00aef0;
}

.nav-links a.active::after {
    background: #00aef0;
    width: 100%;
}

.nav-links a.active * {
    color: #00aef0;
}
.disabled {
    pointer-events: none;

    span {
        opacity: 0.5;
    }
}
</style>
