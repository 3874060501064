<template>
    <div ref="menuContainer" class="flex flex-col justify-center relative items-center">
        <button @click="toggleShow" class="anchor">
            <font-awesome-icon :icon="['fas', 'language']" class="text-base text-slate-700" />
            <!-- <font-awesome-icon :icon="['fas', 'globe']" class="text-[#a0a0a0] text-4xl w-14 h-7" /> -->
            {{ this.$i18n.locale.toUpperCase() }}
        </button>
        <div v-if="showMenu" class="menu">
            <div class="menu-item" v-for="language in languages" :key="language.local" @click="SwitchLocale(language.local)">
                <img loading="lazy" decoding="async" :src="require(`@/assets/Images/${language.flag}`)" :alt="language.local + ' flag'" class="w-8 h-6" />
                {{ language.language }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SwitchLanguage",
    data() {
        return {
            showMenu: false,
            languages: [
                {
                    language: "English",
                    local: "en",
                    flag: "united-kingdom_flag.svg",
                },
                {
                    language: "French",
                    local: "fr",
                    flag: "france_flag.svg",
                },
                // {
                //     language: "Arabic",
                //     local: "ar",
                //     flag: "france_flag.svg",
                // },
            ],
        };
    },
    props: {
        onClick: { type: Function },
    },
    methods: {
        toggleShow: function () {
            this.showMenu = !this.showMenu;
            if (this.showMenu) {
                document.addEventListener("click", this.outsideClick);
            } else {
                document.removeEventListener("click", this.outsideClick);
            }
        },
        outsideClick(event) {
            if (!this.$refs.menuContainer.contains(event.target)) {
                this.showMenu = false;
                document.removeEventListener("click", this.outsideClick);
            }
        },
        SwitchLocale(lang) {
            this.$i18n.locale = lang;
            // console.log('locale....---**', lang)
            this.toggleShow();
            // this.$i18n.locale = locale; // Update vue-i18n locale
            localStorage.setItem("locale", lang); // Persist language choice
            const path = `/${lang}`; // Construct language-specific path
            window.location.pathname = path + window.location.pathname.replace(/^\/(en|fr|ar)/, ""); // Update URL

            // console.log("current locale", this.flag, this.locale);
            // this.$i18n.locale = this.$i18n.locale === "en" ? "fr" : "en";
            // this.locale = this.$i18n.locale === "en" ? "French" : "English";
        },
    },
};
</script>

<style lang="scss" scoped>
.anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    // border: 1px solid transparent;
    // padding: .75rem 2rem;
    // font-size: 1rem;
    // border-radius: .25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    // color: #fff;
    // background-color: #27AE60;
    // border-color: #27AE60;
}

// .anchor::after {
// 	display: inline-block;
//     width: 0;
//     height: 0;
//     margin-left: .5em;
//     vertical-align: .255em;
//     content: "";
//     border-top: .3em solid;
//     border-right: .28em solid transparent;
//     border-bottom: 0;
//     border-left: .28em solid transparent;
//     color: #2196f3;
// }

.anchor:hover {
    // color: #fff;
    // background-color: #229954;
    // border-color: #229954;
    cursor: pointer;
}

.menu {
    background-color: #fff;
    background-clip: padding-box;
    // border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    color: #263238;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    // font-size: 1rem;
    list-style: none;
    margin: 0.125rem 0;
    margin-right: 1rem;
    padding: 0.5rem 0rem;
    position: absolute;
    top: 0;
    right: 100%;
    transform: translateX(50%);
    text-align: left;
    z-index: 5;
    // transform: translateX(-50%);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.05);
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #263238;
    padding: 0.25rem 1.5rem;
    font-size: 14px;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.menu-item:hover {
    background-color: #f4f6f6;
    cursor: pointer;
}

span {
    font-weight: bold;
    // color: #229954;
    font-size: 1.25rem;
}
</style>
