<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-10 z-[100] flex items-center justify-center" style="z-index: 1000">
        <div
            class="fixed top-0 left-0 w-full h-full"
            style="z-index: 1"
            @click="
                () => {
                    toggleScreener();
                }
            "
        ></div>
        <div v-if="newQst" class="absolute top-[5%] left-0 w-full flex items-start justify-center h-full z-[400]">
            <div class="w-[30%] h-[20%] rounded-md bg-[#fff] shadow-card p-4 flex flex-col justify-between animate-slide-from-top">
                <h1 class="text-[14px] font-md">
                    {{ $t("Question") }} <span class="text-[16px] font-xl">"{{ selectedQst.question }}"</span> {{ $t("added successfully") }}!
                </h1>
                <div class="flex justify-end items-center gap-4">
                    <ButtonComponent
                        :action="
                            () => {
                                this.newQst = false;
                                if (!this.previewQst) {
                                    this.selectedQst = {};
                                }
                            }
                        "
                        intent="secondary"
                    >
                        {{ $t("Close") }}
                    </ButtonComponent>
                    <ButtonComponent :action="editQst" intent="primary"> {{ $t("Edit Question") }} </ButtonComponent>
                </div>
            </div>
        </div>
        <div class="w-[90%] h-[95%] shadow-card bg-white rounded-md p-2 flex gap-4" style="z-index: 5">
            <div class="w-[20%]">
                <!-- @click="toggleScreener" -->

                <div
                    v-for="(qst, index) in selectedScreeners"
                    :key="index"
                    class="w-full text-[18px] justify-center p-2 my-2 border rounded-md hover:bg-[#cccccc85] duration-300 screenerBtn"
                    :class="{ 'bg-[#cccccc85]': selectedQst.id === qst.id }"
                    @click="
                        () => {
                            this.selectedQst = qst;
                            console.log({ qst });
                            this.previewQst = true;
                        }
                    "
                >
                    <div class="flex items-center gap-2">
                        <div class="w-fit flex items-center gap-2 bg-[#2196f3] text-[#fff] p-2 rounded-md text-[14px] font-md">
                            <font-awesome-icon :icon="['fas', 'bars']" class="text-[#fff] icon" />Q{{ index + 1 }}
                        </div>

                        <div class="w-fit flex items-center gap-2 text-slate-700 p-2 rounded-md text-[14px] font-md">
                            <font-awesome-icon :icon="['fas', 'circle-check']" class="text-[#00B898]" />{{ $t("Screener") }}
                        </div>
                    </div>
                    <span class="text-[12px] text-slate-700">
                        {{ qst.question }}
                    </span>
                </div>
                <button
                    @click="
                        () => {
                            this.previewQst = false;
                            this.selectedQst = {};
                            this.editing = false;
                            this.newQstData = {
                                type: 'single',
                                question: '',
                                options: [
                                    {
                                        answer: '',
                                        pass: true,
                                    },
                                    {
                                        answer: '',
                                        pass: false,
                                    },
                                ],
                            };
                        }
                    "
                    class="w-full flex flex-col items-center text-[18px] justify-center py-6 my-2 border rounded-md hover:text-[#fff] hover:bg-[#2196f3] duration-300 screenerBtn"
                >
                    <font-awesome-icon :icon="['fas', 'plus']" class="text-[#2196f3] icon" />{{ $t("New Question") }}
                </button>
            </div>
            <div class="w-[80%]">
                <div v-if="previewQst" class="w-full">
                    <div class="rounded-md p-4">
                        <div class="flex justify-between">
                            <h1 class="text-[20px] text-slate-700 font-[600]">{{ selectedQst.question }}</h1>

                            <!-- <ButtonComponent :action="closeInvitation" intent="secondary">
                                    {{ $t("Cancel") }}
                                </ButtonComponent> -->
                            <div class="flex items-center gap-8">
                                <Popper :content="$t('Delete')" placement="bottom" :hover="true">
                                    <font-awesome-icon class="text-[20px] text-slate-700" @click="deleteQst" :icon="['far', 'trash-can']" />
                                </Popper>
                                <Popper @click="editQst" :content="$t('Edit')" placement="bottom" :hover="true">
                                    <font-awesome-icon class="text-[20px] text-slate-700" :icon="['far', 'pen-to-square']" />
                                </Popper>
                            </div>
                        </div>
                        <hr class="h-[5px] bg-[#F5F8FF] my-5" />

                        <div class="mt-2">
                            <div v-if="selectedQst.type === 'single'">
                                <div v-for="(option, index) in selectedQst.options" :key="index" :class="{ 'radio-container': true }" class="relative">
                                    <Popper :content="$t('This preview mode is not interactive, however, the assessment your candidate will see is')" placement="bottom" :hover="true">
                                        <div>
                                            <input type="radio" :id="option" name="option" :value="option" class="custom-radio" />
                                            <label :for="option" class="mx-5">{{ option.answer }}</label>
                                        </div>
                                    </Popper>
                                </div>
                            </div>
                            <div v-else-if="selectedQst.type === 'multiple'">
                                <div v-for="(option, index) in selectedQst.options" :key="index" class="flex items-center gap-2" :class="{ 'radio-container': true }" @click="selectRadio(option)">
                                    <Popper :content="$t('This preview mode is not interactive, however, the assessment your candidate will see is')" placement="bottom" :hover="true">
                                        <div>
                                            <input type="checkbox" name="question" />
                                            <label>{{ option.answer }}</label>
                                        </div>
                                    </Popper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="h-[100%]">
                    <div class="w-full flex items-center justify-content">
                        <div class="w-full flex text-[#A9AAAE] py-4 items-center gap-4">
                            <button
                                @click="
                                    () => {
                                        selectedTab = 'Create';
                                    }
                                "
                                :class="selectedTab === 'Create' ? 'text-[#2196f3]' : 'text-[#A9AAAE]'"
                            >
                                {{ $t("Create") }}
                            </button>
                            <span> | </span>
                            <button
                                @click="
                                    () => {
                                        selectedTab = 'Suggested';
                                    }
                                "
                                :class="selectedTab === 'Suggested' ? 'text-[#2196f3]' : 'text-[#A9AAAE]'"
                            >
                                {{ $t("Suggested") }}
                            </button>
                        </div>
                        <ButtonComponent
                            :action="
                                () => {
                                    toggleScreener();
                                }
                            "
                            intent="secondary"
                        >
                            {{ $t("Close") }}
                        </ButtonComponent>
                    </div>
                    <hr class="h-[5px] bg-[#F5F8FF]" />

                    <div class="mt-[1%] h-[85%]" v-if="selectedTab === 'Suggested'">
                        <h1 class="text-[20px] font-md">{{ $t("Suggested Screening Questions") }}</h1>
                        <div class="mt-[3%] p-2 h-[95%] overflow-y-scroll">
                            <div class="grid grid-cols-2 gap-4">
                                <div v-for="question in questions" :key="question.id" class="shadow-card rounded-md p-4">
                                    <div class="flex justify-between">
                                        <h1 class="text-[16px] text-slate-700 font-md">{{ question.question }}</h1>
                                        <!-- <ButtonComponent :action="closeInvitation" intent="secondary">
                                    {{ $t("Cancel") }}
                                </ButtonComponent> -->
                                        <div class="text-[12px]">
                                            <button @click="addQst(question.id)" class="bg-transparent rounded-md flex items-center gap-2 p-2 shadow-card hover:shadow-none duration-300">
                                                <font-awesome-icon :icon="['fas', 'plus']" class="text-[#2196f3] icon" />
                                                {{ $t("add") }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div v-if="question.type === 'single'">
                                            <div v-for="(option, index) in question.options" :key="index" :class="{ 'radio-container': true }" @click="selectRadio(option)" class="relative">
                                                <Popper :content="$t('This preview mode is not interactive, however, the assessment your candidate will see is')" placement="bottom" :hover="true">
                                                    <div>
                                                        <input type="radio" :id="option" name="option" :value="option" class="custom-radio" />
                                                        <label :for="option" class="mx-5">{{ option.answer }}</label>
                                                    </div>
                                                </Popper>
                                            </div>
                                        </div>
                                        <div v-else-if="question.type === 'multiple'">
                                            <div
                                                v-for="(option, index) in question.options"
                                                :key="index"
                                                class="flex items-center gap-2"
                                                :class="{ 'radio-container': true }"
                                                @click="selectRadio(option)"
                                            >
                                                <Popper :content="$t('This preview mode is not interactive, however, the assessment your candidate will see is')" placement="bottom" :hover="true">
                                                    <div>
                                                        <input type="checkbox" name="question" />
                                                        <label>{{ option.answer }}</label>
                                                    </div>
                                                </Popper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="mt-[1%] h-[85%] overflow-y-scroll p-2">
                        <div class="flex items-center justify-between">
                            <h1 v-if="!editing" class="text-[20px] text-slate-700 font-[600]">{{ $t("Question") }} {{ selectedScreeners.length + 1 }}:</h1>
                            <h1 v-else class="text-[20px] text-slate-700 font-[600]">{{ $t("Question") }} {{ this.selectedQst.id }}:</h1>
                            <ButtonComponent
                                :action="
                                    () => {
                                        this.clear();
                                    }
                                "
                                intent="secondary"
                            >
                                {{ $t("Clear") }}
                            </ButtonComponent>
                        </div>
                        <hr class="h-[5px] bg-[#F5F8FF] my-5" />
                        <div class="relative mb-[3%]">
                            <textarea
                                v-model="newQstData.question"
                                name="question"
                                id=""
                                cols="30"
                                rows="3"
                                placeholder="Write your question here"
                                class="border border-[#A9AAAE] rounded-md w-full p-2"
                            ></textarea>
                            <p class="text-red-500 text-xs mt-1" v-if="qstErr">{{ qstErr }}</p>
                        </div>

                        <h3 class="text-[16px] font-[500]">{{ $t("Answer Choices") }}</h3>
                        <p>{{ $t("Select one or multiple correct answers for this question") }}</p>
                        <label class="inline-flex items-center cursor-pointer my-4">
                            <input type="checkbox" v-model="qstType" class="sr-only peer" :checked="qstType === 'single' ? false : true" />
                            <div
                                class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                            ></div>
                            <span class="ms-3 text-sm font-medium text-gray-500 dark:text-gray-300">{{ $t("Enable multiple correct answers") }}</span>
                        </label>
                        <div class="flex gap-5 w-full mt-[10px]" v-for="(option, index) in newQstData.options" :key="index">
                            <FloatingLabel label="Enter answer" name="Answer" type="text" v-model="option.answer" :setInputs="setInputs" :answerIndx="index" :value="option.answer" />
                            <div class="w-[25%] flex items-center gap-4 mr-4">
                                <SelectComponent2
                                    v-if="newQstData.type == 'single'"
                                    label="Grade"
                                    :value="option.pass ? 'Pass' : 'Fail'"
                                    :options="['pass', 'fail']"
                                    :setOption="handleOptionChange"
                                    :errorMgs="errorMessage"
                                    :answerIndx="index"
                                />
                                <div v-if="this.newQstData.options.length > 2">
                                    <Popper :content="$t('Delete')" placement="bottom" :hover="true">
                                        <font-awesome-icon class="text-[20px] text-slate-700" @click="() => deleteAnswer(index)" :icon="['far', 'trash-can']" />
                                    </Popper>
                                </div>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs mt-1">{{ ansrErr }}</p>
                        <div class="flex justify-start items-center gap-4 mt-4">
                            <ButtonComponent :action="newAnswer" intent="secondary">{{ $t("Add Answer") }}</ButtonComponent>
                            <ButtonComponent :action="createQst" intent="primary">{{ $t("Save Question") }}</ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "./ReusableComponents/ButtonComponent.vue";
import FloatingLabel from "./ReusableComponents/FloatingLabel.vue";
import SelectComponent2 from "@/components/ReusableComponents/SelectComponent2.vue";
import { useStore } from "../store/index";

export default {
    name: "ScreenerQst",
    components: {
        ButtonComponent,
        FloatingLabel,
        SelectComponent2,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            selectedTab: "Create",
            qst: "ScreenerQst",
            selectedQst: {},
            qstType: "single",
            previewQst: false,
            username: "",
            qstErr: "",
            ansrErr: "",
            questions: [
                {
                    id: 1,
                    question: "Are you legally authorized to work in [country/region]?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 2,
                    question: "Are you willing to relocate for this position?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 3,
                    question: "What is your desired salary expectation range for this position?",
                    options: [
                        { answer: "Bellow $50,000", pass: true },
                        { answer: "$50,000 - $75,000", pass: true },
                        { answer: "$75,000 - $100,000", pass: true },
                        { answer: "Above $100,000", pass: true },
                    ],
                    type: "single",
                },
                {
                    id: 4,
                    question: "How many years of industry specific experience do you have?",
                    options: [
                        { answer: "0-2 years", pass: true },
                        {
                            answer: "2-5 years",
                            pass: true,
                        },
                        {
                            answer: "5-10 years",
                            pass: true,
                        },
                        {
                            answer: "10+ years",
                            pass: true,
                        },
                        {
                            answer: "None",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 5,
                    question: "What is your availability for this position?",
                    options: [
                        { answer: "Full-time", pass: true },
                        {
                            answer: "Part-time",
                            pass: true,
                        },
                        {
                            answer: "Part-time",
                            pass: true,
                        },
                        {
                            answer: "Casual",
                            pass: true,
                        },
                        {
                            answer: "Morning",
                            pass: true,
                        },
                        {
                            answer: "Afternoon",
                            pass: true,
                        },
                        {
                            answer: "Evening",
                            pass: true,
                        },
                    ],
                    type: "multiple",
                },
                {
                    id: 6,
                    question: "Do you have any physical or medical conditions that would prevent you from performing the essential functions of the job?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 7,
                    question: "Have you ever been terminated from a previous job for reasons related to misconduct or performance issues?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 8,
                    question: "Do you possess the required education or certification for this position?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
                {
                    id: 9,
                    question: "Do you meet the minimum age requirement for this position?",
                    options: [
                        {
                            answer: "Yes",
                            pass: true,
                        },
                        {
                            answer: "No",
                            pass: false,
                        },
                    ],
                    type: "single",
                },
            ],
            selectedScreeners: this.Store.screenersQst,
            newQst: false,
            newQstData: {
                type: "single",
                question: "",
                options: [
                    {
                        answer: "",
                        pass: true,
                    },
                    {
                        answer: "",
                        pass: false,
                    },
                ],
            },
            editing: false,
            change: false,
        };
    },
    props: {
        toggleScreener: {
            type: Function,
        },
    },
    watch: {
        qstType(val) {
            if (val) {
                this.newQstData.type = "multiple";
                this.newQstData.options.map((option) => (option.pass = true));
            } else {
                this.newQstData.type = "single";
            }
        },
        change(val) {
            console.log({ SCREENERS: val }, "---------------------==============================--------------------");
            this.Store.updateScreeners(this.selectedScreeners);
        },
    },
    methods: {
        selectRadio() {
            alert("This preview mode isn't interactive, however, the assessment your candidate will see is");
        },
        addQst(qstId) {
            let question = this.questions.find((qst) => qst.id === qstId);
            let foundQst = this.selectedScreeners.find((screener) => screener.question === question.question);
            console.log({ foundQst });
            if (foundQst) {
                this.selectedQst = foundQst;
                this.newQst = true;
                return;
            }
            question.id = this.selectedScreeners.length + 1;
            this.selectedQst = question;
            this.newQst = true;
            this.selectedScreeners = [...this.selectedScreeners, question];
            this.change = !this.change;
            console.log({ selectedScreeners: this.selectedScreeners });
        },
        deleteQst() {
            let foundQst = this.selectedScreeners.find((screener) => screener === this.selectedQst);
            this.selectedScreeners = this.selectedScreeners.filter((screener) => screener !== foundQst);
            this.change = !this.change;
            this.selectedQst = {};
            this.previewQst = false;
            console.log({ foundQst });
        },
        handleOptionChange(answerIndx, option) {
            switch (option) {
                case "pass":
                    this.newQstData.options[answerIndx].pass = true;
                    break;
                case "fail":
                    this.newQstData.options[answerIndx].pass = false;
                    break;
                default:
                    break;
            }
            console.log({ newQstData: this.newQstData });
        },
        newAnswer() {
            this.newQstData.options.push({
                answer: "",
                pass: true,
            });
        },
        setInputs(value, index) {
            this.newQstData.options[index].answer = value;
        },
        deleteAnswer(index) {
            this.newQstData.options = this.newQstData.options.filter((option, indx) => indx !== index);
        },
        createQst() {
            if (this.editing) {
                this.saveChanges();
                return;
            }
            console.log({ newQstData: this.newQstData });
            if (!this.newQstData.question) {
                this.qstErr = "Please enter a question";
                return;
            }
            if (this.newQstData.options[0].answer === "" || this.newQstData.options[1].answer === "") {
                this.ansrErr = "Please enter at least two answers";
                return;
            }
            this.newQstData.id = this.selectedScreeners.length + 1;
            this.selectedQst = this.newQstData;
            this.previewQst = true;
            this.newQst = true;
            this.selectedScreeners = [...this.selectedScreeners, this.newQstData];
            this.change = !this.change;
            this.qstType = false;
            this.newQstData = {
                type: "single",
                question: "",
                options: [
                    {
                        answer: "",
                        pass: true,
                    },
                    {
                        answer: "",
                        pass: false,
                    },
                ],
            };
        },
        editQst() {
            this.previewQst = false;
            this.newQst = false;
            this.editing = true;
            this.newQstData = structuredClone(this.selectedQst);
            this.selectedTab = "Create";
        },
        saveChanges() {
            let index = this.selectedScreeners.indexOf(this.selectedQst);
            this.selectedScreeners[index] = this.newQstData;
            this.change = !this.change;
            this.selectedQst = this.newQstData;
            this.newQstData = {
                type: "single",
                question: "",
                options: [
                    {
                        answer: "",
                        pass: true,
                    },
                    {
                        answer: "",
                        pass: false,
                    },
                ],
            };
            this.previewQst = true;
            this.editing = false;
            this.qstType = "single";
        },
        clear() {
            this.newQst = false;
            this.qstType = "single";
            this.newQstData = {
                type: "single",
                question: "",
                options: [
                    {
                        answer: "",
                        pass: true,
                    },
                    {
                        answer: "",
                        pass: false,
                    },
                ],
            };
            this.editing = false;
        },
    },
};
</script>

<style scoped lang="scss">
.radio-container {
    margin-top: 5%;
    margin-bottom: 10px;
    /* Adjust margin as needed */
    cursor: pointer;
    /* Change cursor to pointer */
    border: 1px solid #ccc;
    /* Border color */
    padding: 10px 10px;
    /* Adjust padding as needed */
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.radio-container:hover {
    background-color: #f0f0f0;
    /* Background color on hover */
}

/* Style for checked radio button container */
.checked {
    background-color: #2196f3;
    /* Background color when checked */
    color: #fff;
    /* Text color when checked */
    border-color: #2196f3;

    /* Border color when checked */
    &:hover {
        background-color: #2196f3;
        /* Background color when checked */
    }
}

.custom-radio {
    /* Hide the default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Create a custom circular radio button */
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    /* Position the radio button relative to its container */
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.custom-radio:checked {
    /* Change border color when checked */
    border-color: #fff;

    /* Add inner circle when checked */
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
    }
}
.screenerBtn {
    &:hover {
        .icon {
            color: #fff;
        }
    }
}
</style>
