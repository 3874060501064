<template>
    <section class="about-us">
        <div class="about-us-hero">
            <h1>{{ $t("How GO PLATFORM Helps") }}</h1>
            <h3>{{ $t("about us description") }}.</h3>
        </div>
        <AboutUsCards />
        <div class="about-us-button">
            <a href="#">{{ $t("More About GO Platform") }}</a>
        </div>
    </section>
</template>

<script>
import AboutUsCards from "./AboutUsCards.vue";
export default {
    name: "AboutUs",
    components: {
        AboutUsCards,
    },
};
</script>

<style scoped>
.about-us {
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
    padding: 50px 150px;
}

.about-us-hero {
    text-align: center;
    color: #ffffff;
}

.about-us-hero h1 {
    font-size: 2.2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.about-us-hero h3 {
    font-weight: 400;
    font-size: 0.9em;
    padding: 0 150px;
}

.about-us-button {
    text-align: center;
}

.about-us-button a {
    display: inline-block;
    padding: 10px 50px;
    color: #ffffff;
    background-color: #ffa500;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    transition: 0.7s ease;
}

.about-us-button a:hover {
    background-color: #ec9b04;
    transform: scale(1.1);
}

@media (max-width: 1023px) {
    .about-us {
        padding: 30px 20px;
    }

    .about-us-hero h1 {
        font-size: 24px;
    }

    .about-us-hero h3 {
        font-size: 12px;
        padding: 0 50px;
    }

    .about-us-button a {
        font-size: 12px;
        padding: 8px 40px;
    }
}
</style>
