import { createApp } from "vue";
import App from "./App.vue";
import "./assets/styles/style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import routes from "./router/index";
import { createPinia } from "pinia";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import "./assets/styles/vue-multiselect.css";
import "./assets/styles/theme.css";
import Popper from "vue3-popper";
import Vue3Tour from "vue3-tour";
import "vue3-tour/dist/vue3-tour.css";
import VueGtag from "vue-gtag";
import VueHighlightJS from "vue3-highlightjs";
import "highlight.js/styles/dracula.css";
import VuePerformance from "vue-performance";
import vue3GoogleLogin from "vue3-google-login";

// import { useStore } from "./store/index";
import { useAuthStore } from "./store/authStore";
import VueSweetalert2 from "vue-sweetalert2";
// import axios from "axios";

import i18n from "./i18n";

library.add(far, fas, fab);

const pinia = createPinia();
const app = createApp(App);

app.use(i18n);
app.use(VueHighlightJS);
app.use(pinia);
app.use(routes);
app.use(VueTelInput);
app.use(Vue3Tour);
app.use(Popper);
app.use(VueSweetalert2);

app.use(vue3GoogleLogin, {
    clientId: "946870147174-bc57mqn96cpg31f07ab1jhrju1n24uh8.apps.googleusercontent.com",
});
app.use(VuePerformance);
app.use(
    VueGtag,
    {
        appName: "GoPlatform",
        pageTrackerScreenviewEnabled: true,
        config: {
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
        },
    },
    routes,
);

app.component("font-awesome-icon", FontAwesomeIcon);

// Initialize Pinia store and login check
const authStore = useAuthStore();

// Route guard
routes.beforeEach(async (to, from, next) => {
    if (to.path === `/invite`) {
        return next();
    }

    // Language management
    const supportedLocales = ["en", "fr", "ar"];
    const pathSegments = to.path.split("/"); // Split the path into segments
    const firstSegment = pathSegments[1]; // Get the first segment
    const locale = supportedLocales.includes(firstSegment)
        ? firstSegment // If the first segment is a locale, use it
        : localStorage.getItem("locale") || "en"; // Otherwise, fallback to stored locale or 'en'

    // Redirect to language-specific path if no locale in the URL
    if (!supportedLocales.includes(firstSegment)) {
        return next(`/${locale}${to.path}`);
    }

    // Save the locale in localStorage for persistence
    localStorage.setItem("locale", locale);

    // Extract route meta information
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

    // Set the document title based on the route metadata
    document.title = `${to.meta.title || "Untitled"} | GO-Platform`;

    try {
        // Check login status via the auth store
        await authStore.checkLoginStatus();
        const isLoggedIn = authStore.isLoggedIn;
        const userType = authStore.userType;

        if (isLoggedIn) {
            // User is logged in
            if (requiresAdmin && userType !== "admin") {
                return next(`/error`); // If an admin route is accessed by non-admin, redirect to error page
            }

            // Redirect logged-in users to /dashboard if they visit the root route
            if (to.path === `/${locale}` || to.path === `/${locale}/`) {
                return next(`/${locale}/dashboard`);
            }
        } else if (requiresAuth) {
            return next(`/${locale}/`); // Redirect to login if the route requires authentication
        }

        return next(); // Proceed to the requested route
    } catch (error) {
        console.error("Error during navigation:", error);
        return next(`/${locale}/error`); // Redirect to an error page in case of unexpected issues
    }
});

app.config.productionTip = false;
app.config.devtools = true;

app.mount("#app");
