<template>
    <section class="testimonial-progress">
        <h1>"{{ $t("GO Platform has created a consistent and unbiased way to Measure our workforce") }}."</h1>
        <h3>{{ $t("Fernando Lucini, Global Data Science Lead, Accenture Applied Intelligence") }}</h3>
        <h4>{{ $t("On average, enterprise employees that use GO PLATFORM see:") }}</h4>
        <div class="progress-box">
            <div class="progress">
                <h1>-73%</h1>
                <h3>{{ $t("Turnover") }}</h3>
            </div>
            <div class="progress">
                <h1>8.25K</h1>
                <h3>{{ $t("Faster skill growth") }}</h3>
            </div>
            <div class="progress">
                <h1>+98%</h1>
                <h3>{{ $t("Position fit") }}</h3>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TestimonialProgress",
};
</script>

<style scoped>
.testimonial-progress {
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
}

.testimonial-progress h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.testimonial-progress h3 {
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    margin: 20px 0;
}

.testimonial-progress h4 {
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.progress-box {
    display: flex;
    justify-content: center;
    gap: 14.5em;
    flex-direction: row;
}

.progress h1 {
    font-size: 3.5em;
    font-weight: 700;
}

.progress h3 {
    font-size: 1.5em;
    font-weight: 500;
}

@media (max-width: 1023px) {
    .testimonial-progress {
        padding: 40px 20px;
    }

    .testimonial-progress h1 {
        font-size: 16px;
    }

    .testimonial-progress h3 {
        font-size: 12px;
    }

    .testimonial-progress h4 {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .progress-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .progress {
        max-width: 100%;
    }

    .progress h1 {
        font-size: 28px;
    }

    .progress h3 {
        font-size: 18px;
    }
}
</style>
