<template>
    <div class="bg-[#F1F6F4] mt-[200px] w-full m-7 p-7">
        <!-- Dashboard Header -->
        <div class="mb-6 flex flex-row justify-between gap-4">
            <h1 class="text-l font-bold decoration-skip-ink-none font-inter p-5">Showing {{ candidates.length }} Candidates</h1>

            <div class="flex flex-row items-center justify-between gap-3">
                <select v-model="selectedRank" class="border rounded px-4 py-2 text-black w-full sm:w-auto font-bold decoration-skip-ink-none font-inter">
                    <option class="font-bold decoration-skip-ink-none font-inter" value="Best Ranked">Best Ranked</option>
                    <option class="font-bold decoration-skip-ink-none font-inter" value="Most Recent">Most Recent</option>
                </select>

                <select v-model="selectedStatus" class="border rounded px-4 py-2 text-black w-full sm:w-auto font-bold decoration-skip-ink-none font-inter">
                    <option class="font-bold decoration-skip-ink-none font-inter" value="Passed">Passed</option>
                    <option class="font-bold decoration-skip-ink-none font-inter" value="Not Passed">Not Passed</option>
                </select>
            </div>
        </div>

        <!-- Candidate Cards -->
        <div class="flex flex-col justify-between gap-4">
            <CandidateCard
                v-for="candidate in filteredCandidates"
                :key="candidate.id"
                :name="candidate.name"
                :attempts="candidate.attempts"
                :daysOpen="candidate.daysOpen"
                :location="candidate.location"
                :rating="candidate.rating"
                :reviews="candidate.reviews"
                :status="candidate.status"
            />
        </div>
    </div>
</template>

<script>
import CandidateCard from "../components/CandidateVedCard.vue";

export default {
    components: {
        CandidateCard,
    },
    data() {
        return {
            candidates: [
                {
                    id: 1,
                    name: "Riad Mahrez",
                    attempts: 2,
                    daysOpen: 2,
                    location: "Madrid, Spain",
                    rating: 2.3,
                    reviews: 11,
                    status: ["Ready", "Pending", "Expired"],
                },
                {
                    id: 2,
                    name: "John Doe",
                    attempts: 3,
                    daysOpen: 1,
                    location: "Berlin, Germany",
                    rating: 3.8,
                    reviews: 20,
                    status: ["Pending", "Expired"],
                },

                {
                    id: 3,
                    name: "John Doe",
                    attempts: 3,
                    daysOpen: 1,
                    location: "Berlin, Germany",
                    rating: 3.8,
                    reviews: 20,
                    status: ["Expired"],
                },

                {
                    id: 4,
                    name: "John Doe",
                    attempts: 3,
                    daysOpen: 1,
                    location: "Berlin, Germany",
                    rating: 3.8,
                    reviews: 20,
                    status: ["Ready", "Pending", "Expired"],
                },
                // Add more candidates as needed
            ],
            selectedRank: "Best Ranked",
            selectedStatus: "Passed",
        };
    },
    computed: {
        filteredCandidates() {
            // Example filtering logic based on selectedRank and selectedStatus
            return this.candidates.filter((candidate) => {
                if (this.selectedStatus === "Passed" && candidate.rating < 1) {
                    return false;
                }
                if (this.selectedStatus === "Not Passed" && candidate.rating >= 3) {
                    return false;
                }
                return true;
            });
        },
    },
};
</script>

<style>
@import "tailwindcss/tailwind.css";
</style>
