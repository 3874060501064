<template>
    <section class="scroller flex flex-col lg:flex-row justify-start lg:justify-between items-center pt-8 lg:pt-0 px-5 lg:px-12 w-full lg:h-[800px] lg:relative pb-12 lg:pb-0 overflow-hidden">
        <div class="wave-bg -z-50 hidden lg:block absolute -top-72 -left-52 -rotate-[35deg] bg-gray-100 w-[80rem] h-[50rem]"></div>
        <div class="w-full lg:w-1/2 flex flex-col items-center justify-start gap-5 lg:pl-10">
            <h1 class="text-slate-700 font-bold text-6xl w-full text-center lg:text-left leading-normal">
                {{ $t("Discover") }}
                <!-- <br class="md:hidden"> -->
                <span class="relative overflow-visible whitespace-nowrap">
                    <div class="rotate-[3deg] bg-[#FEC500] absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                    {{ $t("The Potential") }}
                </span>
                {{ $t("Of Every") }}
                <!-- <br class="md:hidden"> --><span class="relative overflow-visible whitespace-nowrap">
                    <div class="-rotate-[6deg] bg-[#D3A8FF] absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                    Talent.
                </span>
            </h1>
            <p class="font-light text-lg text-slate-500 text-justify lg:text-left w-4/5 lg:w-full lg:mx-5 lg:my-6">
                {{ $t("GO PLATFORM uses Technology to accurately measure competencies, providing insights into who possesses the necessary skills and who is on the path to acquiring them.") }}
            </p>
            <!-- <div class="w-full bg-white rounded shadow-[0_0_12px_3px_rgba(0,0,0,5%)] flex lg:flex-row flex-col justify-between lg:mr-auto items-center h-fit p-6 gap-3">
                <div class="flex flex-row justify-between items-center relative w-full lg:w-3/4">
                    <label for="registerEmail" class="absolute text-sm font-bold text-slate-500 -top-2 left-0">Register with email adress</label>
                    <input type="email" name="registerEmail" id="registerEmail" class="border-b border-gray-300 pl-0 w-full h-12 outline-none pb-1 pt-3 text-base font-bold text-slate-500" />
                </div>
            </div> -->
            <div class="flex justify-start w-full">
                <router-link
                    to="/register"
                    class="hero-button w-full lg:w-1/4 px-12 h-12 bg-[#2196f3] shadow hover:opacity-[0.8] rounded text-base text-center flex justify-center whitespace-nowrap text-[18px] items-center text-white font-bold relative overflow-hidden"
                    >{{ $t("Start for free") }}</router-link
                >
            </div>
            <div class="flex flex-row justify-around lg:justify-start items-center gap-4 w-full mt-8">
                <span class="font-light text-left text-base text-slate-700"><font-awesome-icon :icon="['fas', 'circle-check']" class="text-[#5D5FEF] mr-1" />{{ $t("Free forever") }} </span>
                <span class="font-light text-left text-base text-slate-700"><font-awesome-icon :icon="['fas', 'circle-check']" class="text-[#5D5FEF] mr-1" />{{ $t("Great service") }} </span>
            </div>
        </div>
        <div ref="hero" class="hidden w-1/2 px-6 lg:flex justify-center items-center relative left-6 py-10 -rotate-[35deg] -top-16 overflow-y-hidden shadow-2xl h-[600px] bg-none rounded">
            <HeroIllustration />
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeroIllustration from "./HeroIllustration.vue";
export default {
    name: "HeroSection",
    components: { HeroIllustration },
    mounted() {
        gsap.from(this.$refs.hero, {
            duration: 1.5,
            opacity: 0,
            x: 300,
            animation: "bounce",
            yoyo: true,
            scrollTrigger: {
                start: "top center",
                trigger: this.$refs.hero,
            },
        });
    },
};
</script>

<style lang="scss" scoped>
.wave-bg {
    --mask: radial-gradient(12.83rem at 50% calc(100% - 17.6rem), #000 99%, #0000 101%) calc(50% - 22rem) 0/44rem 100%,
        radial-gradient(12.83rem at 50% calc(100% + 6.6rem), #0000 99%, #000 101%) 50% calc(100% - 11rem) / 44rem 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.scroller::-webkit-scrollbar {
    display: none;
}
</style>
