<template>
    <section class="expert-validation">
        <div class="text-content">
            <h1>Created and validated by experts</h1>
            <p>
                Go's IP (Intellectual Property) development specialists work with subject matter experts to design our tests and write valid questions. We employ rigorous selection processes to find
                the right experts. Working closely with our IP development specialists, subject matter experts are trained in the science and art of test development, and our team provides
                developmental edits and guidance to ensure our tests are valid, reliable, fair, and inclusive. Everything is reviewed by a professional copy-editor and an additional, independent
                subject matter expert for accuracy and technical correctness.
            </p>
        </div>
        <span> <img :src="TalentMap" class="talent-map" /> </span>
    </section>
</template>

<script>
import TalentMap from "@/assets/Images/talent-map.png";
export default {
    data() {
        return {
            TalentMap: TalentMap,
        };
    },
};
</script>

<style scoped>
.expert-validation {
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.text-content {
    width: 50%;
    text-align: left;
}

.text-content h1 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}

.text-content p {
    color: #ffffff;
    font-size: 16px;
}

.talent-map {
    width: 450px;
    height: auto;
    /* Maintains aspect ratio */
    border-radius: 5px;
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .expert-validation {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-content {
        width: 100%;
        margin-bottom: 20px;
    }

    .text-content h1 {
        font-size: 24px;
    }

    .text-content p {
        font-size: 12px;
    }

    .talent-map {
        max-width: 100%;
    }
}
</style>
