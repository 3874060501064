<template>
    <div class="bg-white rounded-lg shadow-md p-4 w-full h-52 flex flex-col justify-between transition-transform transform hover:scale-105">
        <h2 class="text-lg font-semibold text-gray-800">{{ assessement.name }}</h2>
        <p class="text-sm text-gray-600 line-clamp-3">{{ getFirstPhrase(assessement.description_test) }}...</p>
        <div class="flex items-center text-gray-500 text-sm mt-2">
            <font-awesome-icon :icon="['far', 'clock']" class="mr-2" />
            {{ assessement?.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessement?.questions_nbr * 35) / 60) }} minutes
        </div>

        <div class="flex justify-between mt-auto">
            <button @click="navigateToPreview" class="text-blue-500 border border-blue-500 rounded-lg px-4 py-2 text-sm flex items-center justify-center transition hover:bg-blue-50">
                <font-awesome-icon :icon="['far', 'eye']" class="mr-2" />
                {{ $t("Preview") }}
            </button>
            <button @click="$emit('openPreview')" class="bg-blue-500 text-white rounded-lg px-4 py-2 text-sm flex items-center justify-center transition hover:bg-blue-600">
                {{ $t("Details") }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "LibraryAssessCard",
    props: ["assessement", "addAssessment"],
    data() {
        return {
            imagePath: "",
        };
    },
    methods: {
        getFirstPhrase(description) {
            // Split the string into an array of words
            let words = description?.split(/\s+/);

            // Extract the first 200 words
            let first200Words = words?.slice(0, 30)?.join(" ");
            return first200Words;
        },
        navigateToPreview() {
            const url = this.$router.resolve({
                path: "/previewAssessment",
                query: { id: this.assessement._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
    },
};
</script>
