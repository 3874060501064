<template>
    <!-- <NavBar /> -->
    <Hero />
    <LogoCarousel />
    <TestimonialProgress />
    <SkillsAssessment />
    <AboutUs />
    <ToolsComponent />
    <CallToAction />
</template>

<script>
//   import NavBar from "@/components/newLandingPage/NavBar.vue"
import Hero from "@/components/newLandingPage/Hero.vue";
import LogoCarousel from "@/components/newLandingPage/LogoCarousel.vue";
import TestimonialProgress from "@/components/newLandingPage/TestimonialProgress.vue";
import SkillsAssessment from "@/components/newLandingPage/SkillsAssessment.vue";
import ToolsComponent from "@/components/newLandingPage/ToolsComponent.vue";
import AboutUs from "@/components/newLandingPage/AboutUs.vue";
import CallToAction from "@/components/newLandingPage/CallToAction.vue";

export default {
    name: "App",
    components: {
        //   NavBar,
        Hero,
        LogoCarousel,
        TestimonialProgress,
        SkillsAssessment,
        AboutUs,
        ToolsComponent,
        CallToAction,
    },
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.1/css/all.min.css");

section {
    padding: 50px 100px;
}

.main-button {
    position: relative;
    display: inline-block;
    padding: 8px 28px;
    color: #ffffff;
    background-color: #00aef0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    overflow: hidden;
}

.main-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 60px;
    height: 100%;
    background-color: #ffffff34;
    animation: moveRightWithShape 2s infinite;
    clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
}

@keyframes moveRightWithShape {
    to {
        left: 100%;
    }
}
</style>
