<template>
    <section class="flex flex-col justify-between items-center gap-5 py-10">
        <span class="font-light text-base text-center w-fit px-5 py-3 rounded-full bg-[#EFECFF] text-[#2196f3]">How it works</span>
        <h1 class="text-3xl lg:text-6xl font-bold text-center lg:text-left text-slate-700 w-full lg:px-20">1. Create high quality assessments, fast.</h1>
        <h2 class="text-base lg:text-lg font-normal text-slate-700 text-center lg:text-left w-full lg:px-20">Building assessments is a breeze with GO PLATFORM. Get Started with these simple Steps</h2>
        <div class="flex flex-col justify-center items-center w-full px-8 lg:px-20 lg:gap-[5rem] sm:gap-[2rem]">
            <!--  <div class="grid grid-cols-1 gap-6 w-full sm:grid-cols-2 lg:grid-cols-4 lg:gap-5 lg:mt-20 buttons-grid">
                <button class="w-full h-[5rem] p-5 rounded-md text-sm font-bold text-center" :class="step === 1 ? 'text-white bg-[#2196f3]' : 'text-slate-700 bg-slate-100'" @click="step = 1">
                    Set up your project
                </button>
                <button class="w-full h-[5rem] p-5 rounded-md text-sm font-bold text-center" :class="step === 2 ? 'text-white bg-[#2196f3]' : 'text-slate-700 bg-slate-100'" @click="step = 2">
                    Select the tests that work for you
                </button>
                <button class="w-full h-[5rem] p-5 rounded-md text-sm font-bold text-center" :class="step === 3 ? 'text-white bg-[#2196f3]' : 'text-slate-700 bg-slate-100'" @click="step = 3">
                    Select the additional questions
                </button>
                <button class="w-full h-[5rem] p-5 rounded-md text-sm font-bold text-center" :class="step === 4 ? 'text-white bg-[#2196f3]' : 'text-slate-700 bg-slate-100'" @click="step = 4">
                    Review and Configure
                </button>
            </div>-->
            <div class="w-full mt-[5rem] flex flex-col lg:flex-row gap-12">
                <div class="w-1/2 flex flex-col justify-center items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Set up your project</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Organize and optimize your project by choosing a distinct name and job role. Creating a unique and detailed name allows you to easily keep track of projects. Selecting a job
                        role lets us recommend the most relevant tests.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
                <img loading="lazy" decoding="async" src="../../assets/Images/project_setup.png" alt="" class="w-full h-auto border-2 rounded-md border-[#D9D9D9] lg:w-1/2 aspect-auto" />
            </div>
            <div class="w-full flex flex-col lg:flex-row gap-4 lg:gap-12">
                <img loading="lazy" decoding="async" src="../../assets/Images/assessments_setup.png" alt="" class="w-full h-auto border-2 rounded border-[#D9D9D9] lg:w-1/2 aspect-auto" />
                <div class="flex flex-col justify-center items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Select the tests that work for you</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Browse through our extensive library of assessments tailored to each job role. From technical proficiency tests to psychometric evaluations, we offer a variety of assessment
                        types to meet your hiring needs. Filter by skill set, difficulty level, or industry relevance to find the perfect test for your candidates. Each test is designed to provide
                        valuable insights into candidates' capabilities, helping you make informed hiring decisions.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
            </div>
            <div class="w-full flex flex-col-reverse lg:flex-row-reverse gap-4 lg:gap-12">
                <img loading="lazy" decoding="async" src="../../assets/Images/custom_questions_setup.png" alt="" class="w-full h-auto border-2 rounded border-[#D9D9D9] lg:w-1/2 aspect-auto" />
                <div class="flex flex-col justify-center items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Select the additional questions</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Personalize your assessments by adding custom questions tailored to your specific requirements. Whether you need to evaluate technical skills, cultural fit, or problem-solving
                        abilities, custom questions enable you to get deeper insights into your candidates. Craft questions that reflect the unique challenges and opportunities of your organization to
                        find the perfect match.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
            </div>
            <div class="w-full flex flex-col lg:flex-row gap-4 lg:gap-12">
                <img loading="lazy" decoding="async" src="../../assets/Images/review_setup.png" alt="" class="w-full h-auto border-2 rounded border-[#D9D9D9] lg:w-1/2 aspect-auto" />
                <div class="flex flex-col justify-center items-center">
                    <h1 class="text-2xl lg:text-4xl font-semibold text-left w-full text-slate-700">Review and Configure</h1>
                    <p class="text-left text-base font-light text-slate-500 my-6">
                        Review your selected assessments and custom questions to ensure they align with your hiring goals. You can delete any test or question to better fit your needs. Additionally,
                        you can change the order in which the assessments and questions are presented to candidates. Once you're satisfied, finalize your project to make it ready for candidate
                        evaluation. This step ensures your assessments are comprehensive and tailored to your specific requirements.
                    </p>
                    <a class="w-fit p-3 rounded-md text-white bg-[#2196f3] mr-auto px-5 font-light" href="/">Get Started</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CreateSection",
    data() {
        return {
            step: 1,
        };
    },
};
</script>

<style lang="scss" scoped>
.buttons-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

button {
    flex: 1;
    min-width: 0;
}

img {
    @media (max-width: 1200px) {
        display: none;
    }
}
</style>
