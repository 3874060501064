<template>
    <footer class="footer">
        <div class="footer-content">
            <img :src="logo" alt="GO Platform" />
            <h4>Unlock the full potential of your workforce.</h4>
            <div class="footer-icons">
                <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                <a href="#"><i class="fa-brands fa-square-facebook"></i></a>
                <a href="#"><i class="fa-brands fa-square-instagram"></i></a>
            </div>
        </div>
        <div class="footer-content">
            <h3>Company</h3>
            <div class="footer-icons">
                <li><a href="#">Company</a></li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">Talents</a></li>
            </div>
        </div>
        <div class="footer-content">
            <h3>Services</h3>
            <div class="footer-icons">
                <li><a href="#">Company</a></li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">Talents</a></li>
            </div>
        </div>
        <div class="footer-content">
            <h3>Resources</h3>
            <div class="footer-icons">
                <li><a href="#">Company</a></li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">Talents</a></li>
            </div>
        </div>
    </footer>
</template>

<script>
import logo from "@/assets/logo.svg";
export default {
    name: "FooterComponent",
    data() {
        return { logo: logo };
    },
};
</script>

<style scoped>
footer {
    background-color: #f5f8ff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 150px;
}

.footer-content img {
    width: 150px;
}

.footer-content h4 {
    font-size: 14px;
    text-transform: capitalize;
    color: #334155;
    font-weight: 400;
    margin: 20px 0;
}

.footer-icons i {
    color: #00aef0;
    font-size: 32px;
    padding: 0 12px 0 0;
}

.footer-content h3 {
    font-size: 14px;
    font-weight: 600;
    color: #00aef0;
    margin-bottom: 20px;
}

.footer-icons li {
    color: #000000;
    font-size: 14px;
    list-style: none;
    margin-bottom: 16px;
}

.footer-icons li a {
    text-decoration: none;
    color: #334155;
}

.footer-icons li a:hover {
    color: #2196f3;
}

@media (max-width: 1023px) {
    footer {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
    }

    .footer-content {
        text-align: center;
        margin-bottom: 20px;
    }

    .footer-content img {
        width: 120px;
    }

    .footer-content h4,
    .footer-content h3 {
        font-size: 12px;
    }

    .footer-icons i {
        font-size: 28px;
    }

    .footer-icons li {
        font-size: 12px;
    }
}
</style>
