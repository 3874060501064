import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        isLoggedIn: false,
        userType: null,
    }),
    actions: {
        async checkLoginStatus() {
            try {
                const response = await axios.get("https://server.go-platform.com/user/isLoggedIn", {
                    withCredentials: true,
                });
                const userType = response.data.isLogged;
                this.userType = userType;
                this.isLoggedIn = userType === "user" || userType === "admin";
            } catch (error) {
                console.error("Error while checking login status:", error);
                this.isLoggedIn = false;
                this.userType = null;
            }
        },

        logout() {
            this.isLoggedIn = false;
            this.userType = null;
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("userType");
        },

        login(userType) {
            this.isLoggedIn = true;
            this.userType = userType;
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userType", userType);
        },
    },
    persist: true, // Optional: persist the store state using Pinia’s plugin
});
