<template>
    <div class="rounded-md p-6 shadow-card bg-[#fff] w-full">
        <div class="score-head">
            <div>
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="" /> -->
                <h2 class="recentapp">{{ $t("Funnel") }}</h2>
            </div>
            <!-- <button class="viewbtn">{{ $t("View All") }} <img loading="lazy"  decoding="async" src="../assets/Images/icons/Vector.svg" alt="" /></button> -->
        </div>

        <div class="w-full h-[300px] flex justify-center">
            <!-- <span class="on-track">
                {{ $t("On Track") }}
            </span> -->

            <HistogramVue :histogramData="this.histogramData" :chartWidth="650" :chartHeight="350" :showLabel="true" class="hidden lg:flex" />
            <div class="w-full flex lg:hidden justify-center items-center text-center text-lg font-bold text-slate-700">For better visibility and insights use a laptop or desktop computer</div>
            <!-- <svg class="chart-svg"></svg> -->
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";
import HistogramVue from "../charts/Histogram.vue";
export default {
    name: "candidateFunnel",
    components: { HistogramVue },
    props: {
        invitations: Number,
        attempts: Number,
        candidatesPassed: Number,
        histogramData: Array,
    },
    data() {
        return {
            invitationsSent: this.invitations,
            candidatesPassedTest: this.candidatesPassed,
            candidatesAttempt: this.attempts,
            // histogramData: [],
            newHistogramData: [
                {
                    label: "Invited",
                    value: this.invitations,
                },
                {
                    label: "Attempted",
                    value: this.attempts,
                },
                {
                    label: "Passed",
                    value: this.candidatesPassed,
                },
            ],
        };
    },
    // watch: {
    //     // Watch for changes to the invitations prop
    //     invitations(newValue) {
    //         // Update the invitationsSent data property
    //         console.log({ newValue });
    //         this.invitationsSent = newValue;
    //         // Update the corresponding value in histogramData

    //         this.newHistogramData[0] = {
    //             label: "Invited",
    //             value: parseInt(newValue),
    //         };
    //     },
    //     // Watch for changes to the candidates prop
    //     attempts(newCandidates) {
    //         // Update the candidatesAttempt data property
    //         this.candidatesAttempt = newCandidates;
    //         this.newHistogramData[1] = {
    //             label: "Attempted",
    //             value: parseInt(newCandidates),
    //         };
    //         // Update the corresponding value in histogramData
    //     },
    //     // Watch for changes to the candidatesPassed prop
    //     candidatesPassed(newCandidatesPassed) {
    //         // Update the candidatesPassedTest data property
    //         this.candidatesPassedTest = newCandidatesPassed;
    //         this.newHistogramData[2] = {
    //             label: "Passed",
    //             value: parseInt(newCandidatesPassed),
    //         };
    //         console.log({ newCandidatesPassed });
    //         // Update the corresponding value in histogramData
    //     },
    // },
    mounted() {
        const containerWidth = document.querySelector(".chart-container")?.clientWidth;

        const containerHeight = 200; // Set the desired height for the chart

        const margin = { top: 20, right: 20, bottom: 40, left: 50 };
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;

        const svg = d3.select(".chart-svg").attr("width", containerWidth).attr("height", containerHeight);

        const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        const data = [
            { category: "Invited", value: 4 },
            { category: "Email Delivered", value: 3 },
            { category: "Emails Opened", value: 5 },
            { category: "Links Clicked", value: 2 },
            { category: "Attempted", value: 3.5 },
            { category: "Passed", value: 4 },
        ];

        const x = d3
            .scaleBand()
            .domain(data.map((d) => d.category))
            .range([0, width])
            .padding(0.3);

        const y = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.value)])
            .range([height, 0]);

        g.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", (d) => x(d.category))
            .attr("y", (d) => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", (d) => height - y(d.value))
            .attr("fill", "#2196f3")
            .attr("rx", 8) // Add border radius to the bars
            .attr("ry", 8);

        // Add x-axis
        g.append("g")
            .attr("transform", "translate(0," + (height + 20) + ")")
            .call(d3.axisBottom(x))
            .call((g) => g.select(".domain").remove())
            .call((g) => g.selectAll(".tick line").remove()); // remove ticks;

        // Add y-axis
        g.append("g")
            .call(d3.axisLeft(y).ticks(5))
            .call((g) => g.select(".domain").remove())
            .call((g) => g.selectAll(".tick line").remove()); // remove ticks;

        // Style the axes
        g.selectAll(".tick line").attr("stroke", "#ccc");

        g.selectAll("text").attr("fill", "#929EAE");

        // Append a line element for the dashed line
        const maxValue = d3.max(data, (d) => d.value);
        g.append("line").attr("class", "highlight-line").attr("x1", 0).attr("x2", width).attr("y1", y(maxValue)).attr("y2", y(maxValue));

        // Style the dashed line
        g.select(".highlight-line").attr("stroke", "#2196f3").attr("stroke-dasharray", "5,5"); // Set the dash pattern (5px dash, 5px gap)
    },
};
</script>

<style scoped>
.candidate {
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
}
.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}
.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}
.score-head {
    display: flex;
    justify-content: space-between;
    /* margin-top: 2%; */
}
.score-head > div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.score-head > div > span {
    color: #05cd99;
}
.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}
.score-head > select:focus {
    border: none;
    outline: none;
}
.score-head > img {
    justify-self: end;
}
.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
}
.viewbtn:hover {
    text-decoration: underline #2196f3;
}
.on-track {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    margin: 2% 0;
    color: #05cd99;
    gap: 8px;
    display: flex;
    justify-content: center;
}
.chart-container {
    margin-top: 10%;
    width: 100%;
    height: 300px; /* Set the desired height for the chart */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 3% 0; */
}

.chart-svg {
    width: 100%;
    height: 100%;
}

.recentapp {
    background: linear-gradient(to right, #00aef0, #c084fc);
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text color transparent */
    background-clip: text; /* Standard property for background clipping */
    text-fill-color: transparent; /* Standard property for text fill color */

    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}
</style>
