export default {
  "message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا بك في i18n !!"])};fn.source="مرحبًا بك في i18n !!";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])};fn.source="تسجيل الدخول";return fn;})(),
  "Details": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])};fn.source="تفاصيل";return fn;})(),
  "believe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نؤمن بالوصول المتساوي للمعلومات للجميع"])};fn.source="نؤمن بالوصول المتساوي للمعلومات للجميع";return fn;})(),
  "Free with AI": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني مع الذكاء الاصطناعي"])};fn.source="مجاني مع الذكاء الاصطناعي";return fn;})(),
  "Home": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])};fn.source="الرئيسية";return fn;})(),
  "Create a new project for assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مشروع جديد للتقييم"])};fn.source="إنشاء مشروع جديد للتقييم";return fn;})(),
  "Talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواهب"])};fn.source="المواهب";return fn;})(),
  "Show all projects": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض جميع المشاريع"])};fn.source="عرض جميع المشاريع";return fn;})(),
  "Show active projects": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المشاريع النشطة"])};fn.source="عرض المشاريع النشطة";return fn;})(),
  "Show archived projects": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المشاريع المؤرشفة"])};fn.source="عرض المشاريع المؤرشفة";return fn;})(),
  "Edit, Duplicate and Delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل، تكرار وحذف"])};fn.source="تعديل، تكرار وحذف";return fn;})(),
  "Send Email Invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال دعوة عبر البريد الإلكتروني"])};fn.source="إرسال دعوة عبر البريد الإلكتروني";return fn;})(),
  "Pricing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسعار"])};fn.source="الأسعار";return fn;})(),
  "Blogs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدونات"])};fn.source="المدونات";return fn;})(),
  "Contact": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل"])};fn.source="تواصل";return fn;})(),
  "Join Our Newsletter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك في نشرتنا الإخبارية"])};fn.source="اشترك في نشرتنا الإخبارية";return fn;})(),
  "GO Platform All rights reserved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لمنصة GO"])};fn.source="جميع الحقوق محفوظة لمنصة GO";return fn;})(),
  "Company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة"])};fn.source="الشركة";return fn;})(),
  "Unlock the full potential of your workforce": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استفد من كامل إمكانات قوتك العاملة"])};fn.source="استفد من كامل إمكانات قوتك العاملة";return fn;})(),
  "Will send you weekly updates for your better Team management.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنرسل لك تحديثات أسبوعية لتحسين إدارة فريقك."])};fn.source="سنرسل لك تحديثات أسبوعية لتحسين إدارة فريقك.";return fn;})(),
  "About us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])};fn.source="معلومات عنا";return fn;})(),
  "UNDERSTAND. DEVELOP. MOBILIZE.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افهم. طور. حرك."])};fn.source="افهم. طور. حرك.";return fn;})(),
  "Contact us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])};fn.source="اتصل بنا";return fn;})(),
  "Learn how GO PLATFORM can power digital transformation and produce measurable results across your enterprise.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على كيفية تمكين منصة GO من التحول الرقمي وتحقيق نتائج قابلة للقياس عبر مؤسستك."])};fn.source="تعرف على كيفية تمكين منصة GO من التحول الرقمي وتحقيق نتائج قابلة للقياس عبر مؤسستك.";return fn;})(),
  "For candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمرشحين"])};fn.source="للمرشحين";return fn;})(),
  "Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف"])};fn.source="الوظائف";return fn;})(),
  "FREE FOREVER": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني للأبد"])};fn.source="مجاني للأبد";return fn;})(),
  "SKILLS ASSESSMENT APP": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق تقييم المهارات"])};fn.source="تطبيق تقييم المهارات";return fn;})(),
  "Services": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])};fn.source="الخدمات";return fn;})(),
  "All The Tools That You": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأدوات التي تحتاجها"])};fn.source="كل الأدوات التي تحتاجها";return fn;})(),
  "Need.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحاجة إليها."])};fn.source="بحاجة إليها.";return fn;})(),
  "All the tools you can imagine": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأدوات التي يمكنك تخيلها"])};fn.source="كل الأدوات التي يمكنك تخيلها";return fn;})(),
  "Compare talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قارن بين المواهب"])};fn.source="قارن بين المواهب";return fn;})(),
  "Check the tools": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من الأدوات"])};fn.source="تحقق من الأدوات";return fn;})(),
  "Skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])};fn.source="المهارات";return fn;})(),
  "Get started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الآن"])};fn.source="ابدأ الآن";return fn;})(),
  "Understand your talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افهم موهبتك"])};fn.source="افهم موهبتك";return fn;})(),
  "Party we years to order allow asked of. We so opinion friends me message as delight.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قمنا على مدى السنوات بتمكين التنظيم حسب الطلب. رأي الأصدقاء ورسائلهم دائمًا مصدر سرور لنا."])};fn.source="قمنا على مدى السنوات بتمكين التنظيم حسب الطلب. رأي الأصدقاء ورسائلهم دائمًا مصدر سرور لنا.";return fn;})(),
  "We provide actionable skills data that inform your talent strategies across hiring, upskilling, and mentorship.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفر بيانات مهارات قابلة للتنفيذ لإرشاد استراتيجيات المواهب الخاصة بك في التوظيف، التطوير، والإرشاد."])};fn.source="نوفر بيانات مهارات قابلة للتنفيذ لإرشاد استراتيجيات المواهب الخاصة بك في التوظيف، التطوير، والإرشاد.";return fn;})(),
  "Assessment Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة التقييم"])};fn.source="منصة التقييم";return fn;})(),
  "Develop their Skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طور مهاراتهم"])};fn.source="طور مهاراتهم";return fn;})(),
  "His defective nor convinced residence own. Connection has put impossible own apartments boisterous.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان إقامته المعيب لم يقنعه. الاتصال جعل من المستحيل امتلاك شقق صاخبة."])};fn.source="مكان إقامته المعيب لم يقنعه. الاتصال جعل من المستحيل امتلاك شقق صاخبة.";return fn;})(),
  "DATA THAT POWERS TRANSFORMATION": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات التي تدعم التحول"])};fn.source="البيانات التي تدعم التحول";return fn;})(),
  "Mobilize your workforce": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرك قوتك العاملة"])};fn.source="حرك قوتك العاملة";return fn;})(),
  "From they fine john he give of rich he. They age and draw mrs like. Improving end distrusts may instantly.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهم جون العظيم الذي يقدم الغنى. مع تقدم العمر، يرسمن الإعجاب. تحسين النهايات قد يزيل الشكوك فوراً."])};fn.source="منهم جون العظيم الذي يقدم الغنى. مع تقدم العمر، يرسمن الإعجاب. تحسين النهايات قد يزيل الشكوك فوراً.";return fn;})(),
  "Our assessments are inspired by the following companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستند تقييماتنا إلى الشركات التالية وأكثر"])};fn.source="تستند تقييماتنا إلى الشركات التالية وأكثر";return fn;})(),
  "Essential Skills Template": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قالب المهارات الأساسية"])};fn.source="قالب المهارات الأساسية";return fn;})(),
  "Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])};fn.source="الفئة";return fn;})(),
  "Essential Skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات الأساسية"])};fn.source="المهارات الأساسية";return fn;})(),
  "Total Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الوقت"])};fn.source="إجمالي الوقت";return fn;})(),
  "Upload your photo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع صورتك"])};fn.source="رفع صورتك";return fn;})(),
  "Included Tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات المضمنة"])};fn.source="الاختبارات المضمنة";return fn;})(),
  "About": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])};fn.source="حول";return fn;})(),
  "You are": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت"])};fn.source="أنت";return fn;})(),
  "Update Profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث الملف الشخصي"])};fn.source="تحديث الملف الشخصي";return fn;})(),
  "Reset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])};fn.source="إعادة ضبط";return fn;})(),
  "Title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])};fn.source="العنوان";return fn;})(),
  "Invited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدعو"])};fn.source="مدعو";return fn;})(),
  "Rating": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])};fn.source="التقييم";return fn;})(),
  "Clean": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظيف"])};fn.source="نظيف";return fn;})(),
  "Device used": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهاز المستخدم"])};fn.source="الجهاز المستخدم";return fn;})(),
  "Desktop": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سطح المكتب"])};fn.source="سطح المكتب";return fn;})(),
  "Location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])};fn.source="الموقع";return fn;})(),
  "Mouse always in assessment window?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل كان المؤشر دائمًا في نافذة التقييم؟"])};fn.source="هل كان المؤشر دائمًا في نافذة التقييم؟";return fn;})(),
  "Full-screen mode always active?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل كان وضع الشاشة الكاملة دائمًا نشطًا؟"])};fn.source="هل كان وضع الشاشة الكاملة دائمًا نشطًا؟";return fn;})(),
  "Stayed within assessment window?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بقي داخل نافذة التقييم؟"])};fn.source="هل بقي داخل نافذة التقييم؟";return fn;})(),
  "Number of times window has changed :": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مرات تغيير النافذة :"])};fn.source="عدد مرات تغيير النافذة :";return fn;})(),
  "Last Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])};fn.source="الاسم الأخير";return fn;})(),
  "Phone Number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])};fn.source="رقم الهاتف";return fn;})(),
  "No Photos have been captured": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم التقاط أي صور"])};fn.source="لم يتم التقاط أي صور";return fn;})(),
  "Go Back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة للخلف"])};fn.source="العودة للخلف";return fn;})(),
  "Description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])};fn.source="الوصف";return fn;})(),
  "Answer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة"])};fn.source="الإجابة";return fn;})(),
  "Remove Question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إزالة السؤال"])};fn.source="إزالة السؤال";return fn;})(),
  "Add Question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة سؤال"])};fn.source="إضافة سؤال";return fn;})(),
  "Interpretations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفسيرات"])};fn.source="التفسيرات";return fn;})(),
  "Range": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النطاق"])};fn.source="النطاق";return fn;})(),
  "Remove Interpretation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إزالة التفسير"])};fn.source="إزالة التفسير";return fn;})(),
  "Add Interpretation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تفسير"])};fn.source="إضافة تفسير";return fn;})(),
  "Uploading Assessment... Please wait.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري رفع التقييم... يرجى الانتظار."])};fn.source="جاري رفع التقييم... يرجى الانتظار.";return fn;})(),
  "This candidate has been cleared of any cheating activity. No further action is needed.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تبرئة هذا المرشح من أي نشاط غش. لا يلزم اتخاذ أي إجراء إضافي."])};fn.source="تم تبرئة هذا المرشح من أي نشاط غش. لا يلزم اتخاذ أي إجراء إضافي.";return fn;})(),
  "Verify": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])};fn.source="تحقق";return fn;})(),
  "This candidate has potentially cheated based on our AI detection. Please verify the data before proceeding.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يكون هذا المرشح قد غش بناءً على اكتشاف الذكاء الاصطناعي لدينا. يرجى التحقق من البيانات قبل المتابعة."])};fn.source="قد يكون هذا المرشح قد غش بناءً على اكتشاف الذكاء الاصطناعي لدينا. يرجى التحقق من البيانات قبل المتابعة.";return fn;})(),
  "This candidate has been marked as a cheater after verification. Please take appropriate action.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم وضع علامة على هذا المرشح كغشاش بعد التحقق. يرجى اتخاذ الإجراء المناسب."])};fn.source="تم وضع علامة على هذا المرشح كغشاش بعد التحقق. يرجى اتخاذ الإجراء المناسب.";return fn;})(),
  "Potential Cheater": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غشاش محتمل"])};fn.source="غشاش محتمل";return fn;})(),
  "Cheater": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غشاش"])};fn.source="غشاش";return fn;})(),
  "Anti-Cheating monitor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراقبة مكافحة الغش"])};fn.source="مراقبة مكافحة الغش";return fn;})(),
  "Scoring method:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة التقييم:"])};fn.source="طريقة التقييم:";return fn;})(),
  "Completed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمل"])};fn.source="مكتمل";return fn;})(),
  "We can detect if the mouse has always been on the test window. Candidates that have two screens could otherwise still have another window open to browse the internet.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكننا اكتشاف ما إذا كان المؤشر دائمًا في نافذة الاختبار. المرشحون الذين لديهم شاشتان قد يكون لديهم نافذة أخرى مفتوحة لتصفح الإنترنت."])};fn.source="يمكننا اكتشاف ما إذا كان المؤشر دائمًا في نافذة الاختبار. المرشحون الذين لديهم شاشتان قد يكون لديهم نافذة أخرى مفتوحة لتصفح الإنترنت.";return fn;})(),
  "Mouse always in assessment window": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤشر دائمًا في نافذة التقييم"])};fn.source="المؤشر دائمًا في نافذة التقييم";return fn;})(),
  "For candidates that use a desktop or laptop, we also activate full-screen mode to ensure candidates don’t browse the internet to look up answers. While we cannot prevent that candidates deactivate full-screen mode, we can detect if they did. It indicates a potential violation.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالنسبة للمرشحين الذين يستخدمون سطح المكتب أو الكمبيوتر المحمول، نقوم أيضًا بتنشيط وضع الشاشة الكاملة لضمان عدم تصفح الإنترنت للبحث عن إجابات. على الرغم من أننا لا نستطيع منع المرشحين من إلغاء تنشيط وضع الشاشة الكاملة، إلا أنه يمكننا اكتشاف ما إذا فعلوا ذلك. وهذا يشير إلى انتهاك محتمل."])};fn.source="بالنسبة للمرشحين الذين يستخدمون سطح المكتب أو الكمبيوتر المحمول، نقوم أيضًا بتنشيط وضع الشاشة الكاملة لضمان عدم تصفح الإنترنت للبحث عن إجابات. على الرغم من أننا لا نستطيع منع المرشحين من إلغاء تنشيط وضع الشاشة الكاملة، إلا أنه يمكننا اكتشاف ما إذا فعلوا ذلك. وهذا يشير إلى انتهاك محتمل.";return fn;})(),
  "Full-screen mode always active": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الشاشة الكاملة دائمًا نشط"])};fn.source="وضع الشاشة الكاملة دائمًا نشط";return fn;})(),
  "When candidates start their assessment, we ask them to activate their webcam/camera. This allows us to capture images of your candidates every 30 seconds. This way you can see if the same (and only one) person has worked on the assessment.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما يبدأ المرشحون تقييمهم، نطلب منهم تنشيط كاميرا الويب الخاصة بهم. يتيح لنا ذلك التقاط صور للمرشحين كل 30 ثانية. بهذه الطريقة يمكنك التحقق من أن نفس الشخص (والشخص الوحيد) هو من عمل على التقييم."])};fn.source="عندما يبدأ المرشحون تقييمهم، نطلب منهم تنشيط كاميرا الويب الخاصة بهم. يتيح لنا ذلك التقاط صور للمرشحين كل 30 ثانية. بهذه الطريقة يمكنك التحقق من أن نفس الشخص (والشخص الوحيد) هو من عمل على التقييم.";return fn;})(),
  "Webcam/front camera enabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تنشيط كاميرا الويب/الكاميرا الأمامية"])};fn.source="تم تنشيط كاميرا الويب/الكاميرا الأمامية";return fn;})(),
  "Using the IP address, we check if candidates fill out the assessment only once (with a public link to the assessment, candidates could use multiple email addresses to take repeated attempts at the assessment).": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باستخدام عنوان IP، نتحقق مما إذا كان المرشحون يملأون التقييم مرة واحدة فقط (باستخدام رابط عام للتقييم، يمكن للمرشحين استخدام عناوين بريد إلكتروني متعددة لمحاولة التقييم عدة مرات)."])};fn.source="باستخدام عنوان IP، نتحقق مما إذا كان المرشحون يملأون التقييم مرة واحدة فقط (باستخدام رابط عام للتقييم، يمكن للمرشحين استخدام عناوين بريد إلكتروني متعددة لمحاولة التقييم عدة مرات).";return fn;})(),
  "Filled out only once from IP address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت تعبئة التقييم مرة واحدة فقط من عنوان IP"])};fn.source="تمت تعبئة التقييم مرة واحدة فقط من عنوان IP";return fn;})(),
  "We register the candidate’s type of device used for the assessment, as well as the geographic location, based on their IP address.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسجل نوع الجهاز الذي يستخدمه المرشح لإجراء التقييم، بالإضافة إلى الموقع الجغرافي بناءً على عنوان IP الخاص بهم."])};fn.source="نسجل نوع الجهاز الذي يستخدمه المرشح لإجراء التقييم، بالإضافة إلى الموقع الجغرافي بناءً على عنوان IP الخاص بهم.";return fn;})(),
  "Device and location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهاز والموقع"])};fn.source="الجهاز والموقع";return fn;})(),
  "Multiple choice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار من متعدد"])};fn.source="اختيار من متعدد";return fn;})(),
  "Select Question Type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع السؤال"])};fn.source="اختر نوع السؤال";return fn;})(),
  "Create a custom question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء سؤال مخصص"])};fn.source="إنشاء سؤال مخصص";return fn;})(),
  "Upgrade to the Starter plan to use this feature. You can learn more about Creating an assessment here.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية إلى خطة البداية لاستخدام هذه الميزة. يمكنك معرفة المزيد عن إنشاء تقييم هنا."])};fn.source="قم بالترقية إلى خطة البداية لاستخدام هذه الميزة. يمكنك معرفة المزيد عن إنشاء تقييم هنا.";return fn;})(),
  "Discover premium features": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف الميزات المميزة"])};fn.source="اكتشف الميزات المميزة";return fn;})(),
  "This assessment will help you hire an employee who can engage in problem solving and communicate well. This holistic assessment will also examine a candidate's time management skills and provide insight into their personality.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيساعدك هذا التقييم في توظيف موظف يمكنه التعامل مع حل المشكلات والتواصل بشكل جيد. كما سيقوم هذا التقييم الشامل بفحص مهارات إدارة الوقت لدى المرشح وتقديم رؤى عن شخصيته."])};fn.source="سيساعدك هذا التقييم في توظيف موظف يمكنه التعامل مع حل المشكلات والتواصل بشكل جيد. كما سيقوم هذا التقييم الشامل بفحص مهارات إدارة الوقت لدى المرشح وتقديم رؤى عن شخصيته.";return fn;})(),
  "Gain a competitive edge in talent acquisition with our advanced assessment tools. From evaluating aptitude and personality to analyzing skills, our platform equips you to make data-driven decisions. Uncover high-potential candidates and build exceptional teams!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على ميزة تنافسية في اكتساب المواهب باستخدام أدوات التقييم المتقدمة لدينا. من تقييم الكفاءة والشخصية إلى تحليل المهارات، تُمكّنك منصتنا من اتخاذ قرارات مستندة إلى البيانات. اكتشف المرشحين ذوي الإمكانات العالية وقم ببناء فرق استثنائية!"])};fn.source="احصل على ميزة تنافسية في اكتساب المواهب باستخدام أدوات التقييم المتقدمة لدينا. من تقييم الكفاءة والشخصية إلى تحليل المهارات، تُمكّنك منصتنا من اتخاذ قرارات مستندة إلى البيانات. اكتشف المرشحين ذوي الإمكانات العالية وقم ببناء فرق استثنائية!";return fn;})(),
  "Get all the tools your team needs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على جميع الأدوات التي يحتاجها فريقك"])};fn.source="احصل على جميع الأدوات التي يحتاجها فريقك";return fn;})(),
  "Unlock the full potential of your workforce with our cutting-edge talent assessment tools. From comprehensive evaluations to data-driven insights, we empower you to make informed decisions and build high-performing teams. Discover the future of talent management today!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استفد من كامل إمكانات قوتك العاملة باستخدام أدوات تقييم المواهب المتقدمة لدينا. من التقييمات الشاملة إلى التحليلات المستندة إلى البيانات، نمكّنك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!"])};fn.source="استفد من كامل إمكانات قوتك العاملة باستخدام أدوات تقييم المواهب المتقدمة لدينا. من التقييمات الشاملة إلى التحليلات المستندة إلى البيانات، نمكّنك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!";return fn;})(),
  "POWERFUL": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوي"])};fn.source="قوي";return fn;})(),
  "Skill Assessment and Talent resourcing in a single solution.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المهارات وتوفير المواهب في حل واحد."])};fn.source="تقييم المهارات وتوفير المواهب في حل واحد.";return fn;})(),
  "Welcome to your next growth opportunity.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا بك في فرصة النمو التالية."])};fn.source="مرحبًا بك في فرصة النمو التالية.";return fn;})(),
  "Get connected with full time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل مع وظائف بدوام كامل، مستقل أو عن بُعد تتناسب معك وتلبي متطلباتك."])};fn.source="تواصل مع وظائف بدوام كامل، مستقل أو عن بُعد تتناسب معك وتلبي متطلباتك.";return fn;})(),
  "APPLY AS A TALENT": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدم كمواهب"])};fn.source="قدم كمواهب";return fn;})(),
  "We open you up to a tool of possibilities.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نفتح لك أداة من الاحتمالات."])};fn.source="نفتح لك أداة من الاحتمالات.";return fn;})(),
  "Erase completely the stress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أزل تمامًا الضغط والقلق من التقديم مباشرةً إلى الشركات، من خلال لوحات الوظائف والتعامل مع العديد من المجندين. ستوصلك منصة GO بالفرص التي تريدها وفي شركات ذات اهتمامات مشابهة."])};fn.source="أزل تمامًا الضغط والقلق من التقديم مباشرةً إلى الشركات، من خلال لوحات الوظائف والتعامل مع العديد من المجندين. ستوصلك منصة GO بالفرص التي تريدها وفي شركات ذات اهتمامات مشابهة.";return fn;})(),
  "Pass Test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجتاز الاختبار"])};fn.source="اجتاز الاختبار";return fn;})(),
  "Pass our test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجتاز اختبارنا وأظهر للشركات ما يمكنك القيام به. الأفعال أبلغ من الكلمات."])};fn.source="اجتاز اختبارنا وأظهر للشركات ما يمكنك القيام به. الأفعال أبلغ من الكلمات.";return fn;})(),
  "Join Community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلى المجتمع"])};fn.source="انضم إلى المجتمع";return fn;})(),
  "Improvement? No Problem!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحسين؟ لا مشكلة! نساعدك على تعلم مهارات جديدة وتحسين ملفك الشخصي بمشاريع جانبية غير معوضة."])};fn.source="التحسين؟ لا مشكلة! نساعدك على تعلم مهارات جديدة وتحسين ملفك الشخصي بمشاريع جانبية غير معوضة.";return fn;})(),
  "Get Hired": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على وظيفة"])};fn.source="احصل على وظيفة";return fn;})(),
  "Are one off gigs your preference?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تفضل المهام الفردية؟ ستساعدك منصة GO على مشاركة ملفك الشخصي مع شركات في الولايات المتحدة وأوروبا ومنطقة الخليج."])};fn.source="هل تفضل المهام الفردية؟ ستساعدك منصة GO على مشاركة ملفك الشخصي مع شركات في الولايات المتحدة وأوروبا ومنطقة الخليج.";return fn;})(),
  "A One-Stop Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة شاملة"])};fn.source="منصة شاملة";return fn;})(),
  "You connect with GO PLATFORM": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتواصل مع منصة GO، ونتواصل مع العشرات من الشركات والمنصات ومئات المجندين لتحديد أفضل فرصة لك!"])};fn.source="تتواصل مع منصة GO، ونتواصل مع العشرات من الشركات والمنصات ومئات المجندين لتحديد أفضل فرصة لك!";return fn;})(),
  "Get Started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الآن"])};fn.source="ابدأ الآن";return fn;})(),
  "Talent Centric Process, Putting you FIRST!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية تركز على المواهب، تضعك أولاً!"])};fn.source="عملية تركز على المواهب، تضعك أولاً!";return fn;})(),
  "GO PLATFORM collaborates with companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتعاون منصة GO مع الشركات التي تلتزم بمعايير التوظيف الدولية. نضمن تبني تجارب نمو مهني جيدة ومنظمة لك."])};fn.source="تتعاون منصة GO مع الشركات التي تلتزم بمعايير التوظيف الدولية. نضمن تبني تجارب نمو مهني جيدة ومنظمة لك.";return fn;})(),
  "Trusted by Talents across AFRICA!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موثوق بها من قبل المواهب في جميع أنحاء إفريقيا!"])};fn.source="موثوق بها من قبل المواهب في جميع أنحاء إفريقيا!";return fn;})(),
  "“The consultants that reached out to me”": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“المستشارون الذين تواصلوا معي بخصوص الوظيفة كانوا متعاونين جدًا وفهماء ورائعين. كانوا معي حرفيًا في كل خطوة من المقابلة إلى عملية الانتظار وأخيراً للحصول على الوظيفة. شكرًا لك يا أكوريدي وأولواتوبي.”"])};fn.source="“المستشارون الذين تواصلوا معي بخصوص الوظيفة كانوا متعاونين جدًا وفهماء ورائعين. كانوا معي حرفيًا في كل خطوة من المقابلة إلى عملية الانتظار وأخيراً للحصول على الوظيفة. شكرًا لك يا أكوريدي وأولواتوبي.”";return fn;})(),
  "Get matched to your dream opportunity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل مع فرصتك الحلم"])};fn.source="تواصل مع فرصتك الحلم";return fn;})(),
  "Save time and Focus your energy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وفر الوقت وركز طاقتك في الاقتراب خطوة نحو فرصتك الأفضل التالية. انضم إلى شبكة المواهب الخاصة بنا الآن!"])};fn.source="وفر الوقت وركز طاقتك في الاقتراب خطوة نحو فرصتك الأفضل التالية. انضم إلى شبكة المواهب الخاصة بنا الآن!";return fn;})(),
  "Streamline your Recruitment. Start free.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بسّط عملية التوظيف الخاصة بك. ابدأ مجانًا."])};fn.source="بسّط عملية التوظيف الخاصة بك. ابدأ مجانًا.";return fn;})(),
  "Choose the perfect plan for your business needs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الخطة المثالية لاحتياجات عملك"])};fn.source="اختر الخطة المثالية لاحتياجات عملك";return fn;})(),
  "Save 15%": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وفر 15%"])};fn.source="وفر 15%";return fn;})(),
  "on monthly plan!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على الخطة الشهرية!"])};fn.source="على الخطة الشهرية!";return fn;})(),
  "Monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهري"])};fn.source="شهري";return fn;})(),
  "Yearly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنوي"])};fn.source="سنوي";return fn;})(),
  "Free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني"])};fn.source="مجاني";return fn;})(),
  "Best for personal use": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأفضل للاستخدام الشخصي"])};fn.source="الأفضل للاستخدام الشخصي";return fn;})(),
  "/month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/شهريًا"])};fn.source="/شهريًا";return fn;})(),
  "What you get:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الذي ستحصل عليه:"])};fn.source="ما الذي ستحصل عليه:";return fn;})(),
  "User": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم"])};fn.source="مستخدم";return fn;})(),
  "5 Tests per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 اختبارات لكل تقييم"])};fn.source="5 اختبارات لكل تقييم";return fn;})(),
  "Reporting and analytics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير والتحليلات"])};fn.source="التقارير والتحليلات";return fn;})(),
  "Pay per use ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع حسب الاستخدام"])};fn.source="الدفع حسب الاستخدام";return fn;})(),
  "Best for Small Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأفضل للشركات الصغيرة"])};fn.source="الأفضل للشركات الصغيرة";return fn;})(),
  "Upgraded features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميزات المحدثة:"])};fn.source="الميزات المحدثة:";return fn;})(),
  "Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمون"])};fn.source="المستخدمون";return fn;})(),
  "Customer Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم العملاء"])};fn.source="دعم العملاء";return fn;})(),
  "Most Popular": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر شيوعًا"])};fn.source="الأكثر شيوعًا";return fn;})(),
  "Pro": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برو"])};fn.source="برو";return fn;})(),
  "Best for Staffing Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأفضل لشركات التوظيف"])};fn.source="الأفضل لشركات التوظيف";return fn;})(),
  "Evolved Features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميزات المتطورة:"])};fn.source="الميزات المتطورة:";return fn;})(),
  "Talent Marketplace": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوق المواهب"])};fn.source="سوق المواهب";return fn;})(),
  "Calender Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكامل التقويم"])};fn.source="تكامل التقويم";return fn;})(),
  "Subscription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك"])};fn.source="الاشتراك";return fn;})(),
  "Custom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخصص"])};fn.source="مخصص";return fn;})(),
  "Unlimited features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميزات غير محدودة:"])};fn.source="ميزات غير محدودة:";return fn;})(),
  "Unlimited Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد غير محدود من المستخدمين"])};fn.source="عدد غير محدود من المستخدمين";return fn;})(),
  "Unlimited Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد غير محدود من التقييمات"])};fn.source="عدد غير محدود من التقييمات";return fn;})(),
  "Automation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأتمتة"])};fn.source="الأتمتة";return fn;})(),
  "Learn-more": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على المزيد"])};fn.source="تعرف على المزيد";return fn;})(),
  "Feature Table": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الميزات"])};fn.source="جدول الميزات";return fn;})(),
  "Unlimited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدود"])};fn.source="غير محدود";return fn;})(),
  "Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])};fn.source="التقييم";return fn;})(),
  "Tests Per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبارات لكل تقييم"])};fn.source="اختبارات لكل تقييم";return fn;})(),
  "Assessment Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة التقييم"])};fn.source="مكتبة التقييم";return fn;})(),
  "Coding Languages": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغات البرمجة"])};fn.source="لغات البرمجة";return fn;})(),
  "Limited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محدود"])};fn.source="محدود";return fn;})(),
  "Flow": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التدفق"])};fn.source="التدفق";return fn;})(),
  "Adds on": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإضافات"])};fn.source="الإضافات";return fn;})(),
  "Calendar Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكامل التقويم"])};fn.source="تكامل التقويم";return fn;})(),
  "Assessment templates based on job roles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب التقييم استنادًا إلى أدوار الوظائف"])};fn.source="قوالب التقييم استنادًا إلى أدوار الوظائف";return fn;})(),
  "Corporate Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات الشركات"])};fn.source="تقييمات الشركات";return fn;})(),
  "Anti-Cheat Measures": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات مكافحة الغش"])};fn.source="إجراءات مكافحة الغش";return fn;})(),
  "Time Limits on Tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود الزمنية للاختبارات"])};fn.source="الحدود الزمنية للاختبارات";return fn;})(),
  "IP Address Logging": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل عناوين IP"])};fn.source="تسجيل عناوين IP";return fn;})(),
  "Copy-Paste Disabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعطيل النسخ واللصق"])};fn.source="تم تعطيل النسخ واللصق";return fn;})(),
  "Webcam Snapshots": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقطات كاميرا الويب"])};fn.source="لقطات كاميرا الويب";return fn;})(),
  "Quality Control": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضبط الجودة"])};fn.source="ضبط الجودة";return fn;})(),
  "GDPR Compliance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتثال للائحة GDPR"])};fn.source="الامتثال للائحة GDPR";return fn;})(),
  "15 step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية من 15 خطوة تشمل المراجعات بين الزملاء، اختبار العينات، كتاب المحتوى، مراجعة من مختص قياس نفسي، وأكثر"])};fn.source="عملية من 15 خطوة تشمل المراجعات بين الزملاء، اختبار العينات، كتاب المحتوى، مراجعة من مختص قياس نفسي، وأكثر";return fn;})(),
  "To maintain test integrity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للحفاظ على نزاهة الاختبار، تمتلك منصة GO خوارزمية اختيار عناصر مملوكة تتضمن عناصر التحكم في التعرض لمنع تعرض أي سؤال محدد لأكثر من 25٪ من المرشحين."])};fn.source="للحفاظ على نزاهة الاختبار، تمتلك منصة GO خوارزمية اختيار عناصر مملوكة تتضمن عناصر التحكم في التعرض لمنع تعرض أي سؤال محدد لأكثر من 25٪ من المرشحين.";return fn;})(),
  "Dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])};fn.source="لوحة التحكم";return fn;})(),
  "Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة"])};fn.source="المكتبة";return fn;})(),
  "Activity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])};fn.source="النشاط";return fn;})(),
  "Manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة"])};fn.source="إدارة";return fn;})(),
  "Talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواهب"])};fn.source="المواهب";return fn;})(),
  "Payouts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات"])};fn.source="المدفوعات";return fn;})(),
  "Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول"])};fn.source="الجدول";return fn;})(),
  "Soon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبًا"])};fn.source="قريبًا";return fn;})(),
  "New Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروع جديد"])};fn.source="مشروع جديد";return fn;})(),
  "You currently have no Project, you can create one above.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك أي مشروع حاليًا، يمكنك إنشاء واحد أعلاه."])};fn.source="ليس لديك أي مشروع حاليًا، يمكنك إنشاء واحد أعلاه.";return fn;})(),
  "Archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرشيف"])};fn.source="الأرشيف";return fn;})(),
  "New Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم جديد"])};fn.source="تقييم جديد";return fn;})(),
  "Confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])};fn.source="تأكيد";return fn;})(),
  "Clear All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح الكل"])};fn.source="مسح الكل";return fn;})(),
  "Free trial": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجربة مجانية"])};fn.source="تجربة مجانية";return fn;})(),
  "Refer a company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوصي بشركة"])};fn.source="أوصي بشركة";return fn;})(),
  "Discounts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق الخصم"])};fn.source="تطبيق الخصم";return fn;})(),
  "Get Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل عليه الآن"])};fn.source="احصل عليه الآن";return fn;})(),
  "no code": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال رمز"])};fn.source="يرجى إدخال رمز";return fn;})(),
  "Terms": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط"])};fn.source="الشروط";return fn;})(),
  "Privacy policy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])};fn.source="سياسة الخصوصية";return fn;})(),
  "hero-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" <span class='grad'> التوظيف القائم على المهارات <img loading='lazy' src='/arch.svg'  class='arch' /></span  > "])};fn.source=" <span class='grad'> التوظيف القائم على المهارات <img loading='lazy' src='/arch.svg'  class='arch' /></span  > ";return fn;})(),
  "hero-subtext": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من مهارات المرشحين التقنية، <br /> المهارات الشخصية والنفسية."])};fn.source="تحقق من مهارات المرشحين التقنية، <br /> المهارات الشخصية والنفسية.";return fn;})(),
  "Start for free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ مجانًا"])};fn.source="ابدأ مجانًا";return fn;})(),
  "Inspired By": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوحى من"])};fn.source="مستوحى من";return fn;})(),
  "Stop the Bleed from Unfilled Positions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوقف النزيف الناتج عن الوظائف الشاغرة"])};fn.source="أوقف النزيف الناتج عن الوظائف الشاغرة";return fn;})(),
  "All The Tools That You Need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات قابلة للتخصيص للكشف عن جاهزية القوى العاملة بما يتجاوز السير الذاتية."])};fn.source="تقييمات قابلة للتخصيص للكشف عن جاهزية القوى العاملة بما يتجاوز السير الذاتية.";return fn;})(),
  "Accuracy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])};fn.source="الدقة";return fn;})(),
  "Precise & Valid Skills Data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات دقيقة وصحيحة عن المهارات"])};fn.source="بيانات دقيقة وصحيحة عن المهارات";return fn;})(),
  "Time Effectiveness": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية الوقت"])};fn.source="فعالية الوقت";return fn;})(),
  "Accelerated Hiring Velocity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسريع سرعة التوظيف"])};fn.source="تسريع سرعة التوظيف";return fn;})(),
  "COMMUNITY": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجتمع"])};fn.source="المجتمع";return fn;})(),
  "help-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت مستعد للانضمام إلى مجتمع منصة GO؟"])};fn.source="هل أنت مستعد للانضمام إلى مجتمع منصة GO؟";return fn;})(),
  "community-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكبر مجتمع من فرق التوظيف وفرق الذكاء الاصطناعي المحادثة يركز على مشاركة المشاريع والمعايير وأفضل الممارسات لإنشاء أفضل المساعدين في جميع الصناعات."])};fn.source="أكبر مجتمع من فرق التوظيف وفرق الذكاء الاصطناعي المحادثة يركز على مشاركة المشاريع والمعايير وأفضل الممارسات لإنشاء أفضل المساعدين في جميع الصناعات.";return fn;})(),
  "Join Slack": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلى Slack"])};fn.source="انضم إلى Slack";return fn;})(),
  "type to activate project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["اكتب <span> \" ", _interpolate(_named("projectName")), " \"</span> لـ ", _interpolate(_named("action")), " هذا المشروع"])};fn.source="اكتب <span> \" {projectName} \"</span> لـ {action} هذا المشروع";return fn;})(),
  "Edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])};fn.source="تعديل";return fn;})(),
  "Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروع"])};fn.source="مشروع";return fn;})(),
  "Duplicate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ"])};fn.source="نسخ";return fn;})(),
  "Archive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرشفة"])};fn.source="أرشفة";return fn;})(),
  "Activate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط"])};fn.source="تنشيط";return fn;})(),
  "Delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])};fn.source="حذف";return fn;})(),
  "Cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])};fn.source="إلغاء";return fn;})(),
  "type to delete project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["اكتب <span> \" ", _interpolate(_named("projectName")), " \"</span> لحذف هذا المشروع"])};fn.source="اكتب <span> \" {projectName} \"</span> لحذف هذا المشروع";return fn;})(),
  "invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة"])};fn.source="دعوة";return fn;})(),
  "Boards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])};fn.source="لوحة التحكم";return fn;})(),
  "Role": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور"])};fn.source="الدور";return fn;})(),
  "Experience": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخبرة"])};fn.source="الخبرة";return fn;})(),
  "Active": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])};fn.source="نشط";return fn;})(),
  "Archived": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤرشف"])};fn.source="مؤرشف";return fn;})(),
  "Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])};fn.source="الوقت";return fn;})(),
  "Status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])};fn.source="الحالة";return fn;})(),
  "Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرشحين"])};fn.source="المرشحين";return fn;})(),
  "Progress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقدم"])};fn.source="تقدم";return fn;})(),
  "Hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء"])};fn.source="إخفاء";return fn;})(),
  "Click here": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا"])};fn.source="اضغط هنا";return fn;})(),
  "To Compare Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمقارنة الخطط"])};fn.source="لمقارنة الخطط";return fn;})(),
  "Log in to your account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])};fn.source="تسجيل الدخول";return fn;})(),
  "Password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])};fn.source="كلمة المرور";return fn;})(),
  "Forgot Password?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة المرور؟"])};fn.source="هل نسيت كلمة المرور؟";return fn;})(),
  "Already have an account?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك حساب بالفعل؟"])};fn.source="لديك حساب بالفعل؟";return fn;})(),
  "Sign Up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])};fn.source="تسجيل";return fn;})(),
  "Email Address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني"])};fn.source="عنوان البريد الإلكتروني";return fn;})(),
  "Next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])};fn.source="التالي";return fn;})(),
  "Don't have access to these informations?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل ليس لديك وصول إلى هذه المعلومات؟"])};fn.source="هل ليس لديك وصول إلى هذه المعلومات؟";return fn;})(),
  "example@example.com": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example&#64;example.com"])};fn.source="example&#64;example.com";return fn;})(),
  "Fill in your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني"])};fn.source="أدخل بريدك الإلكتروني";return fn;})(),
  "Welcome to go platform!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا بك في منصة GO!"])};fn.source="مرحبًا بك في منصة GO!";return fn;})(),
  "Thank you for choosing our app. We hope you enjoy using it.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرًا لاختيارك تطبيقنا. نأمل أن تستمتع باستخدامه."])};fn.source="شكرًا لاختيارك تطبيقنا. نأمل أن تستمتع باستخدامه.";return fn;})(),
  "No Thanks.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا شكرًا."])};fn.source="لا شكرًا.";return fn;})(),
  "Start Tour": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الجولة"])};fn.source="ابدأ الجولة";return fn;})(),
  "Online Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمون المتواجدون"])};fn.source="المستخدمون المتواجدون";return fn;})(),
  "Upgrade to PRO to get access to all features!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالترقية إلى النسخة PRO للوصول إلى جميع الميزات!"])};fn.source="قم بالترقية إلى النسخة PRO للوصول إلى جميع الميزات!";return fn;})(),
  "Total Spent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع المنفق"])};fn.source="المجموع المنفق";return fn;})(),
  "Learn More!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم المزيد!"])};fn.source="تعلم المزيد!";return fn;})(),
  "Add now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الآن"])};fn.source="أضف الآن";return fn;})(),
  "Search Title or Keyword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن العنوان أو الكلمة الرئيسية"])};fn.source="ابحث عن العنوان أو الكلمة الرئيسية";return fn;})(),
  "Job title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])};fn.source="المسمى الوظيفي";return fn;})(),
  "Seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرتبة الوظيفية"])};fn.source="الرتبة الوظيفية";return fn;})(),
  "Minimum Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى من الدرجات"])};fn.source="الحد الأدنى من الدرجات";return fn;})(),
  "Job Seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الرتبة الوظيفية"])};fn.source="اختر الرتبة الوظيفية";return fn;})(),
  "Job Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الوظيفة"])};fn.source="معلومات الوظيفة";return fn;})(),
  "Select skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المهارات"])};fn.source="اختر المهارات";return fn;})(),
  "Don't show Salary?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لا تريد إظهار الراتب؟"])};fn.source="هل لا تريد إظهار الراتب؟";return fn;})(),
  "If you prefer, the job opening salary can also be kept private.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تفضل، يمكن إبقاء راتب الوظيفة الشاغرة خاصًا."])};fn.source="إذا كنت تفضل، يمكن إبقاء راتب الوظيفة الشاغرة خاصًا.";return fn;})(),
  "Job Description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الوظيفة"])};fn.source="وصف الوظيفة";return fn;})(),
  "Apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدّم الآن"])};fn.source="قدّم الآن";return fn;})(),
  "Total Tasks Done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المهام المنجزة"])};fn.source="إجمالي المهام المنجزة";return fn;})(),
  "Tasks Completed in last 7 Days": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام المنجزة في آخر 7 أيام"])};fn.source="المهام المنجزة في آخر 7 أيام";return fn;})(),
  "Validate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])};fn.source="تحقق";return fn;})(),
  "Test Composition": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركيب الاختبار"])};fn.source="تركيب الاختبار";return fn;})(),
  "30 multiple choice questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 سؤالًا متعدد الاختيارات"])};fn.source="30 سؤالًا متعدد الاختيارات";return fn;})(),
  "Tests included in the Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات المدرجة في التقييم"])};fn.source="الاختبارات المدرجة في التقييم";return fn;})(),
  "Recent Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرشحون الجدد"])};fn.source="المرشحون الجدد";return fn;})(),
  "Invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة"])};fn.source="دعوة";return fn;})(),
  "The Task title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان المهمة"])};fn.source="عنوان المهمة";return fn;})(),
  "Reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجعين"])};fn.source="المراجعين";return fn;})(),
  "Click to add reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط لإضافة المراجعين"])};fn.source="اضغط لإضافة المراجعين";return fn;})(),
  "Add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف"])};fn.source="أضف";return fn;})(),
  "Labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيفات"])};fn.source="التصنيفات";return fn;})(),
  "Click to add labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط لإضافة التصنيفات"])};fn.source="اضغط لإضافة التصنيفات";return fn;})(),
  "Descriptions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأوصاف"])};fn.source="الأوصاف";return fn;})(),
  "Add a description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف وصفًا"])};fn.source="أضف وصفًا";return fn;})(),
  "Attachment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرفقات"])};fn.source="المرفقات";return fn;})(),
  "Remove": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إزالة"])};fn.source="إزالة";return fn;})(),
  "Uploaded at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحميل في"])};fn.source="تم التحميل في";return fn;})(),
  "Click to attach a file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط لإرفاق ملف"])};fn.source="اضغط لإرفاق ملف";return fn;})(),
  "Comments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليقات"])};fn.source="التعليقات";return fn;})(),
  "Save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])};fn.source="حفظ";return fn;})(),
  "Add a comment...": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف تعليقًا..."])};fn.source="أضف تعليقًا...";return fn;})(),
  "Add to cart": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف إلى السلة"])};fn.source="أضف إلى السلة";return fn;})(),
  "Members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعضاء"])};fn.source="الأعضاء";return fn;})(),
  "Checklist": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المراجعة"])};fn.source="قائمة المراجعة";return fn;})(),
  "Actions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])};fn.source="الإجراءات";return fn;})(),
  "Copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ"])};fn.source="نسخ";return fn;})(),
  "Move": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقل"])};fn.source="نقل";return fn;})(),
  "No notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا إشعارات"])};fn.source="لا إشعارات";return fn;})(),
  "Control your Payments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحكم في مدفوعاتك"])};fn.source="تحكم في مدفوعاتك";return fn;})(),
  "Filters": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلاتر"])};fn.source="الفلاتر";return fn;})(),
  "Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])};fn.source="الاسم";return fn;})(),
  "Action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراء"])};fn.source="الإجراء";return fn;})(),
  "at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في"])};fn.source="في";return fn;})(),
  "Find Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن وظائف"])};fn.source="ابحث عن وظائف";return fn;})(),
  "Find Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن مرشحين"])};fn.source="ابحث عن مرشحين";return fn;})(),
  "Log out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])};fn.source="تسجيل الخروج";return fn;})(),
  "Register Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الآن"])};fn.source="سجل الآن";return fn;})(),
  "What role would you like to Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو الدور الذي ترغب في توظيفه؟"])};fn.source="ما هو الدور الذي ترغب في توظيفه؟";return fn;})(),
  "Developers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المطورون"])};fn.source="المطورون";return fn;})(),
  "Software Developers, Data Scientists, DevOps, and QA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطورون البرمجيات، علماء البيانات، DevOps، وجودة البرمجيات"])};fn.source="مطورون البرمجيات، علماء البيانات، DevOps، وجودة البرمجيات";return fn;})(),
  "Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصممون"])};fn.source="المصممون";return fn;})(),
  "Web, Mobile, UI/UX, Branding, and Visual Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصممو الويب، الجوال، UI/UX، العلامات التجارية، والمصممون البصريون"])};fn.source="مصممو الويب، الجوال، UI/UX، العلامات التجارية، والمصممون البصريون";return fn;})(),
  "Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مديرو المشاريع"])};fn.source="مديرو المشاريع";return fn;})(),
  "Digital Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مديرو المشاريع الرقمية، مدراء المشاريع التقنية، أساتذة Scrum، ومدربو Agile"])};fn.source="مديرو المشاريع الرقمية، مدراء المشاريع التقنية، أساتذة Scrum، ومدربو Agile";return fn;})(),
  "Product Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مديرو المنتجات"])};fn.source="مديرو المنتجات";return fn;})(),
  "Digital Product Managers, Product Owners, and Business Analysts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مديرو المنتجات الرقمية، مالكو المنتجات، والمحللون التجاريون"])};fn.source="مديرو المنتجات الرقمية، مالكو المنتجات، والمحللون التجاريون";return fn;})(),
  "Financial Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخبراء الماليون"])};fn.source="الخبراء الماليون";return fn;})(),
  "Financial Modelers, Fundraising Advisors, M&A and FP&A Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدو النماذج المالية، مستشارو جمع الأموال، خبراء الاستحواذ والاندماج، وخبراء التخطيط المالي"])};fn.source="معدو النماذج المالية، مستشارو جمع الأموال، خبراء الاستحواذ والاندماج، وخبراء التخطيط المالي";return fn;})(),
  "What type of project are you hiring for?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما نوع المشروع الذي توظف من أجله؟"])};fn.source="ما نوع المشروع الذي توظف من أجله؟";return fn;})(),
  "New idea or project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فكرة أو مشروع جديد"])};fn.source="فكرة أو مشروع جديد";return fn;})(),
  "Existing project that needs more resources": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروع قائم يحتاج إلى مزيد من الموارد"])};fn.source="مشروع قائم يحتاج إلى مزيد من الموارد";return fn;})(),
  "Ongoing assistance or consultation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة أو استشارة مستمرة"])};fn.source="مساعدة أو استشارة مستمرة";return fn;})(),
  "None of the above, I'm just looking to learn more about RECRUITABLE.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا شيء مما سبق، أنا فقط أبحث عن معرفة المزيد عن RECRUITABLE."])};fn.source="لا شيء مما سبق، أنا فقط أبحث عن معرفة المزيد عن RECRUITABLE.";return fn;})(),
  "How long do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي مدة الحاجة إلى"])};fn.source="ما هي مدة الحاجة إلى";return fn;})(),
  "for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أجل"])};fn.source="من أجل";return fn;})(),
  "Less than 1 week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل من أسبوع"])};fn.source="أقل من أسبوع";return fn;})(),
  "1 to 4 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أسبوع إلى 4 أسابيع"])};fn.source="من أسبوع إلى 4 أسابيع";return fn;})(),
  "1 to 3 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من شهر إلى 3 أشهر"])};fn.source="من شهر إلى 3 أشهر";return fn;})(),
  "3 to 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 3 إلى 6 أشهر"])};fn.source="من 3 إلى 6 أشهر";return fn;})(),
  "Longer than 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر من 6 أشهر"])};fn.source="أكثر من 6 أشهر";return fn;})(),
  "I'll decide later": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سأقرر لاحقًا"])};fn.source="سأقرر لاحقًا";return fn;})(),
  "How many": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد"])};fn.source="كم عدد";return fn;})(),
  "do you need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتاج إلى"])};fn.source="تحتاج إلى";return fn;})(),
  "One": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واحد"])};fn.source="واحد";return fn;})(),
  "Multiple": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدة"])};fn.source="عدة";return fn;})(),
  "A cross-functional team (developers, designers, project managers)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق متعدد التخصصات (المطورون، المصممون، مدراء المشاريع)"])};fn.source="فريق متعدد التخصصات (المطورون، المصممون، مدراء المشاريع)";return fn;})(),
  "What level of time commitment will you require from the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو مستوى الالتزام الزمني الذي ستحتاجه من"])};fn.source="ما هو مستوى الالتزام الزمني الذي ستحتاجه من";return fn;})(),
  "Fulltime (40 or more hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام كامل (40 ساعة أو أكثر في الأسبوع)"])};fn.source="دوام كامل (40 ساعة أو أكثر في الأسبوع)";return fn;})(),
  "Part time (Less than 40 hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام جزئي (أقل من 40 ساعة في الأسبوع)"])};fn.source="دوام جزئي (أقل من 40 ساعة في الأسبوع)";return fn;})(),
  "Hourly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالساعة"])};fn.source="بالساعة";return fn;})(),
  "What skills would you like to see in your new Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما المهارات التي ترغب في رؤيتها في موظفك الجديد؟"])};fn.source="ما المهارات التي ترغب في رؤيتها في موظفك الجديد؟";return fn;})(),
  "Popular skills for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات الشائعة لـ"])};fn.source="المهارات الشائعة لـ";return fn;})(),
  "How many people are employed at your company?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد الأشخاص الذين يعملون في شركتك؟"])};fn.source="كم عدد الأشخاص الذين يعملون في شركتك؟";return fn;})(),
  "Less than 10": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل من 10"])};fn.source="أقل من 10";return fn;})(),
  "More than 1000": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر من 1000"])};fn.source="أكثر من 1000";return fn;})(),
  "When do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متى تحتاج إلى"])};fn.source="متى تحتاج إلى";return fn;})(),
  "to start?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتبدأ؟"])};fn.source="لتبدأ؟";return fn;})(),
  "Immediately": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فورًا"])};fn.source="فورًا";return fn;})(),
  "In 1 to 2 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في 1 إلى 2 أسبوع"])};fn.source="في 1 إلى 2 أسبوع";return fn;})(),
  "More than 2 weeks form now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر من 2 أسبوع من الآن"])};fn.source="أكثر من 2 أسبوع من الآن";return fn;})(),
  "Are you open to working with a remote": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت مستعد للعمل مع فريق عن بُعد؟"])};fn.source="هل أنت مستعد للعمل مع فريق عن بُعد؟";return fn;})(),
  "Yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])};fn.source="نعم";return fn;})(),
  "No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])};fn.source="لا";return fn;})(),
  "I'm not sure yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لست متأكدًا بعد"])};fn.source="لست متأكدًا بعد";return fn;})(),
  "Success! Let's connect you with GO platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح! دعنا نوصلك بمنصة GO"])};fn.source="نجاح! دعنا نوصلك بمنصة GO";return fn;})(),
  "Company's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])};fn.source="اسم الشركة";return fn;})(),
  "Contact's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جهة الاتصال"])};fn.source="اسم جهة الاتصال";return fn;})(),
  "Back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])};fn.source="رجوع";return fn;})(),
  "Continue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])};fn.source="استمرار";return fn;})(),
  "to start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للشروع"])};fn.source="للشروع";return fn;})(),
  "Latest Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث الوظائف"])};fn.source="أحدث الوظائف";return fn;})(),
  "All Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الوظائف"])};fn.source="جميع الوظائف";return fn;})(),
  "Categories": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئات"])};fn.source="الفئات";return fn;})(),
  "Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])};fn.source="التطوير";return fn;})(),
  "Featured Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات المميزة"])};fn.source="الشركات المميزة";return fn;})(),
  "Settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])};fn.source="الإعدادات";return fn;})(),
  "Profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])};fn.source="الملف الشخصي";return fn;})(),
  "Applicant dropout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انسحاب المتقدمين"])};fn.source="انسحاب المتقدمين";return fn;})(),
  "Spent this mount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنفاق هذا الشهر"])};fn.source="الإنفاق هذا الشهر";return fn;})(),
  "Applicant revival": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحياء المتقدمين"])};fn.source="إحياء المتقدمين";return fn;})(),
  "AVG spent per hire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتوسط المنفق لكل توظيف"])};fn.source="المتوسط المنفق لكل توظيف";return fn;})(),
  "Recent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحدث"])};fn.source="الأحدث";return fn;})(),
  "Any questions?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي أسئلة؟"])};fn.source="أي أسئلة؟";return fn;})(),
  "Write or call us. We will write back within 12h": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك اقتراحات أو حتى تحب أن تقول مرحبًا؟ أبوابنا دائمًا مفتوحة :)"])};fn.source="هل لديك اقتراحات أو حتى تحب أن تقول مرحبًا؟ أبوابنا دائمًا مفتوحة :)";return fn;})(),
  "Report a bug": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبلاغ عن خطأ"])};fn.source="الإبلاغ عن خطأ";return fn;})(),
  "Others": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])};fn.source="أخرى";return fn;})(),
  "Feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])};fn.source="ملاحظات";return fn;})(),
  "Request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب"])};fn.source="طلب";return fn;})(),
  "Partnership": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شراكة"])};fn.source="شراكة";return fn;})(),
  "Urgency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستعجال"])};fn.source="الاستعجال";return fn;})(),
  "Send": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])};fn.source="إرسال";return fn;})(),
  "Individual Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة الفردية"])};fn.source="الدرجة الفردية";return fn;})(),
  "Pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])};fn.source="قيد الانتظار";return fn;})(),
  "Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])};fn.source="المسمى الوظيفي";return fn;})(),
  "Invite Co-worker": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة زميل العمل"])};fn.source="دعوة زميل العمل";return fn;})(),
  "Candidate email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريد المتقدم"])};fn.source="بريد المتقدم";return fn;})(),
  "apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدم الآن"])};fn.source="قدم الآن";return fn;})(),
  "Company Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الشركة"])};fn.source="معلومات الشركة";return fn;})(),
  "Company Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])};fn.source="اسم الشركة";return fn;})(),
  "select employees count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر عدد الموظفين"])};fn.source="اختر عدد الموظفين";return fn;})(),
  "Company Website": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الشركة"])};fn.source="موقع الشركة";return fn;})(),
  "select company's industry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر صناعة الشركة"])};fn.source="اختر صناعة الشركة";return fn;})(),
  "Company Logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار الشركة"])};fn.source="شعار الشركة";return fn;})(),
  "Company Logo should be a PNG or JPG file of 250 x 250 pixels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون شعار الشركة ملف PNG أو JPG بحجم 250 × 250 بكسل"])};fn.source="يجب أن يكون شعار الشركة ملف PNG أو JPG بحجم 250 × 250 بكسل";return fn;})(),
  "Recruiter Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المُجنّد"])};fn.source="معلومات المُجنّد";return fn;})(),
  "Featured Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف المميزة"])};fn.source="الوظائف المميزة";return fn;})(),
  "Assess a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])};fn.source="تقييم";return fn;})(),
  "Form Steps": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوات النموذج"])};fn.source="خطوات النموذج";return fn;})(),
  "First Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])};fn.source="الاسم الأول";return fn;})(),
  "Last name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])};fn.source="الاسم الأخير";return fn;})(),
  "Confirm password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])};fn.source="تأكيد كلمة المرور";return fn;})(),
  "If you purchased a featured job, please insert your order ID below": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا اشتريت وظيفة مميزة، يرجى إدخال رقم طلبك أدناه"])};fn.source="إذا اشتريت وظيفة مميزة، يرجى إدخال رقم طلبك أدناه";return fn;})(),
  "Previous": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])};fn.source="السابق";return fn;})(),
  "Submit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])};fn.source="إرسال";return fn;})(),
  "registerFormDescription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتقديم اختبار تقييم للموافقة، يرجى ملء النموذج التالي. إذا كان لديك أي أسئلة في العملية، يرجى التواصل معنا عبر <a class='blue_span'> hi&#64;go-platform.dz</a> <br />إذا كنت ترغب في نشر وظيفة مميزة، يرجى شراء <a class='blue_span'> باقة مميزة هنا</a>. بعد ذلك، يرجى إضافة رقم طلبك في النموذج في قسم الوظيفة المميزة."])};fn.source="لتقديم اختبار تقييم للموافقة، يرجى ملء النموذج التالي. إذا كان لديك أي أسئلة في العملية، يرجى التواصل معنا عبر <a class='blue_span'> hi&#64;go-platform.dz</a> <br />إذا كنت ترغب في نشر وظيفة مميزة، يرجى شراء <a class='blue_span'> باقة مميزة هنا</a>. بعد ذلك، يرجى إضافة رقم طلبك في النموذج في قسم الوظيفة المميزة.";return fn;})(),
  "Invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفاتورة"])};fn.source="الفاتورة";return fn;})(),
  "Pricing Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط الأسعار"])};fn.source="خطط الأسعار";return fn;})(),
  "Invoice ID": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الفاتورة"])};fn.source="معرف الفاتورة";return fn;})(),
  "Download as PDF": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزيل بصيغة PDF"])};fn.source="تنزيل بصيغة PDF";return fn;})(),
  "Print": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])};fn.source="طباعة";return fn;})(),
  "Recieve Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام المدفوعات"])};fn.source="استلام المدفوعات";return fn;})(),
  "Invoice to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])};fn.source="إلى";return fn;})(),
  "Invoice No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الفاتورة"])};fn.source="رقم الفاتورة";return fn;})(),
  "Order Num": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])};fn.source="رقم الطلب";return fn;})(),
  "Invoice Date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الفاتورة"])};fn.source="تاريخ الفاتورة";return fn;})(),
  "Payement Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المستحق"])};fn.source="المبلغ المستحق";return fn;})(),
  "Amount Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المستحق"])};fn.source="المبلغ المستحق";return fn;})(),
  "Quantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])};fn.source="الكمية";return fn;})(),
  "Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])};fn.source="السعر";return fn;})(),
  "Amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])};fn.source="المبلغ";return fn;})(),
  "Sub Total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع الفرعي"])};fn.source="المجموع الفرعي";return fn;})(),
  "Tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضريبة"])};fn.source="الضريبة";return fn;})(),
  "My Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وظائفي"])};fn.source="وظائفي";return fn;})(),
  "Post Job": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر وظيفة"])};fn.source="نشر وظيفة";return fn;})(),
  "No available jobs for now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد وظائف متاحة حالياً"])};fn.source="لا توجد وظائف متاحة حالياً";return fn;})(),
  "Refferal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحالة"])};fn.source="إحالة";return fn;})(),
  "Completion": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإتمام"])};fn.source="الإتمام";return fn;})(),
  "Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدمين"])};fn.source="المتقدمين";return fn;})(),
  "Assessements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])};fn.source="التقييمات";return fn;})(),
  "Success Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل النجاح"])};fn.source="معدل النجاح";return fn;})(),
  "On Track": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على المسار الصحيح"])};fn.source="على المسار الصحيح";return fn;})(),
  "Daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يومي"])};fn.source="يومي";return fn;})(),
  "Weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسبوعي"])};fn.source="أسبوعي";return fn;})(),
  "Avg Completion Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط وقت الإتمام"])};fn.source="متوسط وقت الإتمام";return fn;})(),
  "Behind Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خارج الجدول الزمني"])};fn.source="خارج الجدول الزمني";return fn;})(),
  "Recent Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدمين الجدد"])};fn.source="المتقدمين الجدد";return fn;})(),
  "View All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])};fn.source="عرض الكل";return fn;})(),
  "send invitations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الدعوات"])};fn.source="إرسال الدعوات";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])};fn.source="إغلاق";return fn;})(),
  "invite team members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة أعضاء الفريق"])};fn.source="دعوة أعضاء الفريق";return fn;})(),
  "My Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتي"])};fn.source="تقييماتي";return fn;})(),
  "Invite a friend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة صديق"])};fn.source="دعوة صديق";return fn;})(),
  "you both get": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلاكما يحصل على"])};fn.source="كلاكما يحصل على";return fn;})(),
  "Invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة أصدقائك وابدأ العمل معًا في ثوانٍ. سيحصل كل من تدعوه على بريد ترحيبي"])};fn.source="دعوة أصدقائك وابدأ العمل معًا في ثوانٍ. سيحصل كل من تدعوه على بريد ترحيبي";return fn;})(),
  "Email Adress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني"])};fn.source="عنوان البريد الإلكتروني";return fn;})(),
  "Send Invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الدعوة"])};fn.source="إرسال الدعوة";return fn;})(),
  "More ways to invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق أكثر لدعوة أصدقائك"])};fn.source="طرق أكثر لدعوة أصدقائك";return fn;})(),
  "Invite with Gmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة عبر Gmail"])};fn.source="دعوة عبر Gmail";return fn;})(),
  "Copied": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم النسخ"])};fn.source="تم النسخ";return fn;})(),
  "Copy Link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ الرابط"])};fn.source="نسخ الرابط";return fn;})(),
  "Share on LinkedIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة على LinkedIn"])};fn.source="مشاركة على LinkedIn";return fn;})(),
  "RefferalViewBottomText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة: <font-awesome-icon :icon=\"['fas', 'circle-info']\" /> بمجرد دعوتك للأصدقاء، يمكنك <a href=''>عرض حالة</a> إحالاتك أو زيارة <a href=''>مركز المساعدة</a> إذا كان لديك أي أسئلة."])};fn.source="ملاحظة: <font-awesome-icon :icon=\"['fas', 'circle-info']\" /> بمجرد دعوتك للأصدقاء، يمكنك <a href=''>عرض حالة</a> إحالاتك أو زيارة <a href=''>مركز المساعدة</a> إذا كان لديك أي أسئلة.";return fn;})(),
  "Candidate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشح"])};fn.source="مرشح";return fn;})(),
  "Set Success Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين درجة النجاح"])};fn.source="تعيين درجة النجاح";return fn;})(),
  "Selected Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات المختارة"])};fn.source="التقييمات المختارة";return fn;})(),
  "Select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار"])};fn.source="اختيار";return fn;})(),
  "Destribution by response time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوزيع حسب وقت الاستجابة"])};fn.source="التوزيع حسب وقت الاستجابة";return fn;})(),
  "This chart represents the time of candidates to pass the test after it was sent to them": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمثل هذا الرسم البياني الوقت الذي يستغرقه المرشحون لاجتياز الاختبار بعد إرساله إليهم"])};fn.source="يمثل هذا الرسم البياني الوقت الذي يستغرقه المرشحون لاجتياز الاختبار بعد إرساله إليهم";return fn;})(),
  "Edit project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المشروع"])};fn.source="تحرير المشروع";return fn;})(),
  "Project name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المشروع"])};fn.source="اسم المشروع";return fn;})(),
  "Job Title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])};fn.source="المسمى الوظيفي";return fn;})(),
  "select job seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار مستوى الوظيفة"])};fn.source="اختيار مستوى الوظيفة";return fn;})(),
  "Edit assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير التقييمات"])};fn.source="تحرير التقييمات";return fn;})(),
  "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])};fn.source="حفظ";return fn;})(),
  "Analogy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التشبيه"])};fn.source="التشبيه";return fn;})(),
  "This assessment is designed to evaluate candidates' cognitive abilities related to recognizing and understanding relationships between concepts. Candidates will encounter a series of...": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تصميم هذا التقييم لتقييم القدرات المعرفية للمرشحين المتعلقة بالتعرف على وفهم العلاقات بين المفاهيم. سيواجه المرشحون سلسلة من..."])};fn.source="تم تصميم هذا التقييم لتقييم القدرات المعرفية للمرشحين المتعلقة بالتعرف على وفهم العلاقات بين المفاهيم. سيواجه المرشحون سلسلة من...";return fn;})(),
  "Design": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم"])};fn.source="تصميم";return fn;})(),
  "Great service": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة رائعة"])};fn.source="خدمة رائعة";return fn;})(),
  "Free forever": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني إلى الأبد"])};fn.source="مجاني إلى الأبد";return fn;})(),
  "GO PLATFORM uses Technology to accurately measure competencies, providing insights into who possesses the necessary skills and who is on the path to acquiring them.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستخدم GO PLATFORM التكنولوجيا لقياس الكفاءات بدقة، مما يوفر رؤى حول من يمتلك المهارات اللازمة ومن في طريقه لاكتسابها."])};fn.source="تستخدم GO PLATFORM التكنولوجيا لقياس الكفاءات بدقة، مما يوفر رؤى حول من يمتلك المهارات اللازمة ومن في طريقه لاكتسابها.";return fn;})(),
  "Marketing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسويق"])};fn.source="تسويق";return fn;})(),
  "Of Every": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من كل"])};fn.source="من كل";return fn;})(),
  "Discover": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف"])};fn.source="اكتشف";return fn;})(),
  "How GO PLATFORM": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تساعد GO PLATFORM"])};fn.source="كيف تساعد GO PLATFORM";return fn;})(),
  "Helps": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يساعد"])};fn.source="يساعد";return fn;})(),
  "Give your workforce personalized learning plans and your leadership the visibility and insights needed for better talent decisions–all seamlessly integrated into your technology ecosystem.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدم لموظفيك خطط تعلم مخصصة ولقيادتك الرؤية والرؤى اللازمة لاتخاذ قرارات أفضل بشأن المواهب–كل ذلك مدمج بسلاسة في بيئة التكنولوجيا الخاصة بك."])};fn.source="قدم لموظفيك خطط تعلم مخصصة ولقيادتك الرؤية والرؤى اللازمة لاتخاذ قرارات أفضل بشأن المواهب–كل ذلك مدمج بسلاسة في بيئة التكنولوجيا الخاصة بك.";return fn;})(),
  "The Potential": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمكانات"])};fn.source="الإمكانات";return fn;})(),
  "More About GO Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد عن GO Platform"])};fn.source="المزيد عن GO Platform";return fn;})(),
  "GO PLATFORM has created a consistent and unbiased way to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنشأت GO PLATFORM طريقة متسقة وغير متحيزة لـ"])};fn.source="أنشأت GO PLATFORM طريقة متسقة وغير متحيزة لـ";return fn;})(),
  "Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل"])};fn.source="عمل";return fn;})(),
  "Measure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قياس"])};fn.source="قياس";return fn;})(),
  "Fernando Lucini, Global Data Science Lead, Accenture Applied Intelligence": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيرناندو لوتشيني، قائد علوم البيانات العالمية، أكسنتشر للاستخبارات التطبيقية."])};fn.source="فيرناندو لوتشيني، قائد علوم البيانات العالمية، أكسنتشر للاستخبارات التطبيقية.";return fn;})(),
  "our workforce.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوتنا العاملة."])};fn.source="قوتنا العاملة.";return fn;})(),
  "Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم"])};fn.source="دعم";return fn;})(),
  "On average, enterprise employees that use GO PLATFORM see:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في المتوسط​​، يرى موظفو الشركات الذين يستخدمون GO PLATFORM:"])};fn.source="في المتوسط​​، يرى موظفو الشركات الذين يستخدمون GO PLATFORM:";return fn;})(),
  "Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])};fn.source="البريد الإلكتروني";return fn;})(),
  "We are a proud 5 star business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن عمل فخور بتقييم 5 نجوم"])};fn.source="نحن عمل فخور بتقييم 5 نجوم";return fn;})(),
  "Position fit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناسبة الوظيفة"])};fn.source="مناسبة الوظيفة";return fn;})(),
  "Equal Access to opportunities is a basic right.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول المتساوي للفرص هو حق أساسي."])};fn.source="الوصول المتساوي للفرص هو حق أساسي.";return fn;})(),
  "Faster skill growth": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمو المهارات بشكل أسرع"])};fn.source="نمو المهارات بشكل أسرع";return fn;})(),
  "Already have an account? ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب بالفعل؟"])};fn.source="هل لديك حساب بالفعل؟";return fn;})(),
  "ompany Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الشركة"])};fn.source="معلومات الشركة";return fn;})(),
  "Company name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])};fn.source="اسم الشركة";return fn;})(),
  "Company website": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الشركة"])};fn.source="موقع الشركة";return fn;})(),
  "Read more!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ المزيد!"])};fn.source="اقرأ المزيد!";return fn;})(),
  "Discover More Insightful Articles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف المزيد من المقالات المفيدة"])};fn.source="اكتشف المزيد من المقالات المفيدة";return fn;})(),
  "The GO PLATFORM community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجتمع GO PLATFORM"])};fn.source="مجتمع GO PLATFORM";return fn;})(),
  "Join us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلينا"])};fn.source="انضم إلينا";return fn;})(),
  "Talent email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني"])};fn.source="أدخل بريدك الإلكتروني";return fn;})(),
  "talent invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])};fn.source="إرسال";return fn;})(),
  "Your Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسمك"])};fn.source="اسمك";return fn;})(),
  "Your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريدك الإلكتروني"])};fn.source="بريدك الإلكتروني";return fn;})(),
  "Filter by project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار مشروع"])};fn.source="اختيار مشروع";return fn;})(),
  "Filter by status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة المرشحين"])};fn.source="حالة المرشحين";return fn;})(),
  "passed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اجتيازه"])};fn.source="تم اجتيازه";return fn;})(),
  "invited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت دعوته"])};fn.source="تمت دعوته";return fn;})(),
  "Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات"])};fn.source="المدفوعات";return fn;})(),
  "manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة"])};fn.source="إدارة";return fn;})(),
  "Credit cards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقات الائتمان"])};fn.source="بطاقات الائتمان";return fn;})(),
  "Nme/Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم / العمل"])};fn.source="الاسم / العمل";return fn;})(),
  "Type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])};fn.source="النوع";return fn;})(),
  "Number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد"])};fn.source="العدد";return fn;})(),
  "Expire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])};fn.source="تاريخ الانتهاء";return fn;})(),
  "Send Message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال رسالة"])};fn.source="إرسال رسالة";return fn;})(),
  "employees": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفون"])};fn.source="الموظفون";return fn;})(),
  "Overview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])};fn.source="نظرة عامة";return fn;})(),
  "Benefits": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفوائد"])};fn.source="الفوائد";return fn;})(),
  "Add a new card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة بطاقة جديدة"])};fn.source="إضافة بطاقة جديدة";return fn;})(),
  "Team": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفريق"])};fn.source="الفريق";return fn;})(),
  "Role Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الدور"])};fn.source="فئة الدور";return fn;})(),
  "Compensation & Benefit Consultant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستشار التعويضات والفوائد"])};fn.source="مستشار التعويضات والفوائد";return fn;})(),
  "Experience Requirement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متطلبات الخبرة"])};fn.source="متطلبات الخبرة";return fn;})(),
  "Finance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المالية"])};fn.source="المالية";return fn;})(),
  "Working Location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان العمل"])};fn.source="مكان العمل";return fn;})(),
  "Jakerta": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاكرتا"])};fn.source="جاكرتا";return fn;})(),
  "Easy Apply": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم سهل"])};fn.source="تقديم سهل";return fn;})(),
  "Our company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركتنا"])};fn.source="شركتنا";return fn;})(),
  "Add photos": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة صور"])};fn.source="إضافة صور";return fn;})(),
  "More": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])};fn.source="المزيد";return fn;})(),
  "Invited recruiter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستشار تم دعوته"])};fn.source="مستشار تم دعوته";return fn;})(),
  "Select a project, and assess your talents!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول دعوة المواهب لاجتياز التقييم أو طلب المساعدة عبر"])};fn.source="حاول دعوة المواهب لاجتياز التقييم أو طلب المساعدة عبر";return fn;})(),
  "Slack Channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قناة Slack."])};fn.source="قناة Slack.";return fn;})(),
  "Top Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل التقييمات"])};fn.source="أفضل التقييمات";return fn;})(),
  "Search Assessment, Job Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن التقييم، المسمى الوظيفي"])};fn.source="البحث عن التقييم، المسمى الوظيفي";return fn;})(),
  "accuracyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتجاوز تقييماتنا الدقيقة اختبار المعرفة لتقييم المهارات الواقعية والاستعداد للعمل."])};fn.source="تتجاوز تقييماتنا الدقيقة اختبار المعرفة لتقييم المهارات الواقعية والاستعداد للعمل.";return fn;})(),
  "timeEffectivenessText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالتوظيف بشكل أسرع مع تقييمات المهارات القابلة للتخصيص. حدد الكفاءات الخاصة بالوظيفة، وسرّع عملية التوظيف، واتخذ قرارات مرشحين مبنية على البيانات."])};fn.source="قم بالتوظيف بشكل أسرع مع تقييمات المهارات القابلة للتخصيص. حدد الكفاءات الخاصة بالوظيفة، وسرّع عملية التوظيف، واتخذ قرارات مرشحين مبنية على البيانات.";return fn;})(),
  "Cost Efficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة من حيث التكلفة"])};fn.source="الكفاءة من حيث التكلفة";return fn;})(),
  "costEficiencyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملء الوظائف بسرعة وتوفير المال. تحديد أفضل المواهب بسرعة باستخدام التقييمات المعتمدة للمهارات لتجنب الفراغات المكلفة والخطأ في التوظيف."])};fn.source="ملء الوظائف بسرعة وتوفير المال. تحديد أفضل المواهب بسرعة باستخدام التقييمات المعتمدة للمهارات لتجنب الفراغات المكلفة والخطأ في التوظيف.";return fn;})(),
  "contactText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فقط أخبرنا كيف يمكننا مساعدتك وسيتواصل معك فريقنا في أقل من 24 ساعة."])};fn.source="فقط أخبرنا كيف يمكننا مساعدتك وسيتواصل معك فريقنا في أقل من 24 ساعة.";return fn;})(),
  "Select a title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار العنوان"])};fn.source="اختيار العنوان";return fn;})(),
  "NO DATA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات"])};fn.source="لا توجد بيانات";return fn;})(),
  "Hire Squad": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق التوظيف"])};fn.source="فريق التوظيف";return fn;})(),
  "Search Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث عن التقييمات."])};fn.source="بحث عن التقييمات.";return fn;})(),
  "No Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد لديك حالياً مواهب تم تقييمها في هذا المشروع. ابدأ مشروعًا جديدًا وادعُ المواهب لاجتياز التقييم."])};fn.source="لا يوجد لديك حالياً مواهب تم تقييمها في هذا المشروع. ابدأ مشروعًا جديدًا وادعُ المواهب لاجتياز التقييم.";return fn;})(),
  "Insights": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرؤى"])};fn.source="الرؤى";return fn;})(),
  "Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])};fn.source="التقييمات";return fn;})(),
  "Struggling talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواهب التي تعاني"])};fn.source="المواهب التي تعاني";return fn;})(),
  "Skilled talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواهب الماهرة"])};fn.source="المواهب الماهرة";return fn;})(),
  "lack of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقص الكفاءة"])};fn.source="نقص الكفاءة";return fn;})(),
  "HIgh level of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى عالٍ من الكفاءة"])};fn.source="مستوى عالٍ من الكفاءة";return fn;})(),
  "Number of questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الأسئلة لكل اختبار"])};fn.source="عدد الأسئلة لكل اختبار";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])};fn.source="تسجيل";return fn;})(),
  "Let's get to know you better": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعونا نتعرف عليك أكثر..."])};fn.source="دعونا نتعرف عليك أكثر...";return fn;})(),
  "Invite candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة المرشحين"])};fn.source="دعوة المرشحين";return fn;})(),
  "Clear List": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح القائمة"])};fn.source="مسح القائمة";return fn;})(),
  "Preview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة"])};fn.source="معاينة";return fn;})(),
  "This preview mode is not interactive, however, the assessment your candidate will see is": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع المعاينة هذا غير تفاعلي، ومع ذلك، التقييم الذي سيراه مرشحك هو تفاعلي."])};fn.source="وضع المعاينة هذا غير تفاعلي، ومع ذلك، التقييم الذي سيراه مرشحك هو تفاعلي.";return fn;})(),
  "project name tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسمًا يصف هذا التقييم بأفضل شكل."])};fn.source="أدخل اسمًا يصف هذا التقييم بأفضل شكل.";return fn;})(),
  "category tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر فئة الوظيفة التي تقوم بالتوظيف لها، حتى نتمكن من تصفية الوظائف لك."])};fn.source="اختر فئة الوظيفة التي تقوم بالتوظيف لها، حتى نتمكن من تصفية الوظائف لك.";return fn;})(),
  "job position tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الدور الوظيفي الذي يتطابق بشكل أكبر مع الوظيفة التي تقوم بالتوظيف لها، حتى نتمكن من اقتراح الاختبارات المناسبة لك."])};fn.source="اختر الدور الوظيفي الذي يتطابق بشكل أكبر مع الوظيفة التي تقوم بالتوظيف لها، حتى نتمكن من اقتراح الاختبارات المناسبة لك.";return fn;})(),
  "job seniority tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مستوى الخبرة للدور الوظيفي الذي تقوم بالتوظيف له."])};fn.source="اختر مستوى الخبرة للدور الوظيفي الذي تقوم بالتوظيف له.";return fn;})(),
  "Upgrade": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترقية"])};fn.source="ترقية";return fn;})(),
  "created by tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك استخدام قالب تقييم تم إنشاؤه من قبل خبرائنا والذي يتضمن الاختيارات الصحيحة من الاختبارات والأسئلة المخصصة للحصول على أفضل التوظيفات، أو بناء تقييمك الخاص."])};fn.source="يمكنك استخدام قالب تقييم تم إنشاؤه من قبل خبرائنا والذي يتضمن الاختيارات الصحيحة من الاختبارات والأسئلة المخصصة للحصول على أفضل التوظيفات، أو بناء تقييمك الخاص.";return fn;})(),
  "Job-Positions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناصب الوظيفية"])};fn.source="المناصب الوظيفية";return fn;})(),
  "Discover The Potential Of Every Talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف إمكانات كل موهبة"])};fn.source="اكتشف إمكانات كل موهبة";return fn;})(),
  "hero section description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستخدم منصة GO التكنولوجيا لقياس الكفاءات بدقة، مما يوفر رؤى حول من يمتلك المهارات اللازمة ومن هو على الطريق لاكتسابها."])};fn.source="تستخدم منصة GO التكنولوجيا لقياس الكفاءات بدقة، مما يوفر رؤى حول من يمتلك المهارات اللازمة ومن هو على الطريق لاكتسابها.";return fn;})(),
  "Our Rating": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمنا"])};fn.source="تقييمنا";return fn;})(),
  "GO Platform has created a consistent and unbiased way to Measure our workforce": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أنشأت منصة GO طريقة متسقة وغير متحيزة لقياس القوى العاملة لدينا"])};fn.source="لقد أنشأت منصة GO طريقة متسقة وغير متحيزة لقياس القوى العاملة لدينا";return fn;})(),
  "Turnover": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الأعمال"])};fn.source="حجم الأعمال";return fn;})(),
  "Skills Assessment Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة تقييم المهارات"])};fn.source="منصة تقييم المهارات";return fn;})(),
  "We provide actionable skills data that inform your talent strategies across hiring, upskilling, and mentorship": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقدم بيانات المهارات القابلة للتنفيذ والتي تساعد في إعداد استراتيجيات المواهب الخاصة بك عبر التوظيف، ورفع المهارات، والتوجيه"])};fn.source="نحن نقدم بيانات المهارات القابلة للتنفيذ والتي تساعد في إعداد استراتيجيات المواهب الخاصة بك عبر التوظيف، ورفع المهارات، والتوجيه";return fn;})(),
  "tools section H1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأدوات التي يمكنك تخيلها"])};fn.source="كل الأدوات التي يمكنك تخيلها";return fn;})(),
  "tools section H2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على جميع الأدوات التي يحتاجها فريقك"])};fn.source="احصل على جميع الأدوات التي يحتاجها فريقك";return fn;})(),
  "tools section H3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأدوات التي يمكنك تخيلها"])};fn.source="كل الأدوات التي يمكنك تخيلها";return fn;})(),
  "tools section T1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أطلق العنان لإمكانات القوى العاملة لديك بالكامل باستخدام أدوات تقييم المواهب المتطورة لدينا. من التقييمات الشاملة إلى الرؤى المستندة إلى البيانات، نعمل على تمكينك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!"])};fn.source="أطلق العنان لإمكانات القوى العاملة لديك بالكامل باستخدام أدوات تقييم المواهب المتطورة لدينا. من التقييمات الشاملة إلى الرؤى المستندة إلى البيانات، نعمل على تمكينك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!";return fn;})(),
  "tools section T2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على ميزة تنافسية في استقطاب المواهب باستخدام أدوات التقييم المتقدمة لدينا. من تقييم القدرات والشخصية إلى تحليل المهارات، تزودك منصتنا بالأدوات اللازمة لاتخاذ قرارات تستند إلى البيانات. اكتشف المرشحين ذوي الإمكانات العالية وقم ببناء فرق استثنائية!"])};fn.source="احصل على ميزة تنافسية في استقطاب المواهب باستخدام أدوات التقييم المتقدمة لدينا. من تقييم القدرات والشخصية إلى تحليل المهارات، تزودك منصتنا بالأدوات اللازمة لاتخاذ قرارات تستند إلى البيانات. اكتشف المرشحين ذوي الإمكانات العالية وقم ببناء فرق استثنائية!";return fn;})(),
  "tools section T3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أطلق العنان لإمكانات القوى العاملة لديك بالكامل باستخدام أدوات تقييم المواهب المتطورة لدينا. من التقييمات الشاملة إلى الرؤى المستندة إلى البيانات، نعمل على تمكينك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!"])};fn.source="أطلق العنان لإمكانات القوى العاملة لديك بالكامل باستخدام أدوات تقييم المواهب المتطورة لدينا. من التقييمات الشاملة إلى الرؤى المستندة إلى البيانات، نعمل على تمكينك من اتخاذ قرارات مستنيرة وبناء فرق عالية الأداء. اكتشف مستقبل إدارة المواهب اليوم!";return fn;})(),
  "How GO PLATFORM Helps": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تساعدك منصة GO"])};fn.source="كيف تساعدك منصة GO";return fn;})(),
  "about us description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امنح القوى العاملة الخاصة بك خطط التعلم المخصصة وقيادتك الرؤية والرؤى اللازمة لاتخاذ قرارات أفضل بشأن المواهب - كل ذلك مدمج بسلاسة في نظامك البيئي التكنولوجي"])};fn.source="امنح القوى العاملة الخاصة بك خطط التعلم المخصصة وقيادتك الرؤية والرؤى اللازمة لاتخاذ قرارات أفضل بشأن المواهب - كل ذلك مدمج بسلاسة في نظامك البيئي التكنولوجي";return fn;})(),
  "about us T1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستخدم قادة المؤسسات في مجال البيانات والذكاء الاصطناعي والتكنولوجيا والتعليم والتطوير منصة GO لاكتساب مهارات حاسمة واتخاذ قرارات أفضل بشأن المواهب وإعداد قوتهم العاملة للمستقبل"])};fn.source="يستخدم قادة المؤسسات في مجال البيانات والذكاء الاصطناعي والتكنولوجيا والتعليم والتطوير منصة GO لاكتساب مهارات حاسمة واتخاذ قرارات أفضل بشأن المواهب وإعداد قوتهم العاملة للمستقبل";return fn;})(),
  "about us T2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشمل مجالاتنا وقياساتنا حاليًا تقنيات مثل علم البيانات والذكاء الاصطناعي والبرمجيات والتعلم الآلي والسحابة والمزيد، وتتطور مع وتيرة الصناعة"])};fn.source="تشمل مجالاتنا وقياساتنا حاليًا تقنيات مثل علم البيانات والذكاء الاصطناعي والبرمجيات والتعلم الآلي والسحابة والمزيد، وتتطور مع وتيرة الصناعة";return fn;})(),
  "My Projects": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاريعي"])};fn.source="مشاريعي";return fn;})(),
  "Search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])};fn.source="بحث";return fn;})(),
  "All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجميع"])};fn.source="الجميع";return fn;})(),
  "Date Created": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء"])};fn.source="تاريخ الإنشاء";return fn;})(),
  "Success Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة النجاح"])};fn.source="درجة النجاح";return fn;})(),
  "Not Started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم البدء"])};fn.source="لم يتم البدء";return fn;})(),
  "Dummy Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروع وهمي"])};fn.source="مشروع وهمي";return fn;})(),
  "minutes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقائق"])};fn.source="دقائق";return fn;})(),
  "Next step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة التالية"])};fn.source="الخطوة التالية";return fn;})(),
  "Finish": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينهي"])};fn.source="ينهي";return fn;})(),
  "Build your Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم ببناء تقييمك"])};fn.source="قم ببناء تقييمك";return fn;})(),
  "TYPE": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع"])};fn.source="نوع";return fn;})(),
  "QUESTION": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤال"])};fn.source="سؤال";return fn;})(),
  "Qustom Questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة مخصصة"])};fn.source="أسئلة مخصصة";return fn;})(),
  "You can add up to 5 custom questions to your assessment from our library, or create your own": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إضافة ما يصل إلى 5 أسئلة مخصصة إلى تقييمك من مكتبتنا، أو إنشاء أسئلة خاصة بك"])};fn.source="يمكنك إضافة ما يصل إلى 5 أسئلة مخصصة إلى تقييمك من مكتبتنا، أو إنشاء أسئلة خاصة بك";return fn;})(),
  "Create custom question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء سؤال مخصص"])};fn.source="إنشاء سؤال مخصص";return fn;})(),
  "Create Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء تقييم"])};fn.source="إنشاء تقييم";return fn;})(),
  "Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات"])};fn.source="معلومات";return fn;})(),
  "Assessment Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التقييم"])};fn.source="اسم التقييم";return fn;})(),
  "Assessment Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة التقييم"])};fn.source="فئة التقييم";return fn;})(),
  "Select a category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الفئة"])};fn.source="حدد الفئة";return fn;})(),
  "Assessment Description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف التقييم"])};fn.source="وصف التقييم";return fn;})(),
  "Questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة"])};fn.source="أسئلة";return fn;})(),
  "Select an answer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الإجابة"])};fn.source="اختر الإجابة";return fn;})(),
  "Options": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات"])};fn.source="خيارات";return fn;})(),
  "added successfully": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الإضافة بنجاح"])};fn.source="تمت الإضافة بنجاح";return fn;})(),
  "Screener": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤال الفحص"])};fn.source="سؤال الفحص";return fn;})(),
  "New Question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤال جديد"])};fn.source="سؤال جديد";return fn;})(),
  "Create": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخلق"])};fn.source="يخلق";return fn;})(),
  "Suggested": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقترح"])};fn.source="مقترح";return fn;})(),
  "Suggested Screening Questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة الفحص المقترحة"])};fn.source="أسئلة الفحص المقترحة";return fn;})(),
  "Answer Choices": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات الإجابة"])};fn.source="خيارات الإجابة";return fn;})(),
  "Select one or multiple correct answers for this question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر إجابة واحدة أو أكثر صحيحة لهذا السؤال"])};fn.source="اختر إجابة واحدة أو أكثر صحيحة لهذا السؤال";return fn;})(),
  "Enable multiple correct answers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين الإجابات الصحيحة المتعددة"])};fn.source="تمكين الإجابات الصحيحة المتعددة";return fn;})(),
  "Add Answer": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف إجابة"])};fn.source="أضف إجابة";return fn;})(),
  "Save Question": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ السؤال"])};fn.source="حفظ السؤال";return fn;})(),
  "Language": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة"])};fn.source="لغة";return fn;})(),
  "Test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان"])};fn.source="امتحان";return fn;})(),
  "Duration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة"])};fn.source="مدة";return fn;})(),
  "Job Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الوظيفة"])};fn.source="فئة الوظيفة";return fn;})(),
  "Job Role": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور الوظيفي"])};fn.source="الدور الوظيفي";return fn;})(),
  "My Screeners": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاحصاتي"])};fn.source="فاحصاتي";return fn;})(),
  "Create project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مشروع"])};fn.source="إنشاء مشروع";return fn;})(),
  "Select tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الاختبارات"])};fn.source="اختر الاختبارات";return fn;})(),
  "Add questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الأسئلة"])};fn.source="أضف الأسئلة";return fn;})(),
  "Review & configure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجعة والتكوين"])};fn.source="المراجعة والتكوين";return fn;})(),
  "Information Technology": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكنولوجيا المعلومات"])};fn.source="تكنولوجيا المعلومات";return fn;})(),
  "Software Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطوير البرمجيات"])};fn.source="تطوير البرمجيات";return fn;})(),
  "Sales and Marketing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات والتسويق"])};fn.source="المبيعات والتسويق";return fn;})(),
  "Education and Training": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليم والتدريب"])};fn.source="التعليم والتدريب";return fn;})(),
  "Finance and Accounting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المالية والمحاسبة"])};fn.source="المالية والمحاسبة";return fn;})(),
  "Engineering": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهندسة"])};fn.source="الهندسة";return fn;})(),
  "Customer Service and Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة العملاء والدعم"])};fn.source="خدمة العملاء والدعم";return fn;})(),
  "Human Resources": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموارد البشرية"])};fn.source="الموارد البشرية";return fn;})(),
  "Manufacturing and Production": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيع والإنتاج"])};fn.source="التصنيع والإنتاج";return fn;})(),
  "Management and Leadership": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدارة والقيادة"])};fn.source="الإدارة والقيادة";return fn;})(),
  "Internship": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدريب"])};fn.source="تدريب";return fn;})(),
  "Entry Level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى مبتدئ"])};fn.source="مستوى مبتدئ";return fn;})(),
  "Junior": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبتدئ"])};fn.source="مبتدئ";return fn;})(),
  "Senior": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كبير"])};fn.source="كبير";return fn;})(),
  "VP": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نائب الرئيس"])};fn.source="نائب الرئيس";return fn;})(),
  "Executive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذي"])};fn.source="تنفيذي";return fn;})(),
  "Select your success score manually": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد درجة نجاحك يدويًا"])};fn.source="حدد درجة نجاحك يدويًا";return fn;})(),
  "Selected Tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبارات مختارة"])};fn.source="اختبارات مختارة";return fn;})(),
  "Selected Questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة مختارة"])};fn.source="أسئلة مختارة";return fn;})(),
  "tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات"])};fn.source="الاختبارات";return fn;})(),
  "Job Summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الوظيفة"])};fn.source="ملخص الوظيفة";return fn;})(),
  "Custom questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة مخصصة"])};fn.source="أسئلة مخصصة";return fn;})(),
  "Talent Map": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة المواهب"])};fn.source="خريطة المواهب";return fn;})(),
  "Create a new project assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء تقييم مشروع جديد"])};fn.source="إنشاء تقييم مشروع جديد";return fn;})()
}