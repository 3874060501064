<template>
    <div class="science-card">
        <template v-if="contentFirst">
            <div class="content">
                <h1>{{ title }}</h1>
                <p>{{ description }}</p>
                <a href="#" class="cta-button"> Read More </a>
            </div>
            <span>
                <img :src="imageSrc" alt="Science Card Image" />
            </span>
        </template>
        <template v-else>
            <span>
                <img :src="imageSrc" alt="Science Card Image" />
            </span>
            <div class="content">
                <h1>{{ title }}</h1>
                <p>{{ description }}</p>
                <a href="#" class="cta-button"> Read More </a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "ScienceCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        imageSrc: {
            type: String,
            required: true,
        },
        contentFirst: {
            type: Boolean,
            default: true, // Default to content first
        },
    },
};
</script>

<style scoped>
.science-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.content {
    width: 50%;
}

.content h1 {
    color: #334155;
    font-size: 32px;
    font-weight: 700;
}

.content p {
    color: #334155;
    margin: 25px 0;
}

.science-card span img {
    width: 500px;
    height: auto;
    border-radius: 5px;
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .science-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .content {
        width: 100%;
        margin-bottom: 20px;
    }

    .content h1 {
        font-size: 24px;
    }

    .content p {
        font-size: 14px;
    }

    .science-card span img {
        width: 100%;
        max-width: 400px;
    }
}
</style>
