<template>
    <section class="skills-assessment">
        <div class="skills-assessment-hero">
            <h1>{{ $t("Skills Assessment Platform") }}</h1>
            <h3>{{ $t("We provide actionable skills data that inform your talent strategies across hiring, upskilling, and mentorship") }}.</h3>
        </div>
        <SkillsAssessmentCards />
        <a href="#" class="main-button"> {{ $t("Get started") }} </a>
    </section>
</template>

<script>
import SkillsAssessmentCards from "./SkillsAssessmentCards.vue";
export default {
    name: "SkillsAssessment",
    components: {
        SkillsAssessmentCards,
    },
};
</script>

<style scoped>
.skills-assessment {
    background-color: #f5f8ff;
    width: 100%;
    text-align: center;
}

.skills-assessment-hero {
    color: #334155;
}

.skills-assessment-hero h1 {
    font-size: 2.2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.skills-assessment-hero h3 {
    font-weight: 400;
    padding: 0 90px;
}

.skills-assessment .main-button {
    padding: 12px 28px;
}

@media (max-width: 1023px) {
    .skills-assessment {
        padding: 30px 20px;
    }

    .skills-assessment-hero h1 {
        font-size: 20px;
    }

    .skills-assessment-hero h3 {
        font-size: 12px;
        padding: 0 50px;
    }

    .skills-assessment .main-button {
        padding: 8px 20px;
        font-size: 12px;
    }
}
</style>
