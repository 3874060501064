<template>
    <section class="continuous-improvement">
        <div class="science-cards">
            <!-- Content First -->
            <ScienceCard
                title="Continuous improvement for reliable, science-backed tests"
                description="Our work doesn’t stop there. Go's Science and Assessment Innovation Center of Expertise collects and analyzes data for all tests to examine their quality and impact ..."
                :imageSrc="require('@/assets/Images/Image-1.png')"
                :contentFirst="true"
            />

            <!-- Image First -->
            <ScienceCard
                title="Predicting job success with data"
                description="Our test and skill libraries draw on the US Department of Labor’s skills database (O*NET OnLine) as well as the European Commission’s European Skills, Competences, Qualifications ..."
                :imageSrc="require('@/assets/Images/Image-2.png')"
                :contentFirst="false"
            />

            <!-- Content First -->
            <ScienceCard
                title="Aligned with industry best practices"
                description="We follow industry best practices and relevant laws and regulations to maximize the fairness and legal defensibility of our tests, including the Uniform Guidelines on Employee ..."
                :imageSrc="require('@/assets/Images/Image-3.png')"
                :contentFirst="true"
            />

            <!-- Image First -->
            <ScienceCard
                title="Reduce hiring bias"
                description="We’ve designed our platform and tests considering the standards of the Equal Employment Opportunity Commission (EEOC) and Uniform Guidelines on Employee Selection Procedures (UGESP) ..."
                :imageSrc="require('@/assets/Images/Image-4.png')"
                :contentFirst="false"
            />
        </div>
    </section>
</template>

<script>
import ScienceCard from "./ScienceCard.vue"; // Adjust the path as necessary

export default {
    components: {
        ScienceCard,
    },
};
</script>

<style scoped>
.continuous-improvement {
    background-color: #f5f8ff;
}
.science-cards {
    display: flex;
    flex-direction: column;
}

/* Responsive Styles */
@media (max-width: 1023px) {
}
</style>
