<template>
    <div class="cards">
        <AboutUsCard icon="fa-lightbulb" title="Who we serve"> {{ $t("about us T1") }}. </AboutUsCard>
        <AboutUsCard icon="fa-microchip" title="Our skill domains"> {{ $t("about us T2") }}. </AboutUsCard>
        <AboutUsCard icon="fa-handshake" title="How we integrate">
            GO Platform is designed to complement and strengthen the tools and content stack your enterprise uses today. We integrate seamlessly and help you make the most of your investments.
        </AboutUsCard>
    </div>
</template>

<script>
import AboutUsCard from "./AboutUsCard.vue";

export default {
    name: "AboutUsCards",
    components: {
        AboutUsCard,
    },
};
</script>

<style scoped>
.cards {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px 0;
}

@media (max-width: 1023px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }
}
</style>
