<template>
    <section class="main">
        <h2>
            A Science-Backed <br />
            Hiring Solution
        </h2>
        <p>Experience an enjoyable, accurate, and scientifically sound talent assessment with our multi-layered approach, enabling you to validate and rank your candidates' skills with confidence.</p>
        <div class="button-container">
            <a href="#" class="cta-button">Try for free</a>
            <a href="#" class="cta-button bordered-button">Book a demo</a>
        </div>
    </section>
</template>

<script>
export default {
    name: "HeroSection",
};
</script>

<style scoped>
.main {
    position: relative;
    background-color: #f5f8ff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 140px 100px 40px 100px;
}

.main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.22;
    /* Adjust the opacity as needed */
    z-index: -1;
}

.main h2 {
    text-align: center;
    font-size: 54px;
    font-weight: 700;
    color: #00aef0;
    width: 60%;
    background: linear-gradient(90deg, #00aef0, #c084fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.main p {
    margin: 25px 0;
    width: 60%;
    color: #334155;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.button-container {
    display: inline-flex;
    gap: 20px;
}

.button-container .bordered-button {
    background-color: transparent;
    color: #00aef0;
    border: 1.5px solid #00aef0;
}

@media (max-width: 1023px) {
    .main {
        padding: 60px 40px;
        width: 100%;
        min-height: 33vh;
        padding-top: 100px;
    }

    .main h2 {
        font-size: 20px;
        text-align: center;
        width: 80%;
    }

    .main p {
        width: 100%;
        font-size: 12px;
    }

    .main .cta-button {
        padding: 8px 20px;
        font-size: 12px;
    }
}
</style>
