<template>
    <section class="main">
        <h2>{{ $t("Discover The Potential Of Every Talent") }}.</h2>
        <p class="custom-width">{{ $t("hero section description") }}</p>
        <a href="/register" class="main-button"> {{ $t("Start for free") }} </a>
        <div class="features">
            <div class="features-item">
                <i class="fa-solid fa-circle-check" style="color: #00aef0"></i>
                <span>{{ $t("Free forever") }}</span>
            </div>
            <div class="features-item">
                <i class="fa-solid fa-circle-check" style="color: #00aef0"></i>
                <span>{{ $t("Great service") }}</span>
            </div>
        </div>
        <ReviewsCards />
        <img :src="require('@/assets/Images/skills.png')" class="skills-image" />
    </section>
</template>

<script>
import ReviewsCards from "./ReviewsCards.vue";
export default {
    name: "HeroSection",
    components: {
        ReviewsCards,
    },
};
</script>

<style scoped>
.main {
    position: relative;
    background-color: #f5f8ff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 180px 100px 40px 100px;
}

.main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.22;
    /* Adjust the opacity as needed */
    z-index: -1;
}

.main h2 {
    text-align: center;
    font-size: 54px;
    font-weight: 700;
    color: #00aef0;
    width: 60%;
    background: linear-gradient(90deg, #00aef0, #c084fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.main p {
    margin: 25px 0;
    width: 60%;
    color: #334155;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.main .main-button {
    padding: 10px 40px;
}

.features {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.features-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.features-item span {
    color: #334155;
    font-weight: 400;
}

.main .reviews-image {
    width: 500px;
    height: auto;
    margin: 40px 0;
}

.five-star-reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 40px 0;
}

.five-star-reviews img {
    width: 300px;
    height: auto;
}

.main .skills-image {
    width: 700px;
    height: auto;
    /* Maintains aspect ratio */
}

@media (max-width: 1023px) {
    .main {
        padding: 60px 40px;
        width: 100%;
        min-height: 33vh;
        padding-top: 100px;
    }

    .main h2 {
        font-size: 20px;
        text-align: center;
        width: 80%;
    }

    .main p {
        width: 100%;
        font-size: 12px;
    }

    .main .main-button {
        padding: 8px 20px;
        font-size: 12px;
    }

    .features-item i {
        font-size: 12px;
    }

    .features-item span {
        font-size: 12px;
    }

    .main .skills-image {
        width: 400px;
    }
}
</style>
